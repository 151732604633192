import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetList } from "../../redux/_reducers/_user_reducers";
import moment from "moment";

const UnsettalBets = ({sportBetList}) => {
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
    const [data, setData] = useState([]);

    const dispatch = useDispatch()

    // useEffect(() => {
    //     currentBetList()
    // }, [])

    // useEffect(() => {
    //     if (!sportBetList || sportBetList.length === 0) {
    //       currentBetList();
    //     }
    //   }, [dispatch, sportBetList]); // Dependency on sportBetList
      

   

    const handleSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
          direction = "descending";
        }
        setSortConfig({ key, direction });
        const sortedData = [...data].sort((a, b) => {
          if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
          if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
          return 0;
        });
        setData(sortedData);
      };

    const renderSortArrows = (key) => (
        <span className="flex flex-col items-center -space-y-1.5">
          <button
            onClick={() => handleSort(key)}
            className={`text-xs ${sortConfig.key === key && sortConfig.direction === "ascending"
              ? "opacity-100 text-gray-800"
              : "opacity-50 text-gray-400"
              }`}
          >
            ▲
          </button>
          <button
            onClick={() => handleSort(key)}
            className={`text-xs ${sortConfig.key === key && sortConfig.direction === "descending"
              ? "opacity-100 text-gray-800"
              : "opacity-50 text-gray-400"
              }`}
          >
            ▼
          </button>
        </span>
      );

    return (

        <div className="overflow-auto">

            <table className="w-full">
                <thead className="text-[12px] text-[#243a48] bg-[#E4E4E4] border border-white">
                    <tr className="border border-white">
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[120px]">
                            <div className="flex justify-between items-center gap-1">
                                Placed {renderSortArrows("createdAt")}
                            </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[200px]">
                            <div className="flex justify-between items-center gap-1">
                                Description   {renderSortArrows("")}
                            </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">
                            <div className="flex justify-between items-center gap-1">
                                Section Type {renderSortArrows("type")}
                            </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
                            <div className="flex justify-between items-center gap-1">
                                B/L {renderSortArrows("")}
                            </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
                            <div className="flex justify-between items-center gap-1">


                                Odds {renderSortArrows("odds")}</div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
                            <div className="flex justify-between items-center gap-1">
                                Stake{renderSortArrows("stack")}
                            </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">  <div className="flex justify-between items-center gap-1">Bet Status {renderSortArrows("")}</div></th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">  <div className="flex justify-between items-center gap-1">Exp/profit {renderSortArrows("")}</div></th>
                    </tr>
                </thead>


                <tbody>
    {sportBetList?.length > 0 ? sportBetList?.map((betList, index) => (
      <tr key={index} className="bg-white border-b-[1px]  border-secondary uppercase">
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap ">{moment(betList?.time).format("DD/MM/YYYY hh:mm:ss A")}</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">{betList?.market}</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">{betList?.type}</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">-</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">{betList?.odds}</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">-</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">-</td>
        <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">-</td>
      </tr>
    )):
    <tr className='text-center border-2  px-2 py-3 text-[12px]'><th colSpan={5}>  No data found</th></tr>
  }
  </tbody>

            </table>
        </div>
    )
}

export default UnsettalBets
import { useState, useEffect } from "react";
import { IoIosClose } from "react-icons/io";


function DashboardModal() {
    const [modalOpen, setModalOpen] = useState(localStorage.getItem("dashboardModalOpen") === "true");

    const closeModal = () => {
        setModalOpen(false);
        localStorage.setItem("dashboardModalOpen", false);
    };

    useEffect(() => {
        if (modalOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        return () => {

            document.body.classList.remove("overflow-hidden");
        };

    }, [modalOpen])



    console.log();


    return (
        <>
            {modalOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black/70 flex justify-center items-center z-50 px-2"
                    style={{ zIndex: 999999 }}>
                    <div className="bg-white flex justify-end h-auto animate-slideInFromTop transition-transform duration-700 linear">

                        <div className="absolute z-50 flex justify-end items-end -mr-2 -mt-1"> 
                            <button
                            className=" top-0 right-0 rounded-full bg-red-600"
                            onClick={closeModal}
                        >
                            <IoIosClose size={22} className="text-white " />
                        </button>
                        </div>

                        <div className="relative w-[300px] bg-white flex justify-center items-center shadow-lg">
                            <img
                                className=" object-cover h-full "
                                src='/winjaImages/images/imageModal.jpg'
                            />

                        </div>

                        {/* <div className="border-t flex justify-end items-center w-full pt-2 border-gray-300 bg-white">
                            <button className=" top-0 right-0 mr-4 px-2 py-1.5 text-sm rounded text-white bg-[#6C757D]" onClick={closeModal}>
                                Close
                            </button>
                        </div> */}
                    </div>
                </div>
            )}
        </>
    );
}

export default DashboardModal;
import React, { useEffect, useRef, useState } from "react";
import { Carousel, message } from "antd";
import { HomeJson, primaryColor } from "./HomeJson";

import { Swiper, SwiperSlide } from "swiper/react";
// import SignIn from './SignIn';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../middelware/Http";

import { BsEyeFill, BsFillEyeSlashFill, BsTelegram } from "react-icons/bs";
import { apiCall } from "../../middelware/Http";
import SignIn from "../SignIn";
import Login from "../../component/login/Login";
import { login } from "../../redux/_reducers/_auth_reducers";
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import Social from "./Social";
import { FaWhatsapp } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const Home = () => {
  const navigate = useNavigate();
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("password");
  const [inputFocused, setInputFocused] = useState(false);
  const [iswhatsapp, setIswhatsapp] = useState(false);


  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    username: "",
    password: "",
  });
  const dispatch = useDispatch();
  // const [isSignUpOpen , setIsSignUpOpen] = useState(false)
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard')
    }
  }, [])

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let truncatedValue = value;
    if (name === "mobileNo") {
      // Truncate value to 10 digits if it exceeds
      if (value.length > 10) {
        return setErrors({
          ...errors,
          mobileNo: "Mobile number must be 10 digits",
        });
      }
      truncatedValue = value.slice(0, 10);
    }
    setUser({ ...user, [name]: truncatedValue });
    setErrors({ ...errors, [name]: "" }); // Clear errors when the input changes
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!user.mobileNo || user.mobileNo.length !== 10) {
      setErrors({ ...errors, mobileNo: "Mobile number must be 10 digits" });
      return;
    }
    if (!user.name || user.name.length < 3) {
      setErrors({ ...errors, name: "Name must be at least 3 characters long" });
      return;
    }

    // Validate password
    if (!user.password || user.password.length < 6) {
      setErrors({
        ...errors,
        password:
          "Password must be at least 6 characters long and must be in the format: Ab1234",
      });
      return;
    }
    // Check for uppercase letters
    if (!/[A-Z]/.test(user.password)) {
      setErrors({
        ...errors,
        password: "Password must contain at least one uppercase letter",
      });
      return;
    }
    // Check for lowercase letters
    if (!/[a-z]/.test(user.password)) {
      setErrors({
        ...errors,
        password: "Password must contain at least one lowercase letter",
      });
      return;
    }
    // Check for numbers
    if (!/\d/.test(user.password)) {
      setErrors({
        ...errors,
        password: "Password must contain at least one digit",
      });
      return;
    }

    // Check for special characters
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(user.password)) {
      setErrors({
        ...errors,
        password: "Password must contain at least one special character",
      });
      return;
    }

    setLoading(true);
    try {
      const loginDetails = {
        domainUrl: baseUrl.BACKEND_URL,
        name: user.name,
        username: user.username,
        mobileNo: user.mobileNo,
        password: user.password,
      };
      const response = await apiCall(
        "POST",
        "website/registerClient",
        loginDetails
      );

      if (response) {
        setUser({
          name: "",
          username: "",
          mobileNo: "",
          password: "",
        });
        window.location.href = "/dashboard";
        message.success(response?.message);
      } else {
        message.error("Registration failed. Please check your details.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(
        "An error occurred during registration. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); // password Hide and Show Icons
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handalClickNavigate = (url) => {
    navigate(url);
  };


  const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
  let socialMediaLink = domainSetting?.socialMedia

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleOnSubmit(e);
    }
  };
  const openModal = () => setIsLoginOpen(true);
  const closeModal = () => {
    setIsLoginOpen(!isLoginOpen);
    localStorage.setItem("unauthorized", false);
  };
  const handleDemoLogin = () => {
    const demoCredentials = {
      username: "10c",
      password: "Abcd1111@",
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(demoCredentials))
      .then((data) => {
        if (!data.error) {
          closeModal();
          window.location.href = "/dashboard";
          navigate("/dashboard");
        } else {
          console.error("Demo Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Demo login request failed:", error);
      });
  };
  const scrollToDiv = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const divRef = useRef(null);

  const handleWhatapp = () => {
    setIswhatsapp(true)
  }

  return (
    <div className="w-screen">
      <div className={`fixed bottom-10 right-8 bg-${primaryColor} p-2 rounded-md z-[997] shadow-lg`}>
        <FaWhatsapp className="text-white" size={30} onClick={handleWhatapp} />


      </div>

      {iswhatsapp &&
        <div class="  bg-[rgba(0,8,73,0.9)] text-white p-4 rounded-lg shadow-lg fixed bottom-20 mb-2 right-8 z-[997]">
          <>
            <button class="absolute -top-4 p-1 rounded-full -right-2 bg-white text-black text-center " onClick={() => setIswhatsapp(false)}>
              <IoClose size={20} className="text-md font-bold" />
            </button>
            <p class="text-sm py-1.5">Get an ID Instantly on Whatsapp.</p>
            <a passHref={true} href={`https://wa.me/+${domainSetting?.whatsappNumber}`} title="Whatsapp" className="underline text-sm">Click Here Now</a>
          </>
        </div>
      }

      {/* <div className="flex flex-row md:justify-around justify-between bg-[rgba(0,8,73,0.9)]  items-center py-2  xl:gap-56 xs:gap-8 "> */}
      <div ref={divRef} className="flex flex-row  justify-between lg:px-[165px] md:px-[90px] sm:px-[80px] h-[50px]  bg-[rgba(0,8,73,0.9)]  items-center p-[12px]   ">
        <div className="mr-2"><img className="h-7 sm:h-6 w-24" src="/assets/logo.png" alt="" /></div>
        <div className="flex px-2  h-full flex-row gap-[10px] justify-center items-center">
          <button
            className="bg-[rgba(0,8,73,0.9)]  text-[11px] sm:px-3 px-3 lg:px-5 lg:text-[12px]   sm:text-[11px] py-1 rounded text-white"
            onClick={() => {
              handleDemoLogin();
            }}
          >
            Demo
          </button>
          <button
            onClick={() => {
              setIsLoginOpen(true);
            }}
            className="bg-[rgba(0,8,73,0.9)] text-[11px] sm:text-[11px] sm:px-3 lg:text-[12px] px-3 lg:px-5 py-1 rounded text-white"
          >
            SIGN IN
          </button>
          <button
            onClick={() => {
              setIsSignUpOpen(true);
            }}
            className="bg-[rgba(0,8,73,0.9)] text-[11px] lg:px-5  sm:text-[11px] lg:text-[12px] sm:px-3 px-1 p-1 rounded text-white"
          >
            REGISTER
          </button>
        </div>
      </div>
      <div className="w-full  h-full ">
        {HomeJson && HomeJson.length > 0 ? (
          <Carousel
            autoplay
            effect="fade"
            dots={false} // You can switch to `false` if you prefer no dots
            speed={500}
            autoplaySpeed={2000}
            pauseOnHover={false} // Pause carousel on hover
            easing="ease-in-out" // Smooth easing for transitions
            arrows={false} // Show arrows for navigation
          >
            {HomeJson.filter((image) => image.section === "banner").map(
              (game, index) => (
                <div key={index} className="w-full">
                  <img
                    src={`/winjaImages/banner/${game.pathName}`}
                    alt={game.name || `Slide ${index + 1}`}
                    className="w-full h-full object-cover  shadow-md"
                    // className="w-full h-[140px] sm:h-[180px] md:h-[200px] lg:h-[300px] xl:h-[450px] object-cover  shadow-md"
                    loading="lazy"
                  />
                </div>
              )
            )}
          </Carousel>
        ) : (
          <div className="text-center text-white">No images available.</div>
        )}
      </div>
      <div className="rounded-md  p-3 bg-[rgba(229,231,252,0.9)]  ">
        <div className="lg:px-2 px-0">
          <h2 className="text-black text-[12px] font-semibold ">
           TRENDING GAMES
          </h2>
          {/* <button className="md:text-[12px] text-[10px] text-white p-[6px] px-[12px] rounded-full font-semibold shadow hover:bg-secondary see-all-btn">
                        See All
                    </button> */}
        </div>


        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          loop={true}
          grabCursor={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          direction="horizontal"
          className="px-2 overflow-auto"
          
        >
          {HomeJson?.filter((image) => image.section === "trending")?.map(
            (game, index) => (
              <SwiperSlide key={index} className="!w-auto">
                <img
                  onClick={scrollToDiv}
                  src={`/winjaImages/images/${game.pathName}`}
                  alt={game.name}
                  className="  lg:min-w-[185px]  lg:h-[180px] h-[80px] min-w-[80px]  rounded-[4px] object-cover"
                />
              </SwiperSlide>
            )
          )}
        </Swiper>

      </div>

      <div className="bg-[rgba(0,8,73,0.9)] py-4">
        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          loop={true}
          loopedSlides={HomeJson.length}
          grabCursor={true}
          className="px-2 "
        >
          {HomeJson?.filter((image) => image.section === "games")?.map(
            (game, index) => (
              <SwiperSlide
                key={index}
                onClick={() => {
                  setIsLoginOpen(true);
                }}
                className="border overflow-auto border-white rounded-[3px] lg:h-[110px]  !w-full flex justify-between items-center"
              >
                <div className="flex flex-col p-2 py-3 sm:w-full w-36 text-white gap-2 justify-center items-center h-full   ">
                  <img
                    
                    src={`/winjaImages/images/${game.pathName}`}
                    alt={game.name}
                    className=" object-cover sm:w-[28px] w-[27px] md:w-[48px] lg:w-[48px] rounded-lg"
                  />
                  <h1 className="lg:text-[14px] md:text-[14px] sm:text-[0.6rem] text-[9px] font-bold">{game?.name}</h1>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
      <div className="w-full  h-full  ">
        <div className="px-2 pt-1" >
          <span className="text-black font-semibold text-[13px] uppercase">Latest Games</span>
        </div>
        {HomeJson && HomeJson.length > 0 ? (
          <Carousel
            autoplay
            effect="fade"
            dots={false} // You can switch to `false` if you prefer no dots
            speed={500}
            autoplaySpeed={2000}
            pauseOnHover={false} // Pause carousel on hover
            easing="ease-in-out" // Smooth easing for transitions
            arrows={false} // Show arrows for navigation
          >
            {HomeJson.filter((image) => image.section === "latestGames").map(
              (game, index) => (
                <div key={index} className="w-full">
                  <img
                    onClick={scrollToDiv}
                    src={`/winjaImages/upcoming/${game.pathName}`}
                    alt={game.name || `Slide ${index + 1}`}
                    className="w-full h-full object-cover  shadow-md"
                    // className="w-full h-[140px] sm:h-[180px] md:h-[200px] lg:h-[300px] xl:h-[450px] object-cover rounded-lg shadow-md"
                    loading="lazy"
                  />
                </div>
              )
            )}
          </Carousel>
        ) : (
          <div className="text-center text-white">No images available.</div>
        )}
      </div>

      <div className="rounded-md  pt-5 ">
        <div className="flex  justify-between items-center mb-[1px] !px-3 ">
          <h2 className="text-black md:text-[14px] lg:text-[12px] text-[12px] uppercase font-semibold">
            All Games
          </h2>
          {/* <button className="md:text-[12px] text-[10px] text-white p-[6px] px-[12px] rounded-full font-semibold shadow hover:bg-secondary see-all-btn">
            See All
          </button> */}
        </div>

        {/* <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          loop={true}
          grabCursor={true}
          className="px-2 overflow-auto"
        >
          {HomeJson?.filter((image) => image.section === "allGames")?.map(
            (game, index) => (
              <SwiperSlide key={index} className="!w-auto">
                
                <img
                  onClick={() => {
                    setIsLoginOpen(true);
                  }}
                  src={`/winjaImages/images/${game.pathName}`}
                  alt={game.name}
                  className="md:min-w-56 min-w-28 md:h-36 h-28 object-cover rounded-sm"
                />
              </SwiperSlide>
            )
          )}
        </Swiper> */}

        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          loop={true}
          grabCursor={true}
          className="px-2 overflow-auto"
        >
          {HomeJson?.filter((image) => image.section === "allGames")?.map(
            (game, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="relative min-w-[167px] md:min-w-[280px]  sm:min-w-[200px] sm:min-h-[190px]  lg:min-w-[290px] lg:min-h-[175px] h-[145px] md:h-[150px]"
                onClick={scrollToDiv}
                >
                  <img
                   
                    src={`/winjaImages/images/${game.pathName}`}
                    alt={game.name}
                    className="w-full h-full sm:min-h-[100px] rounded-[2.5px] "
                  />

                  <div className={`absolute bottom-0 w-full bg-${primaryColor}   text-white text-center text-[10px] md:text-[12px] p-1`}>
                    {game?.name}
                  </div>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
      <div className="rounded-md my-4  ">
        <div className="flex  justify-between items-center mb-2 px-3">
          <h2 className="text-black md:text-[14px] text-[12px] font-bold">
            Promotions
          </h2>
          {/* <button className="md:text-[12px] text-[10px] text-white p-[6px] px-[12px] rounded-full font-semibold shadow hover:bg-secondary see-all-btn">
            See All
          </button> */}
        </div>

        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          loop={true}
          grabCursor={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          direction="horizontal"
          className="px-2 overflow-auto"
        >
          {HomeJson?.filter((image) => image.section === "trending")?.map(
            (game, index) => (
              <SwiperSlide key={index} className="!w-auto">
                <img
                  onClick={scrollToDiv}
                  src={`/winjaImages/images/${game.pathName}`}
                  alt={game.name}
                  className=" md:min-w-[220px] lg:min-w-[290px] lg:min-h-[190px] md:h-[140px] h-[105px] sm:h-[130px] sm:min-w-[210px] rounded-[2px] min-w-[180px] object-cover"
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>

      <div className="w-full flex justify-center items-center bg-[rgb(229,231,252,0.9)] md:pt  pt-3">
        <div class="container  lg:px-[107px] md:px-[40px] w-full sm:px-[55px] px-4">
          <div>
            <div className=" text-[#333] flex flex-col gap-[4px] text-[11px] leading-6 xs:px-0 ">
              <div>
                Our website is Asia’s one of the biggest gaming platforms with
                over 200 games and sports available.
              </div>
              <div>
                Popular sports such as Cricket, Tennis, Football, Basketball,
                Volleyball and many more including Indian card games like Teen
                Patti and Andar Bahar are also available from various worldwide
                gaming providers.
              </div>
              <div>
                With unlimited markets and odds, Our website gives players the
                best gaming experience in the market.
              </div>
              <div>
                You can make payments and receive earnings instantly via your
                UPI ID - so you can be sure that your money is safe and secure.
                All UPI platforms are accepted.
              </div>
              <div>
                <h4
                  className="text-[rgba(0,8,73,0.9)] text-[12px] m-1 "
                  style={{ fontWeight: "600" }}
                >
                  100% Safe and Instant Payments
                </h4>
                <h4
                  className="text-[rgba(0,8,73,0.9)]  text-[12px] m-1"
                  style={{ fontWeight: "600" }}
                >
                  Accepted Payment Modes
                </h4>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full">
              <Social></Social>
            </div>
            <div className="flex  text-[rgba(0,8,73,0.9)] text-[25px] flex-row  gap-5 h-14 justify-center items-center ">
              <a passHref={true} href={`https://wa.me/+${domainSetting?.whatsappNumber}`} title="Whatsapp">
                <AiOutlineWhatsApp />
              </a>
              <a href={socialMediaLink?.instaLink ? socialMediaLink?.instaLink : ''} target="_blank" >
                <AiOutlineInstagram />
              </a>
              <a href={socialMediaLink?.facebookLink ? socialMediaLink?.facebookLink : ''} target="_blank">
                <FaFacebook />
              </a>
              <a href={socialMediaLink?.telegramLink ? socialMediaLink?.telegramLink : ''} target="_blank" >
                <BsTelegram />
              </a>
            </div>
          </div>
        </div>
        <div></div>
      </div>

      {isLoginOpen && <Login isOpen={isLoginOpen} closeModal={() => setIsLoginOpen(false)} />}
      {isSignUpOpen && (
        <SignIn isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen} />
      )}
    </div>
  );
};

export default Home;

// import React, { useRef, useState, useEffect } from 'react'
// import { RiInformationFill } from "react-icons/ri";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { NavLink } from 'react-router-dom';



// export const betChipsData = {
//   "1000": 1000,
//   "2000": 2000,
//   "5000": 5000,
//   "10000": 10000,
//   "20000": 20000,
//   "50000": 50000,
//   "100000": 100000,
//   "250000": 250000,
// };


// export function BetPlaceDesktop(props) {
//   let { openBets, betSlipData, placeBet, count, betLoading } = props;



//   const myArray = Object.values(betChipsData);
//   const modalRef = useRef();
//   const [positions, setPositionData] = useState(0);

//   useEffect(() => {


//     if (betSlipData && betSlipData.position && betSlipData.position.length > 0) {
//       betSlipData && betSlipData.position.forEach((eles) => {
//         if (betSlipData.selectionId === eles._id) {
//           setPositionData(eles.position);
//         }
//       });
//     }

//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         // handleClose(); // Close modal when clicking outside
//       }
//     };
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };

//   }, [betSlipData]);


//   const [stake, setStack] = useState(0);

//   const updateInputValue = (event) => {
//     const newValue = parseFloat(event.target.value);
//     setStack(() => {
//       const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
//       betSlipData.stake = newStack;
//       updateOddsPostModal()
//       return newStack;
//     });
//   };


//   const updateFinalBalance = (amount) => setStack(prevStack => {
//     const newStack = prevStack + amount
//     betSlipData.stake = newStack;
//     return newStack
//   });


//   if (betSlipData.oddsType === "fancy") {
//     // filterdata = runCount.session.filter(session => session.Selection_id == betSlipData.data.Selection_id);
//   }

//   if (betSlipData.oddsType === "bookmaker") {
//     // filterdata = runCount.team_data.filter(session => session.selectionid == betSlipData.data.selectionid);

//   }




//   const arrayData = (element) => {
//     if (element > 0) {
//       updateFinalBalance(element);
//       updateOddsPostModal()
//     }
//   };

//   const updateOddsPostModal = async () => {

//     let oddsType = betSlipData.oddsType
//     let positionArray = {}
//     let positionArrayNew = {}

//     if (oddsType == "Match Odds" || oddsType == "Tied Match") {
//       betSlipData.nameOther.map((oddsData) => {

//         if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "L") {
//           positionArray[oddsData.selectionId] = betSlipData.stake * (betSlipData.odds - 1)
//         }
//         if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "K") {
//           positionArray[oddsData.selectionId] = -1 * betSlipData.stake * (betSlipData.odds - 1)
//         }
//         if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "L") {
//           positionArray[oddsData.selectionId] = -1 * betSlipData.stake
//         }
//         if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "K") {
//           positionArray[oddsData.selectionId] = betSlipData.stake
//         }

//         let currentPos = betSlipData.position[oddsData.selectionId] ? betSlipData.position[oddsData.selectionId] : 0
//         let calculatePos = positionArray[oddsData.selectionId]

//         positionArray[oddsData.selectionId] = Number(calculatePos) + Number(currentPos)
//         positionArrayNew[oddsData.selectionId] = Number(calculatePos)

//       })
//     }

//     if (oddsType == "toss" || oddsType == "bookmaker") {
//       betSlipData.nameOther.map((oddsData) => {

//         if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "L") {
//           positionArray[oddsData.selectionid] = betSlipData.stake * (betSlipData.odds)
//         }
//         if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "K") {
//           positionArray[oddsData.selectionid] = -1 * betSlipData.stake * (betSlipData.odds)
//         }
//         if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "L") {
//           positionArray[oddsData.selectionid] = -1 * betSlipData.stake
//         }
//         if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "K") {
//           positionArray[oddsData.selectionid] = betSlipData.stake
//         }

//         let currentPos = betSlipData.position[oddsData.selectionid] ? betSlipData.position[oddsData.selectionid] : 0
//         let calculatePos = positionArray[oddsData.selectionid]

//         positionArray[oddsData.selectionid] = Number(calculatePos) + Number(currentPos)
//         positionArrayNew[oddsData.selectionid] = Number(calculatePos)

//       })
//     }


//     betSlipData.oldPos = betSlipData.position
//     betSlipData.position = positionArray

//   }


//   // useEffect(() => {
//   //   AOS.init();
//   //   AOS.refresh();
//   // }, []);

//   return (
//     <section className=''>


//       <div ref={modalRef} className={`border-4 bg-white  rounded-lg h-96 relative ${betSlipData.betType === "Y" || betSlipData.betType === "K"  ? "border-[#A5D9FE]" : "border-[#F8D0CE]"}`}>
//         <div className='py-4 px-2 '>

//           <div className='text-sm font-normal space-y-1'>
//             <div>Kolkata Knight Riders v Delhi Capitals</div>
//             <div>{betSlipData.name}</div>
//           </div>

//           <div className='text-sm font-normal py-2 flex items-center space-x-3'>
//             <div class="relative mb-2 flex-1">
//               <input
//                 type="text"
//                 className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
//                 id="exampleFormControlInput1"
//                 placeholder="runs"
//                 value={
//                   betSlipData.oddsType === "fancy" || betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "toss"
//                     ? (count * 100)
//                     : count}
//               />
//               <label
//                 class="pointer-events-none px-2  bg-white absolute left-3 top-[2px] mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.39rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
//               >Runs
//               </label>
//             </div>


//             <div class="relative mb-2 flex-1">
//               <input
//                 type="text"
//                 class="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
//                 id="exampleFormControlInput1"
//                 placeholder="Stake"
//                 value={stake}
//                 onChange={updateInputValue}

//               />
//               <label
//                 class={`pointer-events-none px-2  bg-white absolute left-3 top-[2px] mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.39rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out ${stake ? 'peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary' : ''}`}
//               >{!stake ? '' : 'Stake'}
//               </label>
//             </div>

//           </div>



//           <div className=' px-2 flex justify-between items-center'>
//             <span className='px-1 py-1 text-sm font-normal'>or Choose You Stake Size</span>
//             <NavLink to={"/setting"} className='px-2 py-1 text uppercase text-sm font-normal hover:bg-[#FEF4EE] hover:rounded-md text-[#F37027]'>Edit Stake</NavLink>
//           </div>


//           <div className=' grid grid-cols-4 py-2 px-2 gap-2 items-center'>
//             {myArray && myArray.map((element, index) => (
//               <div key={index} className="flex justify-center items-center bg-[#F07934] border rounded-full" onClick={() => arrayData(element)}>
//                 <span className='px-4 py-1.5 text-white text-xs font-light' >{element}</span>
//               </div>
//             ))}
//           </div>


//           <div className='text-sm font-normal py-2 px-2 gap-2 flex items-center'>
//             <div class="relative mb-2 flex-1 text-center px-2  py-2 rounded-full border text-[#F37027] border-[#F37027] cursor-pointer text-sm uppercase" onClick={() => openBets()}>
//               <span className='py-4'>Cancel</span>
//             </div>


//             <div onClick={() => placeBet()} class="relative mb-2 flex-1 px-2  text-center py-2 rounded-full bg-gray-400 text-sm uppercase">
//               <span className='py-4'>placebet</span>

//             </div>
//           </div>

//           <div className='py-2 px-2 flex space-x-2 items-center'>
//             <RiInformationFill size={25} className='text-[#F37027]' />
//             <span className='text-sm font-light text-[#F37027]'>Min Bet: 100 Max Bet: 25000 Max Winning: 100000</span>
//           </div>



//           <div className='py-2 px-2 flex space-x-2 items-center justify-between'>
//             <span className='text-sm font-normal text-gray-800 px-2'>Confirm bets before placing</span>
//             <span className='px-2'>
//               <input type="checkbox" class="peer sr-only opacity-0" id="toggle" />
//               <label for="toggle" class="relative flex h-6 w-12 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-[#4CAF50] before:shadow before:transition-transform before:duration-300 peer-checked:bg-[#C5E5C7] peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-[#C5E5C7]">
//                 <span class="sr-only">Enable</span>
//               </label>
//             </span>
//           </div>
//         </div>

//         {betLoading === true ? (
//           <div className='border-2 bg-black opacity-80 rounded-lg h-96 w-full absolute top-0 flex justify-center items-center'>
//             <div role="status" className='text-red-900 font-bold text-xl'>
//               <svg aria-hidden="true" class="w-8 h-8 text-red-900 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
//                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
//               </svg>

//             </div>
//           </div>
//         ) : (
//           null)}


//       </div>

//     </section>
//   )
// }

import React, { useRef, useState, useEffect } from 'react'
import { RiInformationFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom';
import BetPlaceCounter from './BetPlaceCounter';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TiEdit } from "react-icons/ti";
import { FaDeleteLeft } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};

export function BetPlaceDesktop(props) {
  let { openBets, closeSec, closeRow, matchName, betSlipData, placeBet, count, betLoading, increaseCount, decreaseCount, setBetShow, setStakeModal, setBetSlipData } = props;
  const betChipsLocalStorage = localStorage.getItem("clientbetChipsData") ? JSON.parse(localStorage.getItem("clientbetChipsData")) : betChipsData
  const myArray = Object.values(betChipsLocalStorage);
  const modalRef = useRef();
  const [positions, setPositionData] = useState(0);

  useEffect(() => {


    if (betSlipData && betSlipData.position && betSlipData.position.length > 0) {
      betSlipData && betSlipData.position.forEach((eles) => {
        if (betSlipData.selectionId === eles._id) {
          setPositionData(eles.position);
        }
      });
    }


    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // handleClose(); // Close modal when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [betSlipData]);


  const [stake, setStack] = useState(0);
  let [placeButton, setPlaceButton] = useState(false)
  const updateInputValue = (event) => {
    const newValue = parseFloat(event.target.value);
    setStack(() => {
      const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  };
  const addstackValue = (number) => {

    setStack((stack) => {
      const newStack = !isNaN(number) ? `${stack}${number}` : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  }
  const deleteStackValue = () => {
    if (stake) {
      setStack((stack) => {
        if (!stack && stack === null) return
        const newStack = stack.slice(0, -1); // Remove the last character
        betSlipData.stake = newStack;
        if (betSlipData.stake > 0) {
          setPlaceButton(true);
        }
        if (betSlipData.stake <= 0) {
          setPlaceButton(false);
        }
        updateOddsPostModal()
        return newStack;
      });
    }
  };

  const updateFinalBalance = (amount) => setStack(prevStack => {
    const newStack = Number(prevStack) + amount
    betSlipData.stake = newStack;
    if (betSlipData.stake > 0) {
      setPlaceButton(true);
    }
    if (betSlipData.stake <= 0) {
      setPlaceButton(false);
    }
    return newStack
  });


  if (betSlipData.oddsType === "fancy") {
    // filterdata = runCount.session.filter(session => session.Selection_id == betSlipData.data.Selection_id);
  }

  if (betSlipData.oddsType === "bookmaker") {
    // filterdata = runCount.team_data.filter(session => session.selectionid == betSlipData.data.selectionid);

  }




  const arrayData = (element) => {
    if (element > 0) {
      updateFinalBalance(element);
      updateOddsPostModal()
    }
  };

  const updateOddsPostModal = async () => {

    let oddsType = betSlipData.oddsType
    let positionArray = {}
    let positionArrayNew = {}

    if (oddsType == "Match Odds" || oddsType == "Tied Match") {
      betSlipData.nameOther.map((oddsData) => {
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionId] ? betSlipData.position[oddsData.selectionId] : 0
        let calculatePos = positionArray[oddsData.selectionId]


        positionArray[oddsData.selectionId] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionId] = Number(calculatePos)

      })
    }

    if (oddsType == "toss" || oddsType == "bookmaker") {
      betSlipData.nameOther.map((oddsData) => {

        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionid] ? betSlipData.position[oddsData.selectionid] : 0
        let calculatePos = positionArray[oddsData.selectionid]

        positionArray[oddsData.selectionid] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionid] = Number(calculatePos)

      })
    }


    betSlipData.oldPos = betSlipData.position
    betSlipData.position = positionArray

  }


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);




  const closeModal = () => {
    // assuming betSlipData and id are available here
    let id = closeSec;
    closeRow(id);
    setBetShow(true)
    // setBetSlipData({})
  };

  const handlePlaceBet = () => {
    Promise.resolve(placeBet())
      .then(closeModal)
      .catch((error) => {
        console.error("Error placing bet:", error);
      });
  };




  return (
    <section className=''>
      <div ref={modalRef} className={`border-[5px] border-[#24e6a4] bg-white fixed top-2 left-0 w-full z-50 `}>
        <div className='py-1 px-1 '>
          {/* <div className=' px-1.5 rounded  bg-white w-full flex items-center justify-between'>
            <span className='text-md font-bold uppercase'>Bet Slip</span>
            <span className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold' onClick={closeModal}> &times;</span>
          </div> */}
          <div className='bg-white px-1 my-1 rounded'>
            <div>
              {betSlipData.betType === "K" ? "Lay" : betSlipData.betType === "L" ? "Back" : ""}
            </div>
            {/* <div className='text-sm font-normal space-y-1'>
              <div>{matchName}</div>
              {betSlipData.betType === "Y" ? "Back" : betSlipData.betType === "L" ? "Lay" : ""}
              <div className='text-button py-1 px-0.5 font-bold text-sm uppercase'>{betSlipData.oddsType}</div>
            </div> */}
            <div className={`px-2 py-0.5 rounded ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "bg-[#A5D9FE]" : "bg-[#F8D0CE]"}`}>
              {/* <div className='text-black py-1 px-1 flex justify-start items-center gap-1 font-bold text-sm'>
                <div>{betSlipData.name}</div>
                <div className='text-red-800' onClick={closeModal}><RiDeleteBin6Line />
                </div>
                <div onClick={() => setStakeModal(true)} className='text uppercase text-xs font-bold flex items-center  text-button'><TiEdit size={18} /></div>
              </div> */}

              {/* <div className='text-black py-1 px-1 flex items-center gap-3 font-bold text-sm'>{betSlipData.name}

                <span className='text-red-800' onClick={closeModal}><RiDeleteBin6Line />
                </span>
              </div> */}
              <div className='text-sm font-normal py-1 flex items-center space-x-3'>
                <div className="relative mb-2 flex-1 flex py-1 items-center bg-white  ">
                  <button className="ml-3 w-6 h-6  font-bold flex items-center justify-center bg-[#CEC8C6]" onClick={decreaseCount}><FaMinus size={11} /></button>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full font-bold  rounded text-center px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="exampleFormControlInput1"
                    value={
                      betSlipData.oddsType === "fancy" || betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "toss"
                        ? (count * 100)
                        : count}
                  />

                  <button className="mr-3 w-6 h-6  font-bold flex items-center justify-center bg-[#CEC8C6]" onClick={increaseCount}><FaPlus size={11} /></button>
                </div>
                <div class="relative mb-2 flex-1 flex py-1 bg-white  items-center">
                  <button className="ml-3 w-6 h-6  font-bold flex items-center justify-center bg-[#CEC8C6]"><FaMinus size={11} /></button>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full font-bold rounded text-center bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="exampleFormControlInput1"
                    placeholder="Stack "
                    value={stake ? stake : ""}
                    onChange={updateInputValue}
                  />
                  <button className="mr-3 w-6 h-6  font-bold flex items-center justify-center bg-[#CEC8C6]" ><FaPlus size={11} /></button>

                </div>
              </div>
            </div>
            <div className='grid grid-cols-6 gap-2 py-1 px-0 mt-1 text-[10px] gap-x-1 items-center'>
              {myArray && myArray.map((element, index) => (
                <div key={index} className={` ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "bg-[#61cce6] bg-gradient-to-b from-[#61cce6] to-[#29a3c2]" : "bg-[#fbbcbf] bg-gradient-to-b from-[#fbbcbf] to-[#eb7c81]"} flex py-1.5 justify-center items-center rounded`} onClick={() => arrayData(element)}>
                  <span className='px-1  text-black text-xs font-[500]' >{element}</span>

                </div>
              ))}
              {/* <div onClick={() => { setStakeModal(true) }} className='px-2 py-1 text uppercase text-xs font-bold flex items-center  text-button'><TiEdit size={18} /> <span className='text-white'>Edit</span></div> */}
            </div>


            {/* <div className='text-[10px] font-extrabold text-gray-400 px-2 py-0.5'>Select a different amount</div> */}

            {/* <div className='flex space-x-4 px-2 py-1.5 w-full'>

              <div className='grid grid-cols-6 gap-1 w-[80%]'>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00'].map((number) => (
                  <div className='flex ' key={number}>
                    <button onClick={()=>addstackValue(number)} className='px-5 w-full text-xs py-1 font-bold rounded bg-[#CEC8C6]'>{number}</button>
                  </div>
                ))}
              </div>
              <div onClick={()=>deleteStackValue()} className='rounded bg-[#CEC8C6] w-[20%] text-black flex justify-center items-center'  >
                <FaDeleteLeft  size={19} />
              </div>
            </div> */}
          </div>
          <hr className='my-2 mx-2 font-bold text-md border-[#5f5f5f]' />
          <div className='text-sm px-1 gap-2 font-bold flex items-center'>
            <div class="relative  flex-1 text-center px-2  py-1.5 rounded  bg-[#c30529] bg-gradient-to-b from-[#ff5071] to-[#c30529] cursor-pointer text-sm capitalize" onClick={() => closeModal()}>
              <span className='py-3 text-white'>Cancel</span>
            </div>
            {placeButton === false ? <>
              <div
                class="relative  flex-1 px-2  text-center py-1.5 rounded bg-[#026d02] bg-gradient-to-b from-[#07af07] to-[#026d02] text-gray-200 text-sm capitalize">
                <span className='py-3 text-white'>place bet</span>
              </div>
            </> :
              <div onClick={handlePlaceBet} class="relative  flex-1 px-2  text-center py-1.5 rounded bg-[#026d02] bg-gradient-to-b from-[#07af07] to-[#026d02] text-sm capitalize">
                <span className='py-3 text-white'>place bet</span>
              </div>
            }
          </div>



        </div>

        {betLoading === true ? (
          <div className='border-2 bg-black opacity-80 rounded-lg h-96 w-full absolute top-0 flex justify-center items-center'>
            <div role="status" className='text-button font-bold text-xxl text-center'>
              {/* <BetPlaceCounter /> */}
              <div className="loaderBet"></div>
            </div>
          </div>
        ) : (
          null)}


      </div>
    </section>
  )
}

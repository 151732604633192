import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getResult } from "../redux/_reducers/_user_reducers";
import CustomPagination from "../component/CustomPagination/CustomPagination";
import { primaryColor } from "./Home/HomeJson";
// import { getResult } from "../../redux/_reducers/_user_reducers";
// import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
// import CustomPagination from "../../component/CustomPagination/CustomPagination";

function Result() {
  const dispatch = useDispatch();
  const { resultData } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [expandedRows, setExpandedRows] = useState({});
  const pageSize = 50;

  const fetchResults = () => {
    const reqData = {
      size: pageSize,
      page: currentPage,
      // fromDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
      // toDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
    };
    dispatch(getResult(reqData));
  };
  const [activeTab, setActiveTab] = useState("Fancy");
  useEffect(() => {
    fetchResults();
  }, [currentPage, startDate, endDate]);

  useEffect(() => {
    if (resultData && resultData?.list?.length > 0) {

      const filteredData = resultData?.list?.map((data) => ({
        matchName: data?.matchName,
        marketName: data?.marketType,
        date: data?.date,
        result: data?.result,
        marketId: data?.marketId
      }));
      setData(filteredData);
    }
  }, [resultData]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const renderSortArrows = (key) => (
    <span className="flex flex-col  justify-center  gap-0  items-center">
      <button
        onClick={() => handleSort(key)}
        className={`text-[9px] ${sortConfig.key === key && sortConfig.direction === "ascending"
          ? "opacity-100 text-gray-800"
          : "opacity-50 text-gray-400"
          }`}
      >
        ▲
      </button>
      <button
        onClick={() => handleSort(key)}
        className={`text-[9px] ${sortConfig.key === key && sortConfig.direction === "descending"
          ? "opacity-100 text-gray-800"
          : "opacity-50 text-gray-400"
          }`}
      >
        ▼
      </button>
    </span>
  );
  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the expanded state for the row
    }));
  };
  const onPaginationChange = (page) => {
    setCurrentPage(page);
  };
  return (

    <div className="">
      <div
        className={`text-sm p-1 mx-auto font-semibold text-white bg-${primaryColor
          }`}
      >
        Result History

      </div>
      <div className="space-y-2 mb-4 p-2">
        {/* <div className="flex items-center space-x-2">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
            />
            <span className="text-gray-600">-</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div> */}
        {/* <div className="flex flex-wrap gap-2">
            {["Soccer", "Tennis", "Cricket"].map((category, index) => (
              <button
                key={index}
                className={`px-4 py-2 rounded-md ${
                  category === "Horse Race"
                    ? "bg-[#00b181] text-white"
                    : "bg-gray-300 text-gray-700"
                } hover:bg-[#00b181] hover:text-white transition`}
              >
                {category}
              </button>
            ))}
          </div> */}


        <div className="flex md:items-center md:justify-between flex-col md:flex-row justify-start items-start  gap-2 p-2">

          <div className="flex items-center gap-2">
            {/* <button
              className={`px-4 py-1.5 text-[14px] text-white rounded ${activeTab === "Fancy" ? "bg-gradient-to-r from-[#3d0252] to-[#3d0252]" : "bg-gradient-to-b from-[#545454] to-[#000]"}`}


              onClick={() => setActiveTab("Fancy")}
            >
              Fancy
            </button>
            <button
              className={`px-4 py-1.5 text-[14px] text-white rounded ${activeTab === "Markets" ? "bg-gradient-to-r from-[#3d0252] to-[#3d0252]" : "bg-gradient-to-b from-[#545454] to-[#000]"}`}

              onClick={() => setActiveTab("Markets")}
            >
              Markets
            </button> */}
          </div>


          <div className="grid grid-cols-2 md:grid-cols-2 gap-2 w-full md:w-1/2">
            <input
              type="text"
              placeholder="Market Search"
              className="border border-gray-300 text-xs py-2 px-2 w-full focus:outline-none "
            />

            <select
              className="border border-gray-300 text-xs py-2 px-2 w-full focus:outline-none"
            // onChange={(e) => console.log(e.target.value)} // This can be updated based on your action logic
            >
              {["All","Soccer", "Tennis", "Cricket"].map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>


        </div>
        {/* <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center space-x-2">
            <label htmlFor="entries" className="text-gray-600">
              Show
            </label>
            <select
              id="entries"
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="text-gray-600">entries</span>
          </div>
          <div className="flex items-center space-x-2">
            <label htmlFor="search" className="text-gray-600">
              Search:
            </label>
            <input
              id="search"
              type="text"
              placeholder="Type to search..."
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
        </div> */}
      </div>

      <div className="overflow-x-auto bg-white ">


      <div className="overflow-x-auto w-full shadow-1xl " >
                    <table className="w-full text-sm  text-left   border-b-[2px] border-[#ddd] border  " >
                      <thead className="text-[11px]  text-[#243a48]   bg-[#E4E4E4] border border-white">
                        <tr className="border border-white">
                          <th
                            scope="col "
                            className="text-[12px] "
                          >
                           <div className="flex justify-between items-center">
                           Market Date {renderSortArrows("date")}
                </div>
                           
                          </th>
                          <th
                            scope="col "
                            className="px-2.5 w-[16%]  py-1 text-[#243a48]  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center">
                            MarketId {renderSortArrows("marketId")}
                </div>
                          
                          </th>

                          <th
                            scope="col "
                            className="pl-2.5  w-[35%] py-1 text-[#243a48] whitespace-nowrap  border-[0.5px] border-white"
                          >
                             <div className="flex justify-between items-center">
                             Match Name {renderSortArrows("matchName")}
                </div>
                           
                           
                          </th>
                          <th
                            scope="col "
                            className="px-2.5 w-[5%] py-1 text-[#243a48]  border-[0.5px] border-white"
                          >
                           <div className="flex justify-between items-center">
                            Fancy {renderSortArrows("")}
                </div>
                          </th>
                          {/* <th scope="col " className="px-2.5 py-1 text-[#243a48]  border-[0.5px] border-white">Action </th> */}
                          <th
                            scope="col "
                            className="px-2.5 w-[25%] py-1 text-[#243a48]  border-[0.5px] border-white"
                          >
 <div className="flex justify-between items-center">
                            Result {renderSortArrows("result")}
                </div>
                          </th>
                        </tr>
                      </thead>
                      {data?.map((item, index) => (
                        <tbody key={index}>
                          <tr className="bg-white border-b-[1px]  border-secondary uppercase">
                            <th scope="row" className=" py-2 text-[#333] text-[12px] font-medium  border border-[#ddd]" >
                              {moment(item.date).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )}
                            </th>

                            <td
                              className={`px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd] ${
                                item.amount > 0
                                  ? "text-green-600"
                                  : item.amount < 0
                                  ? "text-red-600"
                                  : "text-[#333]"
                              }`}
                            >
                             {item?.marketId}
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]">
                            {item.matchName}
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd] space-x-2 ">
                              -
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]">{item.result}</td>
                            {/* <td className="px-6 py-4 text-center space-x-2 ">
                              <FaCircleInfo onClick={() => openModal(item)} size={20} />
                            </td> */}
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>



        {/* <table className="w-full border table-auto text-sm">
          <thead className="bg-gray-100 text-gray-700">
            <tr>
              <th className="px-3 py-1 text-left border">#</th>
              <th className="px-3 py-1 text-left border">
                <div className="flex justify-between items-center">
                  Match Name {renderSortArrows("matchName")}
                </div>
              </th>
              <th className="px-3 py-1 text-left border sm:table-cell hidden">
                <div className="flex justify-between items-center">
                  Market Name {renderSortArrows("marketName")}
                </div>
              </th>
              <th className="px-3 py-1 text-left border md:table-cell hidden">
                <div className="flex justify-between items-center ">
                  Date {renderSortArrows("date")}
                </div>
              </th>
              <th className="px-3 py-1 text-left border lg:table-cell hidden">
                Result
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={`${index % 2 === 0 ? "bg-[#f9f9f9]" : "bg-white"
                      } hover:bg-gray-50`}
                  >
                    <td className="px-3 py-1 border">
                      <div className="flex justify-between items-center">
                        <button
                          onClick={() => toggleRow(index)}
                          className="text-gray-600 text-xl lg:hidden block"
                        >
                          {expandedRows[index] ? (
                            <span className="flex justify-center items-center rounded-full bg-red-700 w-5 h-5 text-white">
                              -
                            </span>
                          ) : (
                            <span className="flex justify-center items-center rounded-full bg-green-700 text-white w-5 h-5">
                              +
                            </span>
                          )}
                        </button>
                        {index + 1}
                      </div>
                    </td>
                    <td className="px-3 py-1 border">{row.matchName}</td>
                    <td className="px-3 py-1 border sm:table-cell hidden">{row.marketName}</td>
                    <td className="px-3 py-1 border md:table-cell hidden">
                      {moment(row.date).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td className="px-3 py-1 border lg:table-cell hidden">
                      {row.result}
                    </td>
                  </tr>

                  {expandedRows[index] && (
                    <tr className=" lg:hidden">
                      <td colSpan="5" className="px-3 py-2">
                        <div className=" p-2 rounded-lg">
                          <ul className="dtr-details space-y-2">
                            <li className="dtr-item sm:hidden flex gap-3">
                              <span className="dtr-title font-bold">
                                Market Name:
                              </span>
                              <span className="dtr-data">
                                {row.marketName}
                              </span>
                            </li>
                            <li className="dtr-item gap-3 md:hidden flex">
                              <span className="dtr-title font-bold">
                                Date:
                              </span>
                              <span className="dtr-data">
                                {moment(row.date).format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                              </span>
                            </li>
                            <li className="dtr-item gap-3 lg:hidden flex">
                              <span className="dtr-title font-bold">
                                Result:
                              </span>
                              <span className="dtr-data">{row.result}</span>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="px-3 py-1 text-center text-gray-500"
                >
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table> */}
      </div>
      <CustomPagination
        totalCount={resultData?.total}
        pageSize={pageSize}
        currentPage={currentPage}
        onChange={onPaginationChange}
      />
    </div>

  );
}

export default Result;

import React from "react";

const CustomPagination = ({ totalCount, pageSize, currentPage, onChange }) => {

  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;

    if (onChange) {
      onChange(page);
    }
  };

  const createPageArray = () => {
    const delta = 2;
    let start = Math.max(1, currentPage - delta);
    let end = Math.min(totalPages, currentPage + delta);

    const pages = [];
    if (start > 1) {
      pages.push(1);
      if (start > 2) pages.push("...");
    }
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    if (end < totalPages) {
      if (end < totalPages - 1) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  const pageNumbers = createPageArray();

  return (
    <div className="flex items-center text-sm justify-center lg:justify-start my-2 mx-2 space-x-1">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1  border  rounded-lg   text-gray-500  transition-colors "
      >
      previous
      </button>

      {pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === "number" && handlePageChange(page)}
          className={`w-8 h-8  rounded-lg  transition-colors ${page === currentPage
              ? "!bg-[#52bdb4] text-black "
              : "text-gray-700 border border-gray-700  hover:bg-gray-100"
            }`}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 border-[1px] border-[#e4e4e4]  rounded-lg   text-[#7b7b7b]  transition-colors `}
      >
        Next 
      </button>
    </div>
  );
};

export default CustomPagination;

export const data = [
    {
      id: 1,
      icon: "📍",
      content:
        "Power Play247 Group Limited 2 WOODBERRY GROVE, FINCHILY LONDON, ENGLAND N12 0DR, Company No. 13980038F",
      order: 1,
    },
    {
      id: 2,
      icon: "📞",
      content: "+447878924457",
      order: 2,
    },
    {
      id: 3,
      icon: "✉️",
      content: "Infopowerplay247@gmail.com",
      order: 3,
    },
    {
      id: 4,
      icon: "🌐",
      content: "powerplay247.in",
      order: 4,
    },
  ];
import React, { useRef, useState, useEffect } from 'react'
import { RiInformationFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom';
import BetPlaceCounter from './BetPlaceCounter';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TiEdit } from "react-icons/ti";
import { FaDeleteLeft } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};


export function BetPlaceMobile(props) {


  let { openBets, closeSec, closeRow, matchName, betSlipData, placeBet, count, betLoading, increaseCount, decreaseCount, setStakeModal, setBetSlipData } = props;
  const betChipsLocalStorage = localStorage.getItem("clientbetChipsData") ? JSON.parse(localStorage.getItem("clientbetChipsData")) : betChipsData
  const myArray = Object.values(betChipsLocalStorage);
  const modalRef = useRef();
  const [positions, setPositionData] = useState(0);

  useEffect(() => {


    if (betSlipData && betSlipData.position && betSlipData.position.length > 0) {
      betSlipData && betSlipData.position.forEach((eles) => {
        if (betSlipData.selectionId === eles._id) {
          setPositionData(eles.position);
        }
      });
    }


    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // handleClose(); // Close modal when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [betSlipData]);


  const [stake, setStack] = useState(0);
  let [placeButton, setPlaceButton] = useState(false)
  const updateInputValue = (event) => {
    const newValue = parseFloat(event.target.value);
    setStack(() => {
      const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  };
  const addstackValue = (number) => {

    setStack((stack) => {
      const newStack = !isNaN(number) ? `${stack}${number}` : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  }
  const deleteStackValue = () => {
    if (stake) {
      setStack((stack) => {
        if (!stack && stack === null) return
        const newStack = stack.slice(0, -1); // Remove the last character
        betSlipData.stake = newStack;
        if (betSlipData.stake > 0) {
          setPlaceButton(true);
        }
        if (betSlipData.stake <= 0) {
          setPlaceButton(false);
        }
        updateOddsPostModal()
        return newStack;
      });
    }
  };

  const updateFinalBalance = (amount) => setStack(prevStack => {
    const newStack = Number(prevStack) + amount
    betSlipData.stake = newStack;
    if (betSlipData.stake > 0) {
      setPlaceButton(true);
    }
    if (betSlipData.stake <= 0) {
      setPlaceButton(false);
    }
    return newStack
  });


  if (betSlipData.oddsType === "fancy") {
    // filterdata = runCount.session.filter(session => session.Selection_id == betSlipData.data.Selection_id);
  }

  if (betSlipData.oddsType === "bookmaker") {
    // filterdata = runCount.team_data.filter(session => session.selectionid == betSlipData.data.selectionid);

  }




  const arrayData = (element) => {
    if (element > 0) {
      updateFinalBalance(element);
      updateOddsPostModal()
    }
  };

  const updateOddsPostModal = async () => {

    let oddsType = betSlipData.oddsType
    let positionArray = {}
    let positionArrayNew = {}

    if (oddsType == "Match Odds" || oddsType == "Tied Match") {
      betSlipData.nameOther.map((oddsData) => {
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionId] ? betSlipData.position[oddsData.selectionId] : 0
        let calculatePos = positionArray[oddsData.selectionId]


        positionArray[oddsData.selectionId] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionId] = Number(calculatePos)

      })
    }

    if (oddsType == "toss" || oddsType == "bookmaker") {
      betSlipData.nameOther.map((oddsData) => {

        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionid] ? betSlipData.position[oddsData.selectionid] : 0
        let calculatePos = positionArray[oddsData.selectionid]

        positionArray[oddsData.selectionid] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionid] = Number(calculatePos)

      })
    }


    betSlipData.oldPos = betSlipData.position
    betSlipData.position = positionArray

  }


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);




  const closeModal = () => {
    // assuming betSlipData and id are available here
    let id = closeSec;
    closeRow(id);
    // setBetSlipData({})
  };

  const handlePlaceBet = () => {
    Promise.resolve(placeBet())
      .then(closeModal)
      .catch((error) => {
        console.error("Error placing bet:", error);
      });
  };




  return (
    <section className='py-0.5 hidden sm:block'>
      {/* ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "border-[#A5D9FE]" : "border-[#F8D0CE]"} */}

      <div ref={modalRef} className={`border-[5px] border-[#24e6a4] bg-[#fffff] relative z-50`}>
        <div className='py-1 px-1 '>
          {/* <div className=' px-1.5 rounded  bg-white w-full flex items-center justify-between'>
            <span className='text-md font-bold uppercase'>Bet Slip</span>
            <span className='w-5 h-5 bg-black cursor-pointer rounded-full flex justify-center items-center text-white font-bold' onClick={closeModal}> &times;</span>
          </div> */}
          <div className='bg-white px-1 my-1 rounded'>
            <div>
              {betSlipData.betType === "K" ? "Lay" : betSlipData.betType === "L" ? "Back" : ""}
            </div>
            {/* <div>{matchName}</div> */}
            {/* <div className='text-sm font-normal space-y-1'>
              <div className='text-button py-1 px-0.5 font-bold text-sm uppercase'>{betSlipData.oddsType}</div>
            </div> */}
            <div className={`px-2 py-0.5 flex justify-end items-center rounded ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "bg-[#A5D9FE]" : "bg-[#F8D0CE]"}`}>


              {/* <div className='text-black py-1 px-1 flex justify-start items-center gap-1 font-bold text-sm'>
                <div>{betSlipData.name}</div>
                <div className='text-red-800' onClick={closeModal}><RiDeleteBin6Line />
                </div>
                <div onClick={() => setStakeModal(true)} className='text uppercase text-xs font-bold flex items-center  text-button'><TiEdit size={18} /></div>
              </div> */}

              <div className='text-sm font-normal py-1 flex items-center space-x-3 w-[300px]'>
                <div className="relative  flex-1 flex py-[2px] items-center bg-white w-[100px]">
                  <button className="ml-3 w-6 h-6 font-bold flex items-center justify-center bg-[#CEC8C6]" onClick={decreaseCount}><FaMinus size={11} /></button>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-[80px] font-bold  rounded text-center px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="exampleFormControlInput1"
                    value={
                      betSlipData.oddsType === "fancy" || betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "toss"
                        ? (count * 100)
                        : count}
                  />

                  <button className="mr-3 w-6 h-6 font-bold flex items-center justify-center bg-[#CEC8C6]" onClick={increaseCount}><FaPlus size={11} /></button>
                </div>
                <div class="relative  flex-1 flex py-[2px] bg-white items-center w-[100px]">
                  <button className="ml-3 w-6 h-6 font-bold flex items-center justify-center bg-[#CEC8C6]"><FaMinus size={11} /></button>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-[80px] font-bold rounded text-center bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="exampleFormControlInput1"
                    placeholder="Stack "
                    value={stake ? stake : ""}
                    onChange={updateInputValue}
                  />
                  <button className="mr-3 w-6 h-6 font-bold flex items-center justify-center bg-[#CEC8C6]" ><FaPlus size={11} /></button>

                </div>
              </div>

            </div>
            <div className='grid grid-cols-6 gap-2 py-1 px-0 mt-1 text-[10px] gap-x-1.5 items-center'>
              {myArray && myArray.map((element, index) => (
                <div key={index} className={` ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "bg-[#61cce6] bg-gradient-to-b from-[#61cce6] to-[#29a3c2]" : "bg-[#fbbcbf] bg-gradient-to-b from-[#fbbcbf] to-[#eb7c81]"} flex py-1.5 justify-center items-center rounded`} onClick={() => arrayData(element)}>
                  <span className='px-1  text-black text-xs font-[500]' >{element}</span>

                </div>
              ))}
            </div>


            {/* <div className='text-[10px] font-extrabold text-gray-400 px-2 py-0.5'>Select a different amount</div> */}

            {/* <div className='flex space-x-4 px-2 py-1.5 w-full'>

              <div className='grid grid-cols-6 gap-1 w-[80%]'>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00'].map((number) => (
                  <div className='flex ' key={number}>
                    <button onClick={()=>addstackValue(number)} className='px-5 w-full text-xs py-1 font-bold rounded bg-[#CEC8C6]'>{number}</button>
                  </div>
                ))}
              </div>
              <div onClick={()=>deleteStackValue()} className='rounded bg-[#CEC8C6] w-[20%] text-black flex justify-center items-center'  >
                <FaDeleteLeft  size={19} />
              </div>
            </div> */}
          </div>
          <hr className='my-2 mx-2 font-bold text-md border-[#5f5f5f]' />
          <div className='text-sm px-1 gap-2 font-bold flex items-center'>
            <div class="relative  flex-1 text-center px-2  py-1.5 rounded  bg-[#c30529] bg-gradient-to-b from-[#ff5071] to-[#c30529] cursor-pointer text-sm capitalize" onClick={() => closeModal()}>
              <span className='py-3 text-white'>Cancel</span>
            </div>
            {placeButton === false ? <>
              <div
                class="relative  flex-1 px-2  text-center py-1.5 rounded bg-[#026d02] bg-gradient-to-b from-[#07af07] to-[#026d02]  text-white text-sm capitalize">
                <span className='py-3 text-white'>place bet</span>
              </div>
            </> :


              <div onClick={handlePlaceBet} class="relative  flex-1 px-2  text-center py-1.5 rounded bg-button text-sm capitalize">
                <span className='py-3 text-white'>place bet</span>


              </div>
            }
          </div>



        </div>

        {betLoading === true ? (
          <div className='border-2 bg-black opacity-80 rounded-lg h-96 w-full absolute top-0 flex justify-center items-center'>
            <div role="status" className='text-button font-bold text-xxl text-center'>
              {/* <BetPlaceCounter /> */}
              <div className="loaderBet"></div>


            </div>

          </div>
        ) : (
          null)}


      </div>

    </section>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown, MdOutlineTimer } from "react-icons/md";
import { BiSolidError } from "react-icons/bi";
import Blinking from "../component/Blinking/Blinking.js";
import { useDispatch } from "react-redux";
import axios from "axios";
import io from "socket.io-client";
import { BetPlaceDesktop } from "../component/BetPlace/BetPlaceDesktop.js";
import { BetPlaceMobile } from "../component/BetPlace/BetPlaceMobile.js";
import { TbLadder, TbPinnedFilled } from "react-icons/tb";
import moment from "moment";
import {
  FaEdit,
  FaList,
  FaMinus,
  FaPlus,
  FaRegListAlt,
  FaTv,
} from "react-icons/fa";
import FancyLadder from "../component/FancyLadder/FancyLadder.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OpenBetsMobile from "../component/OpenBet/OpenBetMobile.js";
import { message } from "antd";
import { apiCall } from "../middelware/Http.js";
import Rules from "../component/rules/Rules.js";
import Loader from "../component/Loader/Loader.js";
import { ImCross } from "react-icons/im";
import BetListComponent from "../component/BetListComponent/BetListComponent.js";
import BetListComponentMobile from "../component/BetListComponent/BetListComponentMobile.js";
import { getUserBalance } from "../redux/_reducers/_user_reducers.js";
import EditStack from "../component/EditStake/EditStake.js";
import { primaryColor } from "./Home/HomeJson.js";

const MatchViewDeatils = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [inplayMatch, setInplayMatch] = useState({});
  const [scoreShow, setScoreShow] = useState(true);
  const [tvShow, setTvShow] = useState(false);
  const [betShow, setBetShow] = useState(true);
  const [betShowMobile, setBetShowMobile] = useState(false);
  const [matchScoreDetails, setMatchScoreDetails] = useState({});
  const [matchDetailsForSocketNew, setMatchDetailsForSocketNew] = useState({});
  const [finalSocket, setFinalSocketDetails] = useState({});
  const [otherFinalSocket, setOtherFinalSocketDetails] = useState({});
  const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);
  const [hiddenRows, setHiddenRows] = useState([]);
  const [active, setActive] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [betSlipData, setBetSlipData] = useState({
    stake: "0",
    count: 0,
    teamname: "",
    teamData: null,
  });

  const [minMaxCoins, setminMaxCoins] = useState({ max: null, min: null });
  const [sessionCoin, setSessionCoin] = useState({ max: null, min: null });
  const [isTieCoin, setIsTieCoin] = useState({ max: null, min: null });
  const [isTossCoin, setIsTossCoin] = useState({ max: null, min: null });
  const [isMatchCoin, setIsMatchCoin] = useState({ max: null, min: null });

  const [fancyBetData, setFancyBetData] = useState([]);
  const [oddsBetData, setOddsBetData] = useState([]);
  const [returnDataObject, setReturnDataObject] = useState({});
  const [returnDataFancyObject, setReturnDataFancyObject] = useState({});
  const [fancypositionModal, setFancypositionModal] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [betLoading, setBetLoading] = useState(false);
  const scrollRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [socketState, setSocketState] = useState(null);

  const [positionObj, setPositionObj] = useState({});
  const [positioBetData, setPositionBetData] = useState({});

  const [fancyPositionObj, setFancyPositionObj] = useState({});
  const [fancybetData, setFancybetData] = useState({});
  const [fullscreen, setFullScreen] = useState(false);
  const [isBookmekar, setIsBookmaker] = useState(true);
  const [isBookmekarHide, setIsBookmakerHide] = useState(true);
  const [isFancy, setIsFancy] = useState(true);
  const [isFancyHide, setIsFancyHide] = useState(true);
  const [isCommFancy, setIsCommFancy] = useState(true);
  const [isCommFancyHide, setIsCommFancyHide] = useState(true);
  const [isMatchOddsHide, setIsMatchOddsHide] = useState(true);
  const [isTossHide, setIsTossHide] = useState(true);
  const [isTieHide, setIsTieHide] = useState(true);
  const [isTieMatch, setIsTieMatch] = useState(true);
  const [stakeModal, setStakeModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [oneBetAmount, setOneBetAmount] = useState(
    localStorage.getItem("oneBetAmount") || "100"
  );
  const [oneClickBet, setOneClickBet] = useState(
    localStorage.getItem("oneClickStatus")
  );
  const [showStack, setShowStack] = useState(false);
  const [betListModal, setBetListModal] = useState(false);
  const [hideRowFancy, setHideRowFancy] = useState([]);
  const [deleteRowFancy, setDeleteRowFancy] = useState([]);
  const [hideRowCommFancy, setHideRowCommFancy] = useState([]);
  const [deleteRowCommFancy, setDeleteRowCommFancy] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);
  const toggleStack = () => {
    setShowStack((prevState) => !prevState);
  };

  const handleChange = () => {
    setIsChecked((prev) => !prev);
    setIsScorecardOpen((prevChecked) => (prevChecked ? false : "tv"));
  };

  const [isToss, setIsToss] = useState(true);

  // const [betPlaceModalMobile, setBetPlaceModalMobile] = useState(false);

  let { marketId, eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const gameDetailOtherPart = pathname.includes("viewMatchDetail");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const threshold = 100;
      setIsFixed(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let data = localStorage.getItem(`${marketId}_BookmakerData`);
  const setDataFromLocalstorage = async (marketId) => {
    if (data) {
      setMatchScoreDetails(JSON.parse(data).result);
    } else {
      setMatchScoreDetails("");
    }
  };

  const setMatchDataFromLocalstorage = async () => {
    let data = localStorage.getItem(`${eventId}_MatchOddsData`)
    if (!data) {
      return null
    }
    else {
      setFinalSocketDetails(JSON.parse(data));
    }
  }

  useEffect(() => {
    setDataFromLocalstorage();
    setMatchDataFromLocalstorage();
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        isConnected &&
        inplayMatch?.data?.socketUrl
      ) {
        callSocket(inplayMatch?.data?.socketUrl);
      } else if (document.visibilityState === "hidden") {
        cleanupWebSocket();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    setupAsyncActions(marketId);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      cleanupWebSocket();
    };
  }, [eventId, marketId, isConnected]);

  const [oddsbetdata, setOddsbetData] = useState();
  const [incomletedFancy, setIncompletedFancy] = useState();
  const [compltedFancy, setCompletedFancy] = useState();

  useEffect(() => {
    if (positioBetData) {
      const sortedOddsBetData = positioBetData?.oddsBetData
        ? positioBetData?.oddsBetData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        : [];

      const filteredFancyBetData = positioBetData?.fancyBetData
        ? positioBetData?.fancyBetData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        : [];

      const completeFancy =
        filteredFancyBetData && filteredFancyBetData.length > 0
          ? filteredFancyBetData.filter((element) => element.isDeclare === 1)
          : [];
      let showCompletedFancy = [];

      completeFancy.map((data, key) => {
        let pos = 0;
        if (data.decisionRun >= data.run && data.type === "Y") {
          pos = Math.round(data.amount * data.odds);
        } else if (data.decisionRun >= data.run && data.type === "N") {
          pos = Math.round(-1 * data.amount * data.odds);
        } else if (data.decisionRun < data.run && data.type === "Y") {
          pos = Math.round(-1 * data.amount);
        } else if (data.decisionRun < data.run && data.type === "N") {
          pos = Math.round(data.amount);
        }
        data.pos = pos;
        completeFancy[key].pos = pos;
        showCompletedFancy.push(data);
      });

      const finalPositionInfo = {};
      sortedOddsBetData &&
        sortedOddsBetData.forEach((item) => {
          const positionInfo = item.positionInfo;

          for (const key in positionInfo) {
            if (positionInfo.hasOwnProperty(key)) {
              if (!finalPositionInfo[key]) {
                finalPositionInfo[key] = 0;
              }
              finalPositionInfo[key] += positionInfo[key];
            }
          }
        });

      let finalPositionInfoFancy = {};

      filteredFancyBetData.forEach((item) => {
        const selectionId = item.selectionId;
        const loss = item.loss;

        if (finalPositionInfoFancy[selectionId]) {
          finalPositionInfoFancy[selectionId] += loss;
        } else {
          finalPositionInfoFancy[selectionId] = loss;
        }
      });

      setFancyPositionObj(finalPositionInfoFancy);
      setFancybetData(filteredFancyBetData);
      setPositionObj(finalPositionInfo);
      setOddsbetData(sortedOddsBetData);
      setCompletedFancy(showCompletedFancy);
      setIncompletedFancy(filteredFancyBetData && filteredFancyBetData.length > 0
        ? filteredFancyBetData.filter((element) => element.isDeclare === 0)
        : []
      );
    }
  }, [positioBetData]);

  useEffect(() => {


    const maxCoinData = inplayMatch?.maxMinCoins
      ? JSON.parse(inplayMatch?.maxMinCoins)
      : {
        maximum_match_bet: null,
        minimum_match_bet: null,
        maximum_session_bet: null,
        minimum_session_bet: null,
      };


    setminMaxCoins({
      max: maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });
    setSessionCoin({
      max: maxCoinData?.maximum_session_bet,
      min: maxCoinData?.minimum_session_bet,
    });


    setIsTieCoin({
      max: maxCoinData?.maximum_tie_coins > 0 ? maxCoinData?.maximum_tie_coins : maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });

    setIsTossCoin({
      max: maxCoinData?.maximum_toss_coins > 0 ? maxCoinData?.maximum_toss_coins : maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });

    setIsMatchCoin({
      max: maxCoinData?.maximum_matchOdds_coins > 0 ? maxCoinData?.maximum_matchOdds_coins : maxCoinData?.maximum_match_bet,
      min: maxCoinData?.minimum_match_bet,
    });



  }, [inplayMatch]);


  const setupAsyncActions = async (marketId) => {
    await getMatchDataByMarketID(marketId);
    fetchBetLists();
    // userPositionByMarketId(marketId);
  };

  const cleanupWebSocket = () => {
    if (socketState) {
      socketState.disconnect();
      setSocketState(null);
    }
  };

  const getMatchDataByMarketID = async (marketId) => {
    try {
      const resData = {
        marketId: marketId,
      };
      const inplayMatchResponse = await apiCall(
        "POST",
        "sports/sportByMarketId",
        resData
      );
      if (inplayMatchResponse && inplayMatchResponse.data) {
        setInplayMatch(inplayMatchResponse.data);
        const data = inplayMatchResponse?.data;

        if (inplayMatchResponse?.data?.socketPerm) {
          callSocket(inplayMatchResponse?.data?.socketUrl, inplayMatchResponse?.data);
        } else {
          callCache(inplayMatchResponse?.data?.cacheUrl);
        }

        // callSocket(inplayMatchResponse?.data?.socketUrl, inplayMatchResponse?.data?.socketPerm, inplayMatchResponse?.data?.cacheUrl);
      }
    } catch (error) {
      console.error("Error fetching inplay data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const callSocket = async (socketUrl, matchData) => {
    if (socketState && socketState.connected) {
      return;
    }
    try {
      const socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      socket.emit(`marketByEvent`, eventId);

      socket.on(eventId, (data) => {
        localStorage.setItem(`${eventId}_MatchOddsData`, data);
        setMatchDetailsForSocketNew(JSON.parse(data));
        setIsConnected(true);
        filterData(JSON.parse(data));
      });

      socket.emit("JoinRoom", marketId);
      // matchData.sportId === 4 || matchData.sportId == 999 &&  //
      socket.on(marketId, (data) => {
        localStorage.setItem(`${marketId}_BookmakerData`, data);
        setMatchScoreDetails(JSON.parse(data).result);
      });

      socket.on("disconnect", () => {
        setIsConnected(false);
      });

      setSocketState(socket);
    } catch (error) {
      console.error("Error in socket connection:", error);
    }
  };

  const callCache = async (cacheUrl) => {
    try {
      await getMarketCacheUrl(cacheUrl);
      const interval = setInterval(async () => {
        await getMarketCacheUrl(cacheUrl);
      }, 1000);
      // getMarketEventUrl(eventUrl);
      return () => clearInterval(interval);
    } catch (error) {
      console.error("Error calling cache:", error);
    }
  };

  const getMarketCacheUrl = async (cacheUrl) => {
    try {
      const response = await axios.get(cacheUrl);
      localStorage.setItem(
        `${marketId}_BookmakerData`,
        JSON.stringify(response.data)
      );
      setMatchScoreDetails(response.data.result);
    } catch (error) {
      console.error("Error fetching cache data:", error);
    }
  };

  // const getMarketEventUrl = async (eventUrl) => {
  //   try {
  //     const response = await axios.get(eventUrl);

  //     if (response?.data?.data) {
  //       localStorage.setItem(
  //         `${eventId}_MatchOddsData`,
  //         JSON.stringify(response?.data?.data)
  //       );
  //       setMatchDetailsForSocketNew(JSON.parse(response?.data?.data));

  //     }
  //   } catch (error) {
  //     setMatchDetailsForSocketNew({});
  //     console.error("Error fetching market event URL:", error);
  //   }
  // };


  // const filterData = (matchDetailsForSocketNew) => {
  //   try {
  //     if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
  //       return;
  //     }

  //     const criteria = ["Tied Match", "Match Odds", "To Win the Toss"];

  //     const filteredData = Array.isArray(matchDetailsForSocketNew)
  //       ? matchDetailsForSocketNew.filter((item) =>
  //           criteria.includes(item.marketType)
  //         )
  //       : [];

  //     if (filteredData.length > 0) {
  //       const filteredDataObject = [];
  //       filteredData.forEach((item) => {
  //         filteredDataObject[item.marketType] = item;
  //       });

  //       setFinalSocketDetails(filteredDataObject);
  //     } else {
  //       console.error("No data matched the criteria.");
  //     }
  //   } catch (error) {
  //     console.error("Error filtering data:", error);
  //   }
  // };


  const filterData = (matchDetailsForSocketNew) => {
    try {
      if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
        return;
      }
      const criteria = ["Tied Match", "Match Odds", "To Win the Toss"];
      const filteredData = Array.isArray(matchDetailsForSocketNew)
        ? matchDetailsForSocketNew.filter((item) =>
          criteria.includes(item.marketType)
        )
        : [];

      if (filteredData.length > 0) {
        const filteredDataObject = [];
        filteredData.forEach((item) => {
          filteredDataObject[item.marketType] = item;
        });
        setFinalSocketDetails(filteredDataObject);
      } else {
        console.error("No data matched the first criteria.");
      }

      const otherData = Array.isArray(matchDetailsForSocketNew)
        ? matchDetailsForSocketNew.filter((item) =>
          !criteria.includes(item.marketType)
        )
        : [];

      if (otherData.length > 0) {
        const OtherFilteredDataObject = [];
        otherData.forEach((item) => {
          OtherFilteredDataObject[item.marketType] = item;
        });
        setOtherFinalSocketDetails(OtherFilteredDataObject);
      } else {
        console.log("No other data matched.");
      }

    } catch (error) {
      console.error("Error filtering data:", error);
    }
  };

  const handleOnClick = () => {
    navigate("/");
  };

  // const handelScoreModal = () => {
  //   setScoreShow(!scoreShow);
  // };

  const handelScoreModal = () => {
    setScoreShow(true);
    setTvShow(false);
    setBetShowMobile(false);
  };
  const handelTvModal = () => {
    setTvShow(!tvShow);
    setScoreShow(true);
    setBetShowMobile(false);
  };

  const handelAllClossModal = () => {
    setTvShow(false);
    setScoreShow(!scoreShow);
  };

  const openBets = () => {
    setBetShow(true);
  };

  const openBetsClose = () => {
    setBetShow(false);
  };

  const toggleAccordion = (index) => {
    setActive((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // bets Palce Modal write

  const handleBackOpen = async (data) => {
    // setBetPlaceModalMobile(true)

    let oneClickBet = localStorage.getItem("oneClickStatus")
      ? JSON.parse(localStorage.getItem("oneClickStatus"))
      : false;
    let oneBetAmount =
      localStorage.getItem("oneBetAmount") &&
        localStorage.getItem("oneBetAmount") != "undefined"
        ? JSON.parse(localStorage.getItem("oneBetAmount"))
        : 0;

    if (oneClickBet) {
      if (data) {
        setBetShow(false);
        setBetSlipData({
          ...data,
          stake: oneBetAmount,
          count: data.odds,
          teamname: data.name,
          teamData: data.teamData,
        });
      }
    } else {
      if (data) {
        setBetShow(false);
        setBetSlipData({
          ...data,
          stake: 0,
          count: data.odds,
          teamname: data.name,
          teamData: data.teamData,
        });
      }
    }
  };

  useEffect(() => {
    if (betSlipData && betSlipData?.stake != 0) {
      placeBet();
    }
  }, [betSlipData]);

  const toggleRowVisibility = (id) => {
    if (hiddenRows.includes(id)) {
      // !== lagane se open close ho jayega
      setHiddenRows(hiddenRows.filter((rowId) => rowId === id));
    } else {
      setHiddenRows([...hiddenRows, id]);
    }
  };

  const userPositionByMarketId = async () => {
    const positionByMarketId = {
      marketId: marketId,
    };
    try {
      const userPositionData = await apiCall(
        "POST",
        "sports/userPositionByMarketId",
        positionByMarketId
      );
      if (userPositionData) {
        const getUserPositionItem = userPositionData.data;
        let oddsPos = [];
        let sessPos = [];
        let returnDataObject = {};
        let returnDataFancyObject = {};

        if (getUserPositionItem?.oddsPosition) {
          oddsPos = getUserPositionItem.oddsPosition;
          oddsPos.forEach((data) => {
            const hasKey = returnDataObject.hasOwnProperty(data._id);
            if (!hasKey) {
              returnDataObject[data._id] = data.totalPosition.toFixed(2);
              setReturnDataObject(returnDataObject);
            }
          });
        }

        if (getUserPositionItem?.sessionPosition) {
          sessPos = getUserPositionItem.sessionPosition;
          sessPos.forEach((data) => {
            const hasKey = returnDataFancyObject.hasOwnProperty(data._id);
            if (!hasKey) {
              returnDataFancyObject[data._id] = data.position;
              setReturnDataFancyObject(returnDataFancyObject);
            }
          });
        }
      }
    } catch (error) {
      console.error("Error fetching user position:", error);
      throw error;
    }
  };
  

  const placeBet = async () => {
    try {
      const betObject = {
        odds: betSlipData.count + "",
        amount: betSlipData.stake,
        selectionId: betSlipData.selectionId + "",
        marketId: marketId + "",
        eventId: eventId,
        betFor: betSlipData.betFor + "",
        run: betSlipData.run ? betSlipData.run + "" : "0",
        oddsType:
          betSlipData.oddsType === "Match Odds"
            ? "matchOdds"
            : betSlipData.oddsType === "Tied Match"
              ? "tiedMatch"
              : betSlipData.oddsType + "",
        type: betSlipData.betType + "",
      };

      if (
        betSlipData.oddsType === "bookmaker" ||
        betSlipData.oddsType === "fancy"
      ) {
        // Do something if needed
      } else {
        betObject["betfairMarketId"] = betSlipData.betfairMarketId + "";
      }
      setBetLoading(true);

      // this.setState({ betLoading: true, betPlaceModal: false })
      if (betSlipData.oddsType === "fancy") {
        let saveBetOdds = await apiCall(
          "POST",
          "sports/sessionBetPlaced",
          betObject
        );

        setBetShow(true);
        setBetLoading(false);
        // setBetPlaceModalMobile(false)

        if (!saveBetOdds.error) {
          // const toastId = toast.success("Success! Your bet has been placed");
          // setTimeout(() => toast.dismiss(toastId), 1000);

          message.success("Success! Your bet has been placed");
          // await userPositionByMarketId()
          await fetchBetLists();
          await matchOddsPos();

          //   dispatch(userAction.userBalance());
          toast.success(saveBetOdds.message, {
            autoClose: 500,
          });
        } else {
          // const toastId = toast.error("Sorry! your bet couldn't be placed.");
          // setTimeout(() => toast.dismiss(toastId), 1000);
          toast.error(saveBetOdds?.data?.message, {
            autoClose: 500,
          });
          message.error("Sorry, your bet couldn't be placed.");
        }
      } else {
        let saveBetOdds = await apiCall(
          "POST",
          "sports/oddBetPlaced",
          betObject
        );

        setBetShow(true);
        setBetLoading(false);
        // setBetPlaceModalMobile(false);

        if (!saveBetOdds.error) {
          message.success("Success! Your bet has been placed");

          // await userPositionByMarketId()
          await fetchBetLists();
          await matchOddsPos();
          //   dispatch(userAction.userBalance());

          toast.success(saveBetOdds?.data?.message, {
            autoClose: 500,
          });
        } else {
          console.log(saveBetOdds);
          message.error("Sorry! your bet couldn't be placed.");
          toast.error(saveBetOdds?.data?.message, {
            autoClose: 500,
          });
          // toast.success("Sorry, your bet couldn't be placed.");
        }
      }
    } catch (error) {
      toast.error(error?.data?.message, {
        autoClose: 500,
      });
      console.error("Error placing bet:", error);
    } finally {
      setBetLoading(false);
      setBetSlipData({
        stake: "0",
        count: 0,
        teamname: "",
        teamData: null,
      });
      setHiddenRows([]);
      dispatch(getUserBalance());
    }
  };

  const fetchBetLists = async () => {
    try {
      const BetListData = {
        fancyBet: true,
        isDeclare: false,
        oddsBet: true,
        marketId: marketId,
      };

      const userBetHistory = await apiCall(
        "POST",
        "sports/betsList",
        BetListData
      );
      if (userBetHistory && userBetHistory.data) {
        const { fancyBetData, oddsBetData } = userBetHistory.data;
        const filteredFancyBetData = fancyBetData
          ? fancyBetData
            .filter((element) => element.isDeclare === 0)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          : [];
        const sortedOddsBetData = oddsBetData
          ? oddsBetData
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          : [];
        setFancyBetData(filteredFancyBetData);
        setOddsBetData(sortedOddsBetData);
        setPositionBetData(userBetHistory.data);
        // return { fancyBetData: filteredFancyBetData, oddsBetData: sortedOddsBetData };
      }
    } catch (error) {
      console.error("Error fetching bet lists:", error);
      throw error;
    }
  };

  const matchOddsPos = async () => {
    let matchOddsPos = await apiCall("POST", "reports/matchOddsRunningPos");
    if (matchOddsPos) {
      localStorage.setItem(
        "matchOddsRunningPos",
        JSON.stringify(matchOddsPos.data)
      );
    }
  };

  const handleFancyPositionModal = (data) => {
    try {
      setFancypositionModal(!fancypositionModal);
      setPositionData(data);
    } catch (error) {
      console.error("Error handling fancy position modal:", error);
    }
  };

  const handleClose = () => {
    setFancypositionModal(false);
  };

  const closeRow = (id) => {
    setHiddenRows(hiddenRows.filter((rowId) => rowId !== id));
  };

  const increaseCount = () => {
    try {
      setBetSlipData((prevData) => {
        const newCount = parseFloat(prevData.count) + 0.01;
        return {
          ...prevData,
          count: newCount.toFixed(2),
        };
      });
    } catch (error) {
      console.error("Error increasing count:", error);
    }
  };
  const openBetInMobile = () => {
    setBetShowMobile(!betShowMobile);
    setTvShow(false);
    setScoreShow(false);
  };
  const decreaseCount = () => {
    try {
      setBetSlipData((prevData) => {
        const newCount = parseFloat(prevData.count) - 0.01;
        return {
          ...prevData,
          count: newCount.toFixed(2),
        };
      });
    } catch (error) {
      console.error("Error decreasing count:", error);
    }
  };

  const openRuleModal = () => {
    setIsRulesOpen((prevState) => !prevState);
  };
  const closeRuleModal = () => setIsRulesOpen(false);



  // const [isPopoverVisible, setPopoverVisible] = useState(false);


  // // Toggle popover visibility on button click
  // const togglePopover = (value) => {
  //   setPopoverVisible(!isPopoverVisible);
  // };
  const [isPopoverVisible, setPopoverVisible] = useState({
    matchOdds: false,
    bookmakerStake: false,
    soccerStake: false,
    tossStake: false,
    tieStake: false
  });

  const togglePopover = (popover) => {
    setPopoverVisible(prevState => ({
      ...prevState,
      [popover]: !prevState[popover]
    }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClickBetChange = () => {
    setOneClickBet((prevState) => {
      const newState = !prevState;
      localStorage.setItem("oneClickStatus", newState);
      return newState;
    });
  };

  const match = inplayMatch?.matchName ? inplayMatch?.matchName : "";
  let team1 = "",
    team2 = "";

  if (match.includes(" v ") || match.includes(" V ")) {
    [team1, team2] = match.split(/ v | V /);
  } else if (match.includes(" vs ") || match.includes(" VS ")) {
    [team1, team2] = match.split(/ vs | VS /);
  }

  const [acceptAllOdds, setAcceptAllOdds] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [betValue, setBetValue] = useState("100");
  const [betSlipDataNew, setBetSlipDataNew] = useState(
    JSON.parse(localStorage.getItem("betSlipData"))
  );
  const [oneBetAmountlocal, setoneBetAmountlocal] = useState(
    localStorage.getItem("oneBetAmount")
  );

  const [localBetSlipData, setLocalBetSlipData] = useState(betSlipDataNew);

  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = () => {
    setIsEditing(false);

    setBetSlipDataNew(localBetSlipData);

    localStorage.setItem("betSlipData", JSON.stringify(localBetSlipData));

    setIsEditing(false);

    console.log("Saved Bet Values:", localBetSlipData);
  };
  const handleBetStackUpdate = (e, index) => {
    const updatedBetSlipData = [...localBetSlipData];

    updatedBetSlipData[index] = e.target.value;

    setLocalBetSlipData(updatedBetSlipData);
  };

  const updateStackSelected = async (num) => {
    if (isEditing) return;
    let resData = {
      isOneClickBet: true,
      oneClickBetAmount: num,
    };
    const response = await apiCall("POST", `user/updateOneClickBet`, resData);
    if (response.error === false) {
      toast.success(response.message, {
        autoClose: 500,
        className: "custom-toast",
      });
      localStorage.setItem("oneBetAmount", num);
      try {
        setoneBetAmountlocal(num);
      } catch (error) {
        console.error("Error updating stake:", error);
      }
    }
  };



  return isLoading ? (
    <Loader />
  ) : (
    <section className="afterFooter mb-12 ">
      {/* <div className="bg-[#272727] w-full px-3 flex justify-center">
        <NavigatePathComponent showPathName={inplayMatch && inplayMatch?.matchName
          ? inplayMatch?.matchName
          : ""} />

      </div> */}
      {stakeModal && <EditStack closeModal={() => setStakeModal(false)} />}
      {betListModal && (
        <>
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 p-4" />

          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-secondary rounded-lg shadow-lg w-96 border-2 border-button py-2">
              <div className="max-h-96 overflow-y-auto">
                <BetListComponentMobile
                  oddsBetData={oddsBetData}
                  fancyBetData={fancyBetData}
                  matchName={inplayMatch?.matchName}
                  setBetListModal={setBetListModal}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {!betShow && !stakeModal && (
        <div className="fixed  sm:hidden inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white  rounded-lg max-w-md w-full relative">
            <div>
              <BetPlaceDesktop
                setStakeModal={setStakeModal}
                closeRow={closeRow}
                betShow={betShow}
                setBetShow={setBetShow}
                matchName={inplayMatch?.matchName}
                openBets={openBets}
                betSlipData={betSlipData}
                placeBet={placeBet}
                count={betSlipData.count}
                betLoading={betLoading}
                increaseCount={increaseCount}
                setBetSlipData={setBetSlipData}
                decreaseCount={decreaseCount}
              />
            </div>
          </div>
        </div>
      )}
      <div className="w-full  relative bg-white">
        <div className="flex w-full">

          <div className="lg:w-8/12 w-full h-auto lg:px-1 md:pb-0 pb-6">
            <div>
              {/* <div className="rounded-md bg-gray-600 w-full px-1 py-1 flex gap-1 justify-end">
                <div className=" py-1 rounded-sm bg-black w-full  text-white px-2">
                  <div className="p-0.5  rounded-sm w-full flex justify-between text-white px-2">
                    <div className="text-xs font-bold flex items-center space-x-1">
                      <span
                        className={`px-4 rounded py-0.5 cursor-pointer ${activeTab === "all"
                          ? "bg-button text-black"
                          : "text-white"
                          }`}
                        onClick={() => handleTabClick("all")}
                      >
                        All
                      </span>
                      <span
                        className={`px-2 rounded py-0.5 cursor-pointer ${activeTab === "matchOdds"
                          ? "bg-button text-black"
                          : "text-white"
                          }`}
                        onClick={() => handleTabClick("matchOdds")}
                      >
                        Match Odds
                      </span>
                      <span
                        className={`px-2 rounded py-0.5 cursor-pointer ${activeTab === "Fancy"
                          ? "bg-button text-black"
                          : "text-white"
                          }`}
                        onClick={() => handleTabClick("Fancy")}
                      >
                        Fancy
                      </span>
                      <span
                        className={`px-2 rounded py-0.5 cursor-pointer hidden sm:block lg:hidden`}
                        onClick={() => setBetListModal(true)}
                      >
                        Bet List
                      </span>
                    </div>
                    <div className="text-xs font-bold flex items-center space-x-2">
                      <span
                        className={`px-2 text-[10px] ${showStack
                          ? "bg-button text-black"
                          : "bg-[#2D2E2E] text-white"
                          } text-white rounded py-0.5`}
                        onClick={() => {
                          toggleStack();

                          const currentStatus = JSON.parse(localStorage.getItem('oneClickStatus'));

                          const newStatus = currentStatus === true ? false : true;


                          localStorage.setItem('oneClickStatus', JSON.stringify(newStatus));
                        }}
                      >
                        1 click betting
                      </span>
                    </div>
                  </div>

                  {showStack && (
                    <>
                      <div className="bg-[#2D2E2E] py-1.5 mt-1 hidden md:flex  rounded-md  w-full  justify-between items-center  text-white px-2">
                        <div
                          className="text-[11px] font-bold "
                          onClick={() => setShowStack(false)}
                        >
                          1 click Bet
                        </div>
                        <div className="flex space-x-2 text-xs font-bold">
                          {localBetSlipData?.map((ele, index) => (
                            <input
                              key={index}
                              type="text"
                              readOnly={!isEditing}
                              // disabled={!isEditing}
                              value={ele}
                              onClick={() => updateStackSelected(ele)}
                              onChange={(e) => handleBetStackUpdate(e, index)}
                              className={`text-center ${oneBetAmountlocal == ele
                                ? "border-button border text-button"
                                : "border border-white"
                                } py-1 w-20  bg-transparent outline-none  `}
                            />
                          ))}
                        </div>
                        <div>
                          {isEditing ? (
                            <div className="flex items-center text-[10px] font-semibold space-x-2">
                              <button
                                onClick={handleSaveClick}
                                className="bg-green-600 text-white px-2 py-1  rounded"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => {
                                  setIsEditing(false);
                                  setLocalBetSlipData(betSlipDataNew);
                                }}
                                className="bg-red-600 text-white px-2 py-1  rounded"
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center space-x-2">
                              <span
                                className="text-[11px] flex gap-1 items-center font-bold cursor-pointer"
                                onClick={handleEditClick}
                              >
                                <FaEdit size={12} className="text-yellow-500" />{" "}
                                Edit
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="bg-[#2D2E2E] py-1.5 mt-1 block md:hidden  rounded-md  w-full  justify-between items-center  text-white px-2">
                        <div
                          className="text-[11px] font-bold "
                          onClick={() => setShowStack(false)}
                        >
                          1 click Bet
                        </div>
                        <div className="flex justify-between w-full items-center">
                          <div className="flex space-x-2 text-xs font-bold ">
                            {localBetSlipData?.map((ele, index) => (
                              <input
                                key={index}
                                type="text"
                                readOnly={!isEditing}
                                // disabled={!isEditing}
                                value={ele}
                                onClick={() => updateStackSelected(ele)}
                                onChange={(e) => handleBetStackUpdate(e, index)}
                                className={`text-center ${oneBetAmountlocal == ele
                                  ? "border-button border text-button"
                                  : "border border-white"
                                  } py-1 w-20  bg-transparent outline-none  `}
                              />
                            ))}
                          </div>
                          <div>
                            {isEditing ? (
                              <div className="flex items-center text-[10px] font-semibold space-x-2">
                                <button
                                  onClick={handleSaveClick}
                                  className="bg-green-600 text-white px-2 py-1  rounded"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={() => {
                                    setIsEditing(false);
                                    setLocalBetSlipData(betSlipDataNew);
                                  }}
                                  className="bg-red-600 text-white px-2 py-1  rounded"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center space-x-2">
                                <span
                                  className="text-[11px] flex gap-1 items-center font-bold cursor-pointer"
                                  onClick={handleEditClick}
                                >
                                  <FaEdit
                                    size={12}
                                    className="text-yellow-500"
                                  />{" "}
                                  Edit
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div> */}

              {/* copy design stary */}

              <div className={`border-white border-b-[1px] font-semibold bg-${primaryColor}`}>
                <div className="flex text-white justify-between items-center px-2 py-1 text-[13px] ">
                  <div className="">
                    {inplayMatch?.matchName}
                  </div>
                  <div className="">Bet-Open</div>

                </div>
              </div>

              <div className="sm:hidden block">
                <div className={`border-secondary bg-${primaryColor}`}>
                  <div className="flex text-white justify-between items-center px-2">
                    <div className="flex gap-2 py-1 text-sm font-semibold">Live TV
                    <div
                          onClick={() => setFullScreen((state) => !state)}
                          className="text-white bg-button rounded-sm px-2 py-0.5 text-xs font-semibold"
                        >
                          {fullscreen ? "HS" : "FS"}
                        </div>
                    </div>
                    <div
                      onClick={() => handleChange()}
                      className={`flex items-center justify-center w-12 h-full rounded-full px-1 ${isChecked ? 'bg-[#28a745]' : 'bg-[#FF0000]'}`}>
                      <label className="flex items-center cursor-pointer relative">
                        <input
                          type="checkbox"
                          className="sr-only"
                          onChange={handleChange}
                          checked={isChecked}
                        />
                        <div
                          className={`block relative w-[65px] h-[22px] py-[1px] text-center rounded-full before:absolute before:bg-white before:w-[20px] before:h-[20px] before:text-center before:rounded-full before:transition-all before:duration-500 ${isChecked
                            ? 'before:left-9'
                            : 'before:left-3 peer-checked:before:left-0 peer-checked:before:bg-white'
                            }`}
                        ></div>
                      </label>
                    </div>
                  </div>
                  <div>
                    {inplayMatch.isTv ? (
                      <>
                        {isScorecardOpen === "tv" && inplayMatch.tvUrl && (
                          <div className="bg-white w-full rounded-lg border-2 border-secondary">
                            <div className="details">
                              <div
                                className={`w-full relative md:text-sm text-[10px]`}
                              >
                                <iframe
                                  src={
                                    inplayMatch && inplayMatch.tvUrl
                                      ? inplayMatch.tvUrl
                                      : ""
                                  }
                                  title=" "
                                  loading="lazy"
                                  className="w-[100%] h-[200px]"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="market-info p-0 match-info market-wrapper-info position-relative sm:hidden block  ">

                {inplayMatch.isScore ? (
                  <>
                    {scoreShow && (
                      <div
                        className={`bg-white w-full ${fullscreen ? "h-[220px]" : "h-[110px]"
                          }`}
                      >
                        <div className="details">
                          <div
                            className={`w-full relative md:text-sm text-[10px]`}
                          >
                            <iframe
                              src={
                                inplayMatch && inplayMatch.scoreIframe
                                  ? inplayMatch.scoreIframe
                                  : ""
                              }
                              title=" "
                              loading="lazy"
                              className={`w-[100%] ${fullscreen ? "h-[220px]" : "h-[110px]"
                                }`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>

              {/* <div className="">
                <div className="match-container flex text-center bg-white py-1 rounded-lg my-2 relative">
                  <div className="w-full">

                    <div className="match-date font-semibold text-gray-500">
                      {
                        (() => {
                          const inputMoment = moment(inplayMatch?.matchDate, "DD-MM-YYYY HH:mm:ss A");
                          const currentMoment = moment();
                          return currentMoment.isSameOrAfter(inputMoment) ?
                            <div className="absolute top-[5px] left-[10px] flex items-center">
                              <img alt="" src="/images/in-play-events-icon.png" className="w-5 h-5" />
                              <span className="text-[#008000] text-[13px] font-signika uppercase">Live</span>
                            </div>
                            : '';
                        })()
                      }
                      <span className="md:text-sm text-[13px]">

                        {" "}
                        {inplayMatch && inplayMatch?.matchDate
                          ? inplayMatch?.matchDate
                          : ""}
                      </span>
                    </div>
                    <div className="match-teams w-full flex justify-center items-center m-0 mt-1 text-[13px] font-semibold">
                      <span className="col p-0 text-right">
                        <span className="inline font-signika">
                          {team1 ? team1 : "Team 1"}
                        </span>
                      </span>
                      <div className="vs-circle mx-2 bg-yellow-400 px-1.5 py-1 text-[11px] font-signika font-semibold rounded-full">
                        <div>VS</div>
                      </div>
                      <span className="col p-0 text-left">
                        <span className="inline font-signika">
                          {team2 ? team2 : "Team 2"}
                        </span>
                      </span>
                    </div>
                    <div className="match-type mt-1 text-[13px] font-bold ">
                      <span className="inline font-signika">
                        {inplayMatch && inplayMatch?.seriesName
                          ? inplayMatch?.seriesName
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`market-button grid grid-cols-3 justify-items-center divide-x-2 text-xs md:text-sm divide-secondary gap-2  rounded-md border-2 border-secondary my-1 ${isScorecardOpen === false ? "bg-black" : "bg-button"
                    }`}
                >
                  <button
                    onClick={() => setIsScorecardOpen("tv")}
                    className={`btn-tv-score text-center flex items-center space-x-2 px-4 py-2 font-semibold ${isScorecardOpen === "tv" ? "text-black" : "text-gray-500"
                      }`}
                  >
                    <FaTv size={17} />
                    <span>Live Tv</span>
                  </button>
                  <button
                    className={`btn-tv-score text-center flex items-center space-x-2 px-4 py-2 font-semibold ${isScorecardOpen === "score"
                      ? "text-black"
                      : "text-gray-500"
                      }`}
                    onClick={() => setIsScorecardOpen("score")}
                  >
                    <FaRegListAlt size={16} />
                    <span>Scorecard</span>
                  </button>
                  <button
                    onClick={() => setBetListModal(true)}
                    className={`btn-tv-score text-center flex items-center space-x-2 px-4 py-2 font-semibold ${isScorecardOpen === "tv" ? "text-black" : "text-gray-500"
                      }`}
                  >
                    <FaList size={17} />
                    <span> Bet List</span>
                  </button>
                </div>
                <div
                  className={`market-button grid grid-cols-2 gap-2  rounded-md border-2 border-secondary my-1 `}
                ></div>
                <div>
                  {isScorecardOpen === "score" && inplayMatch.isScore ? (
                    <div className="border-2 border-secondary rounded-lg  my-1">
                      <div className="flex justify-between items-center px-2">
                        <div
                          onClick={() => setFullScreen((state) => !state)}
                          className="text-white bg-button rounded-sm px-2 py-0.5 text-xs font-semibold"
                        >
                          {fullscreen ? "HS" : "FS"}
                        </div>
                        <div
                          className="px-2 py-1 text-white bg-red-500 rounded-full text-xs"
                          onClick={() => setIsScorecardOpen(false)}
                        >
                          X
                        </div>
                      </div>
                      <div
                        className={`bg-white w-full ${fullscreen ? "h-[220px]" : "h-[110px]"
                          }`}
                      >
                        <div className="details">
                          <div
                            className={`w-full relative md:text-sm text-[10px]`}
                          >
                            <iframe
                              src={
                                inplayMatch && inplayMatch.scoreIframe
                                  ? inplayMatch.scoreIframe
                                  : ""
                              }
                              title=" "
                              loading="lazy"
                              className={`w-[100%] ${fullscreen ? "h-[220px]" : "h-[110px]"
                                }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {inplayMatch.isTv ? (
                    <>
                      {isScorecardOpen === "tv" && inplayMatch.tvUrl && (
                        <div className="bg-white w-full rounded-lg border-2 border-secondary">
                          <div className="flex justify-end items-center px-2">
                            <div
                              className="px-2 py-1 text-white bg-red-500 rounded-full text-xs"
                              onClick={() => setIsScorecardOpen(false)}
                            >
                              X
                            </div>
                          </div>
                          <div className="details">
                            <div
                              className={`w-full relative md:text-sm text-[10px]`}
                            >
                              <iframe
                                src={
                                  inplayMatch && inplayMatch.tvUrl
                                    ? inplayMatch.tvUrl
                                    : ""
                                }
                                title=" "
                                loading="lazy"
                                className="w-[100%] h-[300px]"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div> */}

              {betShowMobile ? (
                <OpenBetsMobile marketId={marketId} />
              ) : (
                <div className="bg-white h-auto mb-2 overflow-y-auto">
                  <div className="space-y-4">
                    {inplayMatch?.isMatchOdds &&
                      <>

                        {Object.values(finalSocket).map((element, index) => element.marketType === "Match Odds" && (
                          <div>
                            <div className="rounded-md divide-y divide-gray-400">
                              <header className={` bg-${primaryColor} z-[1] relative`}>
                                <div className=" items-center flex relative z-0">
                                  <div className="px-1 flex flex-1 justify-start items-center">
                                    {/* <img
                                        className="h-4 w-4"
                                        src="/winjaImages/DashboardIcon/a-pin-mobile.svg"
                                        alt="icon"
                                      /> */}
                                    <span className="border border-white rounded">
                                      <TbPinnedFilled size={15} className="text-white" />
                                    </span>
                                    <span className="capitalize items-center py-1.5 px-1 inline-flex text-sm text-white font-semibold ">
                                      {element && element.marketType
                                        ? element.marketType
                                        : "NA"}
                                    </span>
                                  </div>

                                  <div className='text-white font-semibold text-xs'>
                                    Min: 100 |
                                    Max: {isMatchCoin?.max}
                                  </div>

                                  <span
                                    className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm cursor-pointer flex justify-center items-center"
                                    onClick={() =>
                                      setIsMatchOddsHide(
                                        !isMatchOddsHide
                                      )
                                    }
                                  >
                                    {isMatchOddsHide ? (
                                      <FaMinus />
                                    ) : (
                                      <FaPlus />
                                    )}
                                  </span>

                                  {/* <div className="relative  rounded-tr-md flex flex-wrap justify-center items-center">
                                    <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                      <span
                                        className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                        onClick={() => togglePopover('matchOdds')}
                                      >
                                        <div className="relatives">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="2"
                                            height="8"
                                            viewBox="0 0 2 8"
                                            fill="none"
                                          >
                                            <path
                                              _ngcontent-lmb-c38=""
                                              d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                              fill="white"
                                            ></path>
                                          </svg>
                                        </div>
                                      </span>
                                    </div>

                                    <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.matchOdds && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                      <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{isMatchCoin?.min} - {isMatchCoin?.max}</span></p>
                                      <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {isMatchCoin?.max}</span></p>
                                    </div>)}
                                    </div>

                                  </div> */}
                                </div>
                                <div className=" items-center flex relative bg-white z-[-1] w-full">
                                  <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex ">
                                    <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-black">
                                      {/* {matchScoreDetails?.team_data?.length > 0
                                        ? matchScoreDetails?.team_data?.length
                                        : null}{" "}
                                      Selection */}
                                    </div>
                                  </div>

                                  <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                    <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                      <span className="lg:block hidden"></span>
                                      <span className="lg:block hidden"></span>
                                      <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                        Back
                                      </span>
                                      <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                        Lay
                                      </span>
                                      <span className="lg:block hidden"></span>
                                      <span className="lg:block hidden"></span>
                                    </div>
                                  </div>
                                </div>
                              </header>
                              {isMatchOddsHide && (
                                <>
                                  {element &&
                                    element.runners &&
                                    element.runners.length > 0
                                    ? element.runners.map(
                                      (elementtemp, index) => (
                                        <>
                                          <div
                                            className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                          >
                                            <div className="w-1/2 lg:w-[25%] 2xl:w-1/2 flex px-2">
                                              <div className="w-11/12  leading-3 flex items-center text-slate-700">
                                                <span class="text-sm font-bold">
                                                  <span className="text-black text-xs font-bold">
                                                    {
                                                      elementtemp.selectionName
                                                    }{" "}
                                                    <br />
                                                    <span
                                                      key={index}
                                                      className={
                                                        positionObj[
                                                          elementtemp
                                                            .selectionId
                                                        ] > 0
                                                          ? "text-green-500"
                                                          : positionObj[
                                                            elementtemp
                                                              .selectionId
                                                          ] < 0
                                                            ? "text-red-500"
                                                            : "text-green-500"
                                                      }
                                                    >
                                                      {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}

                                                      {positionObj[
                                                        elementtemp
                                                          .selectionId
                                                      ]
                                                        ? (
                                                          Math.floor(
                                                            Number(
                                                              positionObj[
                                                              elementtemp
                                                                .selectionId
                                                              ]
                                                            ) * 100
                                                          ) / 100
                                                        ).toFixed(2)
                                                        : 0}
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="w-1/2 lg:w-[75%] 2xl:w-1/2 grid grid-cols-6 gap-1">
                                              {elementtemp &&
                                                elementtemp.ex &&
                                                elementtemp.ex
                                                  .availableToBack &&
                                                elementtemp.ex
                                                  .availableToBack.length >
                                                0 ? (
                                                <>
                                                  {elementtemp.ex.availableToBack
                                                    .slice(1)
                                                    .map(
                                                      (
                                                        tempData,
                                                        index
                                                      ) => (
                                                        <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                          <Blinking
                                                            price={
                                                              tempData.price
                                                            }
                                                            size={
                                                              tempData.size
                                                            }
                                                            color={
                                                              "bg-[#D7E8F4]"
                                                            }
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              ""
                                                            }
                                                          />
                                                        </span>
                                                      )
                                                    )}
                                                </>
                                              ) : null}

                                              {elementtemp &&
                                                elementtemp.ex &&
                                                elementtemp.ex
                                                  .availableToBack &&
                                                elementtemp.ex
                                                  .availableToBack.length >
                                                0 ? (
                                                <>
                                                  {elementtemp.ex.availableToBack
                                                    .slice(0, 1)
                                                    .map(
                                                      (
                                                        tempData,
                                                        index
                                                      ) => (
                                                        <>
                                                          <span
                                                            className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                            onClick={() => {
                                                              handleBackOpen(
                                                                {
                                                                  data: tempData,
                                                                  type: "Yes",
                                                                  odds: tempData.price,
                                                                  name: elementtemp.selectionName,
                                                                  nameOther:
                                                                    element.runners,
                                                                  betFor:
                                                                    "matchOdds",
                                                                  oddsType:
                                                                    element.marketType,
                                                                  betType:
                                                                    "L",
                                                                  selectionId:
                                                                    elementtemp.selectionId,
                                                                  teamData:
                                                                    tempData.price,
                                                                  betfairMarketId:
                                                                    element.marketId,
                                                                  price:
                                                                    elementtemp
                                                                      .ex
                                                                      .availableToLay[0]
                                                                      .price,
                                                                  size: elementtemp
                                                                    .ex
                                                                    .availableToLay[0]
                                                                    .size,
                                                                  position:
                                                                    returnDataObject,
                                                                  newPosition:
                                                                    returnDataObject,
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <Blinking
                                                              price={
                                                                tempData.price
                                                              }
                                                              size={
                                                                tempData.size
                                                              }
                                                              color={
                                                                "bg-[#72BBEF]"
                                                              }
                                                              blinkColor={
                                                                "bg-[#CDEBEB]"
                                                              }
                                                              textColors={
                                                                "text-black"
                                                              }
                                                              boderColors={
                                                                "border-[#489bbd]"
                                                              }
                                                            />
                                                          </span>
                                                          <span
                                                            className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                            onClick={() => {
                                                              toggleRowVisibility(
                                                                elementtemp.selectionId
                                                              );
                                                              handleBackOpen(
                                                                {
                                                                  data: tempData,
                                                                  type: "Yes",
                                                                  odds: tempData.price,
                                                                  name: elementtemp.selectionName,
                                                                  nameOther:
                                                                    element.runners,
                                                                  betFor:
                                                                    "matchOdds",
                                                                  oddsType:
                                                                    element.marketType,
                                                                  betType:
                                                                    "L",
                                                                  selectionId:
                                                                    elementtemp.selectionId,
                                                                  teamData:
                                                                    tempData.price,
                                                                  betfairMarketId:
                                                                    element.marketId,
                                                                  price:
                                                                    elementtemp
                                                                      .ex
                                                                      .availableToLay[0]
                                                                      .price,
                                                                  size: elementtemp
                                                                    .ex
                                                                    .availableToLay[0]
                                                                    .size,
                                                                  position:
                                                                    returnDataObject,
                                                                  newPosition:
                                                                    returnDataObject,
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <Blinking
                                                              price={
                                                                tempData.price
                                                              }
                                                              size={
                                                                tempData.size
                                                              }
                                                              color={
                                                                "bg-[#72BBEF]"
                                                              }
                                                              blinkColor={
                                                                "bg-[#CDEBEB]"
                                                              }
                                                              textColors={
                                                                "text-black"
                                                              }
                                                              boderColors={
                                                                "border-[#489bbd]"
                                                              }
                                                            />
                                                          </span>
                                                        </>
                                                      )
                                                    )}
                                                </>
                                              ) : null}

                                              {elementtemp &&
                                                elementtemp.ex &&
                                                elementtemp.ex
                                                  .availableToLay &&
                                                elementtemp.ex
                                                  .availableToLay.length > 0
                                                ? elementtemp.ex.availableToLay.map(
                                                  (tempData, index) => (
                                                    <>
                                                      {index === 0 ? (
                                                        <>
                                                          <span
                                                            className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                            onClick={() => {
                                                              toggleRowVisibility(
                                                                elementtemp.selectionId
                                                              );
                                                              handleBackOpen(
                                                                {
                                                                  data: tempData,
                                                                  type: "No",
                                                                  odds: tempData.price,
                                                                  name: elementtemp.selectionName,
                                                                  nameOther:
                                                                    element.runners,
                                                                  betFor:
                                                                    "matchOdds",
                                                                  oddsType:
                                                                    element.marketType,
                                                                  betType:
                                                                    "K",
                                                                  selectionId:
                                                                    elementtemp.selectionId,
                                                                  teamData:
                                                                    tempData.price,
                                                                  betfairMarketId:
                                                                    element.marketId,
                                                                  price:
                                                                    elementtemp
                                                                      .ex
                                                                      .availableToBack[0]
                                                                      .price,
                                                                  size: elementtemp
                                                                    .ex
                                                                    .availableToBack[0]
                                                                    .size,
                                                                  position:
                                                                    returnDataObject,
                                                                  newPosition:
                                                                    returnDataObject,
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <Blinking
                                                              price={
                                                                tempData.price
                                                              }
                                                              size={
                                                                tempData.size
                                                              }
                                                              color={
                                                                "bg-[#FAA9BA]"
                                                              }
                                                              blinkColor={
                                                                "bg-[#CDEBEB]"
                                                              }
                                                              textColors={
                                                                "text-black"
                                                              }
                                                              boderColors={
                                                                "border-[#f996ab]"
                                                              }
                                                            />
                                                          </span>

                                                          <span
                                                            className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                            onClick={() => {
                                                              toggleRowVisibility(
                                                                elementtemp.selectionId
                                                              );
                                                              handleBackOpen(
                                                                {
                                                                  data: tempData,
                                                                  type: "No",
                                                                  odds: tempData.price,
                                                                  name: elementtemp.selectionName,
                                                                  nameOther:
                                                                    element.runners,
                                                                  betFor:
                                                                    "matchOdds",
                                                                  oddsType:
                                                                    element.marketType,
                                                                  betType:
                                                                    "K",
                                                                  selectionId:
                                                                    elementtemp.selectionId,
                                                                  teamData:
                                                                    tempData.price,
                                                                  betfairMarketId:
                                                                    element.marketId,
                                                                  price:
                                                                    elementtemp
                                                                      .ex
                                                                      .availableToBack[0]
                                                                      .price,
                                                                  size: elementtemp
                                                                    .ex
                                                                    .availableToBack[0]
                                                                    .size,
                                                                  position:
                                                                    returnDataObject,
                                                                  newPosition:
                                                                    returnDataObject,
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <Blinking
                                                              price={
                                                                tempData.price
                                                              }
                                                              size={
                                                                tempData.size
                                                              }
                                                              color={
                                                                "bg-[#FAA9BA]"
                                                              }
                                                              blinkColor={
                                                                "bg-[#CDEBEB]"
                                                              }
                                                              textColors={
                                                                "text-black"
                                                              }
                                                              boderColors={
                                                                "border-[#f996ab]"
                                                              }
                                                            />
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                            <Blinking
                                                              price={
                                                                tempData.price
                                                              }
                                                              size={
                                                                tempData.size
                                                              }
                                                              color={
                                                                "bg-[#EFD3D9]"
                                                              }
                                                              blinkColor={
                                                                "bg-[#CDEBEB]"
                                                              }
                                                              textColors={
                                                                "text-black"
                                                              }
                                                              boderColors={
                                                                ""
                                                              }
                                                            />
                                                          </span>
                                                        </>
                                                      )}
                                                    </>
                                                  )
                                                )
                                                : null}
                                            </div>
                                          </div>

                                          {hiddenRows.includes(
                                            elementtemp.selectionId
                                          ) && (
                                              <BetPlaceMobile
                                                setStakeModal={
                                                  setStakeModal
                                                }
                                                closeRow={closeRow}
                                                closeSec={
                                                  elementtemp.selectionId
                                                }
                                                matchName={
                                                  inplayMatch?.matchName
                                                }
                                                openBets={openBets}
                                                betSlipData={betSlipData}
                                                placeBet={placeBet}
                                                count={betSlipData.count}
                                                setBetSlipData={setBetSlipData}
                                                betLoading={betLoading}

                                                increaseCount={
                                                  increaseCount
                                                }
                                                decreaseCount={
                                                  decreaseCount
                                                }
                                              />
                                            )}
                                        </>
                                      )
                                    )
                                    : null}
                                </>
                              )}
                            </div>
                          </div>
                        )
                        )}
                      </>
                    }

                    <>
                      {Object.values(otherFinalSocket).map(
                        (element, index) =>
                          element.marketType !== "Tied Match" && element.marketType !== "Match Odds" && element.marketType !== "To Win the Toss" && element.marketType !== "Completed Match" && (
                            <div>
                              <div className="rounded-md divide-y divide-gray-400">
                                <header className={` bg-${primaryColor} z-[1] relative`}>
                                  <div className=" items-center flex relative z-0">
                                    <div className="px-1 flex flex-1 justify-start items-center">
                                      <span className="uppercase items-center py-1.5 px-1 rounded-tl-md inline-flex text-sm font-semibbold text-white ">
                                        {element && element.marketType
                                          ? element.marketType
                                          : "NA"}
                                      </span>
                                      <span></span>
                                    </div>

                                    <div className='text-white font-semibold text-xs'>
                                      Min: 100 |
                                      Max: 2500000
                                    </div>

                                    <span
                                      className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                      onClick={() =>
                                        setIsMatchOddsHide(
                                          !isMatchOddsHide
                                        )
                                      }
                                    >
                                      {isMatchOddsHide ? (
                                        <FaMinus />
                                      ) : (
                                        <FaPlus />
                                      )}
                                    </span>

                                    {/* <div className="bg-[#333333] relative  rounded-tr-md flex flex-wrap justify-center items-center">
                                      <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">


                                        <span
                                          className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                          onClick={() => togglePopover('soccerStake')}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="2"
                                            height="8"
                                            viewBox="0 0 2 8"
                                            fill="none"
                                          >
                                            <path
                                              _ngcontent-lmb-c38=""
                                              d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                              fill="white"
                                            ></path>
                                          </svg>
                                        </span>


                                      </div>

                                      <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.soccerStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                        <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">50 - 200000</span></p>
                                        <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> 2500000</span></p>
                                      </div>

                                      )}
                                      </div>



                                    </div> */}
                                  </div>
                                  <div className=" items-center flex relative bg-white z-[-1] w-full">
                                    <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex ">
                                      <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-black">
                                        {/* {matchScoreDetails?.team_data?.length > 0
                                        ? matchScoreDetails?.team_data?.length
                                        : null}{" "}
                                      Selection */}
                                      </div>
                                    </div>

                                    <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                      <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                        <span className="lg:block hidden"></span>
                                        <span className="lg:block hidden"></span>
                                        <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                          Back
                                        </span>
                                        <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                          Lay
                                        </span>
                                        <span className="lg:block hidden"></span>
                                        <span className="lg:block hidden"></span>
                                      </div>
                                    </div>
                                  </div>
                                </header>
                                {isMatchOddsHide && (
                                  <>
                                    {element &&
                                      element.runners &&
                                      element.runners.length > 0
                                      ? element.runners.map(
                                        (elementtemp, index) => (
                                          <>
                                            <div
                                              className={` bg-[#fffff] shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                            >
                                              <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex px-2">
                                                <div className="w-11/12  leading-3 flex items-center text-black">
                                                  <span class="text-sm font-bold">
                                                    <span className="text-black text-xs font-bold">
                                                      {
                                                        elementtemp.selectionName
                                                      }{" "}
                                                      <br />
                                                      <span
                                                        key={index}
                                                        className={
                                                          positionObj[
                                                            elementtemp
                                                              .selectionId
                                                          ] > 0
                                                            ? "text-green-500"
                                                            : positionObj[
                                                              elementtemp
                                                                .selectionId
                                                            ] < 0
                                                              ? "text-red-500"
                                                              : "text-green-500"
                                                        }
                                                      >

                                                        {/* {betSlipData?.position && typeof betSlipData.position === 'object' && elementtemp?.selectionId && betSlipData.position[elementtemp?.selectionId] ?
                                                              <span className={`${betSlipData.position[elementtemp?.selectionId] > 0 ? 'text-green-700' : 'text-red-700'}`}>
                                                                {betSlipData.position[elementtemp?.selectionId]}
                                                              </span>


                                                              : 0} */}
                                                        {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}

                                                        {positionObj[
                                                          elementtemp
                                                            .selectionId
                                                        ]
                                                          ? (
                                                            Math.floor(
                                                              Number(
                                                                positionObj[
                                                                elementtemp
                                                                  .selectionId
                                                                ]
                                                              ) * 100
                                                            ) / 100
                                                          ).toFixed(2)
                                                          : ''}
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="w-1/2 lg:w-[75%] xl:w-1/2 grid grid-cols-6 gap-1">
                                                {elementtemp &&
                                                  elementtemp.ex &&
                                                  elementtemp.ex
                                                    .availableToBack &&
                                                  elementtemp.ex
                                                    .availableToBack.length >
                                                  0 ? (
                                                  <>
                                                    {elementtemp.ex.availableToBack
                                                      .slice(1)
                                                      .map(
                                                        (
                                                          tempData,
                                                          index
                                                        ) => (
                                                          <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                            <Blinking
                                                              price={
                                                                tempData.price
                                                              }
                                                              size={
                                                                tempData.size
                                                              }
                                                              color={
                                                                "bg-[#D7E8F4]"
                                                              }
                                                              blinkColor={
                                                                "bg-[#CDEBEB]"
                                                              }
                                                              textColors={
                                                                "text-black"
                                                              }
                                                              boderColors={
                                                                ""
                                                              }
                                                            />
                                                          </span>
                                                        )
                                                      )}
                                                  </>
                                                ) : null}

                                                {elementtemp &&
                                                  elementtemp.ex &&
                                                  elementtemp.ex
                                                    .availableToBack &&
                                                  elementtemp.ex
                                                    .availableToBack.length >
                                                  0 ? (
                                                  <>
                                                    {elementtemp.ex.availableToBack
                                                      .slice(0, 1)
                                                      .map(
                                                        (
                                                          tempData,
                                                          index
                                                        ) => (
                                                          <>
                                                            <span
                                                              className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                              onClick={() => {
                                                                handleBackOpen(
                                                                  {
                                                                    data: tempData,
                                                                    type: "Yes",
                                                                    odds: tempData.price,
                                                                    name: elementtemp.selectionName,
                                                                    nameOther:
                                                                      element.runners,
                                                                    betFor:
                                                                      "matchOdds",
                                                                    oddsType:
                                                                      element.marketType,
                                                                    betType:
                                                                      "L",
                                                                    selectionId:
                                                                      elementtemp.selectionId,
                                                                    teamData:
                                                                      tempData.price,
                                                                    betfairMarketId:
                                                                      element.marketId,
                                                                    price:
                                                                      elementtemp
                                                                        .ex
                                                                        .availableToLay[0]
                                                                        .price,
                                                                    size: elementtemp
                                                                      .ex
                                                                      .availableToLay[0]
                                                                      .size,
                                                                    position:
                                                                      returnDataObject,
                                                                    newPosition:
                                                                      returnDataObject,
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <Blinking
                                                                price={
                                                                  tempData.price
                                                                }
                                                                size={
                                                                  tempData.size
                                                                }
                                                                color={
                                                                  "bg-[#72BBEF]"
                                                                }
                                                                blinkColor={
                                                                  "bg-[#CDEBEB]"
                                                                }
                                                                textColors={
                                                                  "text-black"
                                                                }
                                                                boderColors={
                                                                  "border-[#489bbd]"
                                                                }
                                                              />
                                                            </span>
                                                            <span
                                                              className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                              onClick={() => {
                                                                toggleRowVisibility(
                                                                  elementtemp.selectionId
                                                                );
                                                                handleBackOpen(
                                                                  {
                                                                    data: tempData,
                                                                    type: "Yes",
                                                                    odds: tempData.price,
                                                                    name: elementtemp.selectionName,
                                                                    nameOther:
                                                                      element.runners,
                                                                    betFor:
                                                                      "matchOdds",
                                                                    oddsType:
                                                                      element.marketType,
                                                                    betType:
                                                                      "L",
                                                                    selectionId:
                                                                      elementtemp.selectionId,
                                                                    teamData:
                                                                      tempData.price,
                                                                    betfairMarketId:
                                                                      element.marketId,
                                                                    price:
                                                                      elementtemp
                                                                        .ex
                                                                        .availableToLay[0]
                                                                        .price,
                                                                    size: elementtemp
                                                                      .ex
                                                                      .availableToLay[0]
                                                                      .size,
                                                                    position:
                                                                      returnDataObject,
                                                                    newPosition:
                                                                      returnDataObject,
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <Blinking
                                                                price={
                                                                  tempData.price
                                                                }
                                                                size={
                                                                  tempData.size
                                                                }
                                                                color={
                                                                  "bg-[#72BBEF]"
                                                                }
                                                                blinkColor={
                                                                  "bg-[#CDEBEB]"
                                                                }
                                                                textColors={
                                                                  "text-black"
                                                                }
                                                                boderColors={
                                                                  "border-[#489bbd]"
                                                                }
                                                              />
                                                            </span>
                                                          </>
                                                        )
                                                      )}
                                                  </>
                                                ) : null}

                                                {elementtemp &&
                                                  elementtemp.ex &&
                                                  elementtemp.ex
                                                    .availableToLay &&
                                                  elementtemp.ex
                                                    .availableToLay.length > 0
                                                  ? elementtemp.ex.availableToLay.map(
                                                    (tempData, index) => (
                                                      <>
                                                        {index === 0 ? (
                                                          <>
                                                            <span
                                                              className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                              onClick={() => {
                                                                toggleRowVisibility(
                                                                  elementtemp.selectionId
                                                                );
                                                                handleBackOpen(
                                                                  {
                                                                    data: tempData,
                                                                    type: "No",
                                                                    odds: tempData.price,
                                                                    name: elementtemp.selectionName,
                                                                    nameOther:
                                                                      element.runners,
                                                                    betFor:
                                                                      "matchOdds",
                                                                    oddsType:
                                                                      element.marketType,
                                                                    betType:
                                                                      "K",
                                                                    selectionId:
                                                                      elementtemp.selectionId,
                                                                    teamData:
                                                                      tempData.price,
                                                                    betfairMarketId:
                                                                      element.marketId,
                                                                    price:
                                                                      elementtemp
                                                                        .ex
                                                                        .availableToBack[0]
                                                                        .price,
                                                                    size: elementtemp
                                                                      .ex
                                                                      .availableToBack[0]
                                                                      .size,
                                                                    position:
                                                                      returnDataObject,
                                                                    newPosition:
                                                                      returnDataObject,
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <Blinking
                                                                price={
                                                                  tempData.price
                                                                }
                                                                size={
                                                                  tempData.size
                                                                }
                                                                color={
                                                                  "bg-[#FAA9BA]"
                                                                }
                                                                blinkColor={
                                                                  "bg-[#CDEBEB]"
                                                                }
                                                                textColors={
                                                                  "text-black"
                                                                }
                                                                boderColors={
                                                                  "border-[#f996ab]"
                                                                }
                                                              />
                                                            </span>

                                                            <span
                                                              className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                              onClick={() => {
                                                                toggleRowVisibility(
                                                                  elementtemp.selectionId
                                                                );
                                                                handleBackOpen(
                                                                  {
                                                                    data: tempData,
                                                                    type: "No",
                                                                    odds: tempData.price,
                                                                    name: elementtemp.selectionName,
                                                                    nameOther:
                                                                      element.runners,
                                                                    betFor:
                                                                      "matchOdds",
                                                                    oddsType:
                                                                      element.marketType,
                                                                    betType:
                                                                      "K",
                                                                    selectionId:
                                                                      elementtemp.selectionId,
                                                                    teamData:
                                                                      tempData.price,
                                                                    betfairMarketId:
                                                                      element.marketId,
                                                                    price:
                                                                      elementtemp
                                                                        .ex
                                                                        .availableToBack[0]
                                                                        .price,
                                                                    size: elementtemp
                                                                      .ex
                                                                      .availableToBack[0]
                                                                      .size,
                                                                    position:
                                                                      returnDataObject,
                                                                    newPosition:
                                                                      returnDataObject,
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <Blinking
                                                                price={
                                                                  tempData.price
                                                                }
                                                                size={
                                                                  tempData.size
                                                                }
                                                                color={
                                                                  "bg-[#FAA9BA]"
                                                                }
                                                                blinkColor={
                                                                  "bg-[#CDEBEB]"
                                                                }
                                                                textColors={
                                                                  "text-black"
                                                                }
                                                                boderColors={
                                                                  "border-[#f996ab]"
                                                                }
                                                              />
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                              <Blinking
                                                                price={
                                                                  tempData.price
                                                                }
                                                                size={
                                                                  tempData.size
                                                                }
                                                                color={
                                                                  "bg-[#EFD3D9]"
                                                                }
                                                                blinkColor={
                                                                  "bg-[#CDEBEB]"
                                                                }
                                                                textColors={
                                                                  "text-black"
                                                                }
                                                                boderColors={
                                                                  ""
                                                                }
                                                              />
                                                            </span>
                                                          </>
                                                        )}
                                                      </>
                                                    )
                                                  )
                                                  : null}
                                              </div>
                                            </div>

                                            {hiddenRows.includes(
                                              elementtemp.selectionId
                                            ) && (
                                                <BetPlaceMobile
                                                  setStakeModal={
                                                    setStakeModal
                                                  }
                                                  closeRow={closeRow}
                                                  closeSec={
                                                    elementtemp.selectionId
                                                  }
                                                  matchName={
                                                    inplayMatch?.matchName
                                                  }
                                                  openBets={openBets}
                                                  betSlipData={betSlipData}
                                                  placeBet={placeBet}
                                                  count={betSlipData.count}
                                                  betLoading={betLoading}
                                                  setBetSlipData={setBetSlipData}
                                                  increaseCount={
                                                    increaseCount
                                                  }
                                                  decreaseCount={
                                                    decreaseCount
                                                  }
                                                />
                                              )}
                                          </>
                                        )
                                      )
                                      : null}
                                  </>
                                )}
                              </div>
                            </div>
                          )
                      )}
                    </>



                    {inplayMatch?.isBookmaker &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {isBookmekar && (
                          <>
                            <div>
                              {matchScoreDetails &&
                                matchScoreDetails.team_data &&
                                matchScoreDetails.team_data.length > 0 ? (
                                <>
                                  <div>
                                    <div className="rounded-md divide-y divide-gray-400">
                                      <header className={`bg-${primaryColor} z-[1] relative`}>
                                        <div className=" items-center flex relative z-0 ">
                                          <div className=" flex flex-1 justify-start items-center px-1">
                                            <span className="border border-white rounded">
                                              <TbPinnedFilled size={15} className="text-white" />
                                            </span>
                                            <div className=" rounded-tl-md py-1.5 text-nowrap capitalize px-2 items-center inline-flex text-sm font-semibold text-white">
                                              BookMaker &nbsp;
                                              <span className='flex justify-center items-center gap-1 text-[12px]'>
                                                <MdOutlineTimer size={15} />
                                                {inplayMatch?.betDelayTime} Sec
                                              </span>
                                            </div>
                                          </div>

                                          <div className='text-white font-semibold text-xs'>
                                            Min: 100 |
                                            Max: {minMaxCoins?.max}
                                          </div>

                                          {/* <span
                                        className="text-[10px] rounded font-semibold mx-1 px-2 py-0.5 cursor-pointer border border-red-600 text-red-600"
                                        onClick={() =>
                                          setIsBookmaker(false)
                                        }
                                      >
                                        &#10005;
                                      </span> */}

                                          <span
                                            className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm cursor-pointer flex justify-center items-center"
                                            onClick={() =>
                                              setIsBookmakerHide(
                                                !isBookmekarHide
                                              )
                                            }
                                          >
                                            {isBookmekarHide ? (
                                              <FaMinus />
                                            ) : (
                                              <FaPlus />
                                            )}
                                          </span>



                                          {/* <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center relative">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">


                                          <span className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                            onClick={() => togglePopover("bookmakerStake")}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          

                                        </div>
                                        <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.bookmakerStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{minMaxCoins?.min} - {minMaxCoins?.max}</span></p>
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {minMaxCoins?.max}</span></p>
                                        </div>

                                        )}
                                        </div>
                                      </div> */}
                                        </div>
                                        <div className=" items-center flex relative bg-white z-[-1] w-full">
                                          <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex ">
                                            <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-black">
                                              {/* {matchScoreDetails.team_data.length > 0
                                            ? matchScoreDetails.team_data.length
                                            : null}{" "}
                                          Selection */}
                                            </div>
                                          </div>

                                          <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                            <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                              <span className="lg:block hidden"></span>
                                              <span className="lg:block hidden"></span>
                                              <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                                Back
                                              </span>

                                              <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                                Lay
                                              </span>
                                              <span className="lg:block hidden"></span>
                                              <span className="lg:block hidden"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </header>

                                      {isBookmekarHide && (
                                        <>
                                          {matchScoreDetails &&
                                            matchScoreDetails.team_data &&
                                            matchScoreDetails.team_data.length > 0
                                            ? matchScoreDetails.team_data.map(
                                              (commList, index) => (
                                                <>
                                                  <div
                                                    key={index}
                                                    className={` bg-[#fffff] relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11 `}
                                                  >
                                                    <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex px-2">
                                                      <div className="w-full leading-3 flex items-center text-slate-700">
                                                        <span class="text-xs font-bold">
                                                          <span className="text-black">
                                                            {commList.team_name}
                                                          </span>
                                                          <br />
                                                          <span
                                                            key={index}
                                                            className={
                                                              positionObj[
                                                                commList
                                                                  ?.selectionid
                                                              ] > 0
                                                                ? "text-green-600"
                                                                : positionObj[
                                                                  commList
                                                                    ?.selectionid
                                                                ] < 0
                                                                  ? "text-red-600"
                                                                  : "text-green-500"
                                                            }
                                                          >


                                                            {/* {betSlipData?.position && typeof betSlipData.position === 'object' &&  commList?.selectionId && betSlipData.position[commList.selectionid] ?
                                                              <span className={`${betSlipData.position[commList.selectionid] > 0 ? 'text-green-700' : 'text-red-700'}`}>
                                                                {betSlipData.position[commList.selectionid]}
                                                              </span>


                                                              : 0} */}

                                                            {/* {betSlipData?.position[commList?.selectionid] ? betSlipData?.position[commList?.selectionid] : ''
                                                            } */}
                                                            {/* {console.log(betSlipData?.position[betSlipData?.selectionid], "commListcommList")
                                                            } */}
                                                            {/* {betSlipData?.position[betSlipData?.selectionid]} */}
                                                            {/* {returnDataObject[commList.selectionid] !== 0 ? returnDataObject[commList.selectionid] : "-"} */}
                                                            {positionObj[commList
                                                              ?.selectionid
                                                            ]
                                                              ? (
                                                                Math.floor(
                                                                  Number(
                                                                    positionObj[
                                                                    commList
                                                                      ?.selectionid
                                                                    ]
                                                                  ) * 100
                                                                ) / 100
                                                              ).toFixed(2)
                                                              : ''}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <>
                                                      <div className="w-1/2 lg:w-[75%] xl:w-1/2 grid grid-cols-6 gap-1 py-0.5">
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            // color={"bg-[#98C8EA]"}
                                                            // blinkColor={"bg-[#CDEBEB]"}
                                                            color={"bg-[#D7E8F4]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              ""
                                                            }
                                                          />
                                                        </span>
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            color={"bg-[#94CEF7]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-black"
                                                            }
                                                          />
                                                        </span>
                                                        <span
                                                          className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              nameOther:
                                                                matchScoreDetails.team_data,
                                                              type: "Yes",
                                                              odds: commList.lgaai,
                                                              name: commList.team_name,
                                                              betFor: "odds",
                                                              oddsType:
                                                                "bookmaker",
                                                              betType: "L",
                                                              selectionId:
                                                                commList.selectionid,
                                                              teamData:
                                                                commList.lgaai,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.khaai *
                                                                100,
                                                              size: "0",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                            });
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.lgaai * 100).toFixed(2)}
                                                            size={(commList.khaai * 100).toFixed(2)}
                                                            color={"bg-[#72BBEF]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#489bbd]"
                                                            }
                                                          />
                                                        </span>

                                                        <span
                                                          className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              nameOther:
                                                                matchScoreDetails.team_data,
                                                              type: "Yes",
                                                              odds: commList.lgaai,
                                                              name: commList.team_name,
                                                              betFor: "odds",
                                                              oddsType:
                                                                "bookmaker",
                                                              betType: "L",
                                                              selectionId:
                                                                commList.selectionid,
                                                              teamData:
                                                                commList.lgaai,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.khaai *
                                                                100,
                                                              size: "0",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                            });
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.lgaai * 100).toFixed(2)}
                                                            size={(commList.khaai * 100).toFixed(2)}
                                                            color={"bg-[#72BBEF]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#489bbd]"
                                                            }
                                                          />
                                                        </span>

                                                        <span
                                                          className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden"
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              nameOther:
                                                                matchScoreDetails.team_data,
                                                              type: "No",
                                                              odds: commList.khaai,
                                                              name: commList.team_name,
                                                              betFor: "odds",
                                                              oddsType:
                                                                "bookmaker",
                                                              betType: "K",
                                                              selectionId:
                                                                commList.selectionid,
                                                              teamData:
                                                                commList.khaai,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.lgaai *
                                                                100,
                                                              size: "0",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                            });
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.khaai * 100).toFixed(2)}
                                                            size={(commList.lgaai * 100).toFixed(2)}
                                                            color={"bg-[#FAA9BA]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#f996ab]"
                                                            }
                                                          />
                                                        </span>
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            color={"bg-[#EFD3D9]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-black"
                                                            }
                                                          />
                                                        </span>
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            color={"bg-[#EFD3D9]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-black"
                                                            }
                                                          />
                                                        </span>

                                                        <span
                                                          className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block"
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              nameOther:
                                                                matchScoreDetails.team_data,
                                                              type: "No",
                                                              odds: commList.khaai,
                                                              name: commList.team_name,
                                                              betFor: "odds",
                                                              oddsType:
                                                                "bookmaker",
                                                              betType: "K",
                                                              selectionId:
                                                                commList.selectionid,
                                                              teamData:
                                                                commList.khaai,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.lgaai *
                                                                100,
                                                              size: "0",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                            });
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.khaai * 100).toFixed(2)}
                                                            size={(commList.lgaai * 100).toFixed(2)}
                                                            color={"bg-[#FAA9BA]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#f996ab]"
                                                            }
                                                          />
                                                        </span>
                                                        <span className=" lg:block hidden"></span>
                                                      </div>
                                                      {commList.lgaai ===
                                                        "0.00" ? (
                                                        <div className="w-1/2 border-[#E4E4E4] right-0 h-full  absolute bg-[#E4E4E4] opacity-90 border-2 rounded px-1   flex justify-center items-center">
                                                          {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                                          <div className="2xl:px-8 lg:px-8 px-2 text-nowrap text-sm font-bold opacity-100 text-gray-900 ">
                                                            SUSPENDED
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </>
                                                  </div>
                                                  {index <
                                                    matchScoreDetails.team_data
                                                      .length -
                                                    1 && <hr />}
                                                  <>
                                                    {hiddenRows.includes(
                                                      commList.selectionid
                                                    ) && (
                                                        <BetPlaceMobile
                                                          setStakeModal={
                                                            setStakeModal
                                                          }
                                                          closeRow={closeRow}
                                                          closeSec={
                                                            commList.selectionid
                                                          }
                                                          matchName={
                                                            inplayMatch?.matchName
                                                          }
                                                          openBets={openBets}
                                                          betSlipData={betSlipData}
                                                          placeBet={placeBet}
                                                          count={betSlipData.count}
                                                          betLoading={betLoading}
                                                          setBetSlipData={setBetSlipData}
                                                          increaseCount={
                                                            increaseCount
                                                          }
                                                          decreaseCount={
                                                            decreaseCount
                                                          }
                                                        />
                                                      )}
                                                  </>
                                                </>
                                              )
                                            )
                                            : null}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>{" "}
                          </>
                        )}
                      </>
                    ) : null}


                    {inplayMatch?.isTieOdds &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {isTieMatch && (
                          <>
                            {Object.values(finalSocket).map(
                              (elementMatchOdds, index) =>
                                elementMatchOdds.marketType === "Match Odds" &&
                                (elementMatchOdds &&
                                  elementMatchOdds.runners &&
                                  elementMatchOdds.runners.length <= 2 ? (
                                  <>
                                    {Object.values(finalSocket).map(
                                      (element, index) =>
                                        element.marketType === "Tied Match" && (
                                          <div>
                                            <div className="rounded-md divide-y">
                                              <header className={`bg-${primaryColor} z-[1] relative`}>
                                                <div className=" items-center flex relative z-0">
                                                  <div className="flex flex-1 justify-start items-center px-1">
                                                    <span className="border border-white rounded">
                                                      <TbPinnedFilled size={15} className="text-white" />
                                                    </span>
                                                    <span className="items-center py-1 px-2 rounded-tl-md  inline-flex text-sm font-semibold text-white ">
                                                      TIED_MATCH &nbsp;
                                                      <span className='flex justify-center items-center gap-1 '>
                                                        <MdOutlineTimer />
                                                        {element?.betDelayTime}
                                                      </span>
                                                      {/* {element &&
                                                        element.marketType
                                                        ? element.marketType
                                                        : "NA"} */}
                                                    </span>
                                                  </div>

                                                  <div className="px-1 flex">

                                                    <div className='text-white font-semibold text-xs'>
                                                      Min: 100 |
                                                      Max: {isTieCoin?.max}
                                                    </div>
                                                    <span
                                                      className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                      onClick={() =>
                                                        setIsTieHide(
                                                          !isTieHide
                                                        )
                                                      }
                                                    >
                                                      {isTieHide ? (
                                                        <FaMinus />
                                                      ) : (
                                                        <FaPlus />
                                                      )}
                                                    </span>

                                                    {/* <span
                                                      className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                                      onClick={() =>
                                                        setIsTieMatch(false)
                                                      }
                                                    >
                                                      &#10005;
                                                    </span> */}
                                                  </div>

                                                  {/* <div className="relative bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center">

                                                    <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                                      <span
                                                        className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                                        onClick={openRuleModal}
                                                      >
                                                        Rules
                                                      </span>
                                                      <span className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                                        onClick={() => togglePopover('tieStake')}

                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="2"
                                                          height="8"
                                                          viewBox="0 0 2 8"
                                                          fill="none"
                                                        >
                                                          <path
                                                            _ngcontent-lmb-c38=""
                                                            d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                            fill="white"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                      <span
                                                        className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                                        onClick={() =>
                                                          setIsTieMatch(false)
                                                        }
                                                      >
                                                        &#10005;
                                                      </span>
                                                    </div>
                                                    
                                                    <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.tieStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                                      <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{isTieCoin?.min} - {isTieCoin?.max} </span></p>
                                                      <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {isTieCoin?.max}</span></p>
                                                    </div>

                                                    )}
                                                    </div>
                                                  </div> */}
                                                </div>
                                                <div className=" items-center flex relative bg-white z-[-1]">
                                                  <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex">
                                                    <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                                      {/* {element.runners.length >
                                                        0
                                                        ? element.runners.length
                                                        : null}{" "}
                                                      Selection */}
                                                    </div>
                                                  </div>

                                                  <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                                    <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                                      <span className="lg:block hidden"></span>
                                                      <span className="lg:block hidden"></span>
                                                      <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                                        Back
                                                      </span>

                                                      <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                                        Lay
                                                      </span>
                                                      <span className="lg:block hidden"></span>
                                                      <span className="lg:block hidden"></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </header>
                                              {isTieHide && (
                                                <>
                                                  {element &&
                                                    element.runners &&
                                                    element.runners.length > 0
                                                    ? element.runners.map(
                                                      (
                                                        elementtemp,
                                                        index
                                                      ) => (
                                                        <>
                                                          <div
                                                            className={` bg-[#fffff] shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                                          >
                                                            <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex px-2">
                                                              <div className="w-11/12  py-1 leading-3 flex items-center text-slate-700">
                                                                <span class="text-sm font-bold">
                                                                  <span className="text-black font-bold text-xs">
                                                                    {
                                                                      elementtemp.selectionName
                                                                    }{" "}
                                                                    <br />
                                                                    <span
                                                                      key={
                                                                        index
                                                                      }
                                                                      className={
                                                                        positionObj[
                                                                          elementtemp
                                                                            ?.selectionId
                                                                        ] > 0
                                                                          ? "text-green-500"
                                                                          : positionObj[
                                                                            elementtemp
                                                                              ?.selectionId
                                                                          ] <
                                                                            0
                                                                            ? "text-red-500"
                                                                            : "text-green-500"
                                                                      }
                                                                    >
                                                                      {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}
                                                                      {positionObj[
                                                                        elementtemp
                                                                          ?.selectionId
                                                                      ]
                                                                        ? (
                                                                          Math.floor(
                                                                            Number(
                                                                              positionObj[
                                                                              elementtemp
                                                                                ?.selectionId
                                                                              ]
                                                                            ) *
                                                                            100
                                                                          ) /
                                                                          100
                                                                        ).toFixed(
                                                                          2
                                                                        )
                                                                        : 0}
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="w-1/2 lg:w-[75%] xl:w-1/2 grid grid-cols-6 gap-1 ">
                                                              {elementtemp &&
                                                                elementtemp.ex &&
                                                                elementtemp.ex
                                                                  .availableToBack &&
                                                                elementtemp.ex
                                                                  .availableToBack
                                                                  .length >
                                                                0 ? (
                                                                <>
                                                                  {elementtemp.ex.availableToBack
                                                                    .slice(1)
                                                                    .map(
                                                                      (
                                                                        tempData,
                                                                        index
                                                                      ) => (
                                                                        <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                                          <Blinking
                                                                            price={
                                                                              tempData.price
                                                                            }
                                                                            size={
                                                                              tempData.size
                                                                            }
                                                                            color={
                                                                              "bg-[#D7E8F4]"
                                                                            }
                                                                            blinkColor={
                                                                              "bg-[#CDEBEB]"
                                                                            }
                                                                            textColors={
                                                                              "text-black"
                                                                            }
                                                                            boderColors={
                                                                              ""
                                                                            }
                                                                          />
                                                                        </span>
                                                                      )
                                                                    )}
                                                                </>
                                                              ) : null}

                                                              {elementtemp &&
                                                                elementtemp.ex &&
                                                                elementtemp.ex
                                                                  .availableToBack &&
                                                                elementtemp.ex
                                                                  .availableToBack
                                                                  .length >
                                                                0 ? (
                                                                <>
                                                                  {elementtemp.ex.availableToBack
                                                                    .slice(
                                                                      0,
                                                                      1
                                                                    )
                                                                    .map(
                                                                      (
                                                                        tempData,
                                                                        index
                                                                      ) => (
                                                                        <>
                                                                          <span
                                                                            className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );
                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "Yes",
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "L",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToLay[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToLay[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#72BBEF]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#489bbd]"
                                                                              }
                                                                            />
                                                                          </span>
                                                                          <span
                                                                            className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );
                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "Yes",
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "L",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToLay[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToLay[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#72BBEF]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#489bbd]"
                                                                              }
                                                                            />
                                                                          </span>
                                                                        </>
                                                                      )
                                                                    )}
                                                                </>
                                                              ) : null}

                                                              {elementtemp &&
                                                                elementtemp.ex &&
                                                                elementtemp.ex
                                                                  .availableToLay &&
                                                                elementtemp.ex
                                                                  .availableToLay
                                                                  .length > 0
                                                                ? elementtemp.ex.availableToLay.map(
                                                                  (
                                                                    tempData,
                                                                    index
                                                                  ) => (
                                                                    <>
                                                                      {index ===
                                                                        0 ? (
                                                                        <>
                                                                          <span
                                                                            className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );

                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "No",
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "K",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToBack[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToBack[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#FAA9BA]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#f996ab]"
                                                                              }
                                                                            />
                                                                          </span>

                                                                          <span
                                                                            className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                            onClick={() => {
                                                                              toggleRowVisibility(
                                                                                elementtemp.selectionId
                                                                              );
                                                                              handleBackOpen(
                                                                                {
                                                                                  data: tempData,
                                                                                  type: "No",
                                                                                  odds: tempData.price,
                                                                                  name: elementtemp.selectionName,
                                                                                  nameOther:
                                                                                    element.runners,
                                                                                  betFor:
                                                                                    "tiedMatch",
                                                                                  oddsType:
                                                                                    element.marketType,
                                                                                  betType:
                                                                                    "K",
                                                                                  selectionId:
                                                                                    elementtemp.selectionId,
                                                                                  teamData:
                                                                                    tempData.price,
                                                                                  betfairMarketId:
                                                                                    element.marketId,
                                                                                  price:
                                                                                    elementtemp
                                                                                      .ex
                                                                                      .availableToBack[0]
                                                                                      .price,
                                                                                  size: elementtemp
                                                                                    .ex
                                                                                    .availableToBack[0]
                                                                                    .size,
                                                                                  position:
                                                                                    returnDataObject,
                                                                                  newPosition:
                                                                                    returnDataObject,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#FAA9BA]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                "border-[#f996ab]"
                                                                              }
                                                                            />
                                                                          </span>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                                            <Blinking
                                                                              price={
                                                                                tempData.price
                                                                              }
                                                                              size={
                                                                                tempData.size
                                                                              }
                                                                              color={
                                                                                "bg-[#FBD7E5]"
                                                                              }
                                                                              blinkColor={
                                                                                "bg-[#CDEBEB]"
                                                                              }
                                                                              textColors={
                                                                                "text-black"
                                                                              }
                                                                              boderColors={
                                                                                " "
                                                                              }
                                                                            />
                                                                          </span>
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )
                                                                )
                                                                : null}
                                                            </div>
                                                          </div>

                                                          {hiddenRows.includes(
                                                            elementtemp.selectionId
                                                          ) && (
                                                              <BetPlaceMobile
                                                                setStakeModal={
                                                                  setStakeModal
                                                                }
                                                                closeRow={
                                                                  closeRow
                                                                }
                                                                closeSec={
                                                                  elementtemp.selectionId
                                                                }
                                                                matchName={
                                                                  inplayMatch?.matchName
                                                                }
                                                                openBets={
                                                                  openBets
                                                                }
                                                                betSlipData={
                                                                  betSlipData
                                                                }
                                                                placeBet={
                                                                  placeBet
                                                                }
                                                                count={
                                                                  betSlipData.count
                                                                }
                                                                betLoading={
                                                                  betLoading
                                                                }
                                                                increaseCount={
                                                                  increaseCount
                                                                }
                                                                decreaseCount={
                                                                  decreaseCount
                                                                }
                                                                setBetSlipData={setBetSlipData}
                                                              />
                                                            )}
                                                        </>
                                                      )
                                                    )
                                                    : null}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </>
                                ) : null)
                            )}
                          </>
                        )}{" "}
                      </>
                    ) : null}



                    {inplayMatch?.isToss &&
                      (activeTab === "matchOdds" || activeTab === "all") ? (
                      <>
                        {isToss && (
                          <>
                            <div>
                              {matchScoreDetails &&
                                matchScoreDetails.toss_data &&
                                matchScoreDetails.toss_data.length > 0 ? (
                                <>
                                  <div>
                                    <div className="rounded-md divide-y divide-gray-400">
                                      <header className={`bg-${primaryColor} z-[1] relative`}>
                                        <div className=" items-center flex relative z-0 ">
                                          <div className=" flex flex-1 justify-start items-center px-1">
                                            <span className="border border-white rounded">
                                              <TbPinnedFilled size={15} className="text-white" />
                                            </span>
                                            <div className=" rounded-tl-md py-1.5 text-nowrap capitalize px-2 items-center inline-flex text-sm font-semibold text-white">
                                              To Win The Toss

                                            </div>
                                          </div>

                                          <div className='text-white font-semibold text-xs'>
                                            Min: 100 |
                                            Max: {minMaxCoins?.max}
                                          </div>

                                          {/* <span
                                        className="text-[10px] rounded font-semibold mx-1 px-2 py-0.5 cursor-pointer border border-red-600 text-red-600"
                                        onClick={() =>
                                          setIsBookmaker(false)
                                        }
                                      >
                                        &#10005;
                                      </span> */}

                                          <span
                                            className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm cursor-pointer flex justify-center items-center"
                                            onClick={() =>
                                              setIsBookmakerHide(
                                                !isBookmekarHide
                                              )
                                            }
                                          >
                                            {isBookmekarHide ? (
                                              <FaMinus />
                                            ) : (
                                              <FaPlus />
                                            )}
                                          </span>



                                          {/* <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center relative">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">


                                          <span className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                            onClick={() => togglePopover("bookmakerStake")}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          

                                        </div>
                                        <div className="absolute top-0 left-[-115px] z-[10000]">{isPopoverVisible.bookmakerStake && (<div className=" bg-white w-[169px] px-3 py-2 rounded-[3px] h-auto ">
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Stake Limit : <span className=" text-[11px] font-medium">{minMaxCoins?.min} - {minMaxCoins?.max}</span></p>
                                          <p className="text-[12px] font-semibold text-detailModal font-signika">Max Profit : <span className=" text-[11px] font-medium"> {minMaxCoins?.max}</span></p>
                                        </div>

                                        )}
                                        </div>
                                      </div> */}
                                        </div>
                                        <div className=" items-center flex relative bg-white z-[-1] w-full">
                                          <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex ">
                                            <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-black">

                                            </div>
                                          </div>

                                          <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                            <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                              <span className="lg:block hidden"></span>
                                              <span className="lg:block hidden"></span>
                                              <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                                Back
                                              </span>

                                              <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                                Lay
                                              </span>
                                              <span className="lg:block hidden"></span>
                                              <span className="lg:block hidden"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </header>

                                      {isTossHide && (
                                        <>
                                          {matchScoreDetails &&
                                            matchScoreDetails.toss_data &&
                                            matchScoreDetails.toss_data.length > 0
                                            ? matchScoreDetails.toss_data.map(
                                              (commList, index) => (
                                                <>
                                                  <div
                                                    key={index}
                                                    className={` bg-[#fffff] relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11 `}
                                                  >
                                                    <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex px-2">
                                                      <div className="w-full leading-3 flex items-center text-slate-700">
                                                        <span class="text-xs font-bold">
                                                          <span className="text-black">
                                                            {commList.team_name}
                                                          </span>
                                                          <br />
                                                          <span
                                                            key={index}
                                                            className={
                                                              positionObj[
                                                                commList
                                                                  ?.selectionid
                                                              ] > 0
                                                                ? "text-green-600"
                                                                : positionObj[
                                                                  commList
                                                                    ?.selectionid
                                                                ] < 0
                                                                  ? "text-red-600"
                                                                  : "text-green-500"
                                                            }
                                                          >


                                                            {/* {betSlipData?.position && typeof betSlipData.position === 'object' &&  commList?.selectionId && betSlipData.position[commList.selectionid] ?
                                                              <span className={`${betSlipData.position[commList.selectionid] > 0 ? 'text-green-700' : 'text-red-700'}`}>
                                                                {betSlipData.position[commList.selectionid]}
                                                              </span>


                                                              : 0} */}

                                                            {/* {betSlipData?.position[commList?.selectionid] ? betSlipData?.position[commList?.selectionid] : ''
                                                            } */}
                                                            {/* {console.log(betSlipData?.position[betSlipData?.selectionid], "commListcommList")
                                                            } */}
                                                            {/* {betSlipData?.position[betSlipData?.selectionid]} */}
                                                            {/* {returnDataObject[commList.selectionid] !== 0 ? returnDataObject[commList.selectionid] : "-"} */}
                                                            {positionObj[commList
                                                              ?.selectionid
                                                            ]
                                                              ? (
                                                                Math.floor(
                                                                  Number(
                                                                    positionObj[
                                                                    commList
                                                                      ?.selectionid
                                                                    ]
                                                                  ) * 100
                                                                ) / 100
                                                              ).toFixed(2)
                                                              : ''}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <>
                                                      <div className="w-1/2 lg:w-[75%] xl:w-1/2 grid grid-cols-6 gap-1 py-0.5">
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            // color={"bg-[#98C8EA]"}
                                                            // blinkColor={"bg-[#CDEBEB]"}
                                                            color={"bg-[#D7E8F4]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              ""
                                                            }
                                                          />
                                                        </span>
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            color={"bg-[#94CEF7]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-black"
                                                            }
                                                          />
                                                        </span>
                                                        <span
                                                          className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              type: "Yes",
                                                              odds: commList.lgaai,
                                                              name: commList.team_name,
                                                              betFor: "toss",
                                                              oddsType: "toss",
                                                              betType: "L",
                                                              selectionId:
                                                                commList.selectionid,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.khaai *
                                                                100,
                                                              size: "100",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                              nameOther:
                                                                matchScoreDetails.toss_data,
                                                            });
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.lgaai * 100).toFixed(2)}
                                                            size={(commList.khaai * 100).toFixed(2)}
                                                            color={"bg-[#72BBEF]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#489bbd]"
                                                            }
                                                          />
                                                        </span>

                                                        <span
                                                          className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "

                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              type: "Yes",
                                                              odds: commList.lgaai,
                                                              name: commList.team_name,
                                                              betFor: "toss",
                                                              oddsType: "toss",
                                                              betType: "L",
                                                              selectionId:
                                                                commList.selectionid,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.khaai *
                                                                100,
                                                              size: "100",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                              nameOther:
                                                                matchScoreDetails.toss_data,
                                                            });
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.lgaai * 100).toFixed(2)}
                                                            size={(commList.khaai * 100).toFixed(2)}
                                                            color={"bg-[#72BBEF]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#489bbd]"
                                                            }
                                                          />
                                                        </span>

                                                        <span
                                                          className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden"
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              type: "No",
                                                              odds: commList.khaai,
                                                              name: commList.team_name,
                                                              betFor: "toss",
                                                              oddsType: "toss",
                                                              betType: "K",
                                                              selectionId:
                                                                commList.selectionid,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.lgaai *
                                                                100,
                                                              size: "100",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                              nameOther:
                                                                matchScoreDetails.toss_data,
                                                            })
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.khaai * 100).toFixed(2)}
                                                            size={(commList.lgaai * 100).toFixed(2)}
                                                            color={"bg-[#FAA9BA]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#f996ab]"
                                                            }
                                                          />
                                                        </span>
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            color={"bg-[#EFD3D9]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-black"
                                                            }
                                                          />
                                                        </span>
                                                        <span className="lg:block hidden ">
                                                          <Blinking
                                                            price={0}
                                                            size={0}
                                                            color={"bg-[#EFD3D9]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-black"
                                                            }
                                                          />
                                                        </span>

                                                        <span
                                                          className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block"
                                                          onClick={() => {
                                                            toggleRowVisibility(
                                                              commList.selectionid
                                                            );
                                                            handleBackOpen({
                                                              data: commList,
                                                              type: "No",
                                                              odds: commList.khaai,
                                                              name: commList.team_name,
                                                              betFor: "toss",
                                                              oddsType: "toss",
                                                              betType: "K",
                                                              selectionId:
                                                                commList.selectionid,
                                                              betfairMarketId:
                                                                marketId,
                                                              price:
                                                                commList.lgaai *
                                                                100,
                                                              size: "100",
                                                              position:
                                                                returnDataObject,
                                                              newPosition:
                                                                returnDataObject,
                                                              nameOther:
                                                                matchScoreDetails.toss_data,
                                                            })
                                                          }}
                                                        >
                                                          <Blinking
                                                            price={(commList.khaai * 100).toFixed(2)}
                                                            size={(commList.lgaai * 100).toFixed(2)}
                                                            color={"bg-[#FAA9BA]"}
                                                            blinkColor={
                                                              "bg-[#CDEBEB]"
                                                            }
                                                            textColors={
                                                              "text-black"
                                                            }
                                                            boderColors={
                                                              "border-[#f996ab]"
                                                            }
                                                          />
                                                        </span>
                                                        <span className=" lg:block hidden"></span>
                                                      </div>
                                                      {commList.lgaai ===
                                                        "0.00" ? (
                                                        <div className="w-1/2 border-[#E4E4E4] right-0 h-full  absolute bg-[#E4E4E4] opacity-90 border-2 rounded px-1   flex justify-center items-center">
                                                          {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                                          <div className="2xl:px-8 lg:px-8 px-2 text-nowrap text-sm font-bold opacity-100 text-gray-900 ">
                                                            SUSPENDED
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </>
                                                  </div>
                                                  {index <
                                                    matchScoreDetails.toss_data
                                                      .length -
                                                    1 && <hr />}
                                                  <>
                                                    {hiddenRows.includes(
                                                      commList.selectionid
                                                    ) && (
                                                        <BetPlaceMobile
                                                          setStakeModal={
                                                            setStakeModal
                                                          }
                                                          closeRow={closeRow}
                                                          closeSec={
                                                            commList.selectionid
                                                          }
                                                          matchName={
                                                            inplayMatch?.matchName
                                                          }
                                                          openBets={openBets}
                                                          betSlipData={betSlipData}
                                                          placeBet={placeBet}
                                                          count={betSlipData.count}
                                                          betLoading={betLoading}
                                                          setBetSlipData={setBetSlipData}
                                                          increaseCount={
                                                            increaseCount
                                                          }
                                                          decreaseCount={
                                                            decreaseCount
                                                          }
                                                        />
                                                      )}
                                                  </>
                                                </>
                                              )
                                            )
                                            : null}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>{" "}
                          </>
                        )}
                      </>
                    ) : null}






                    {inplayMatch?.isFancy &&
                      isFancy &&
                      (activeTab === "Fancy" || activeTab === "all") && (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails?.session &&
                            matchScoreDetails?.session?.length > 0 ? (
                            <>
                              {matchScoreDetails.session.filter(
                                (commList) => commList.com_perm == "YES"
                              ).length > 0 && (
                                  <header className={`bg-${primaryColor} z-[1] relative`}>
                                    <div className=" items-center flex relative z-0">
                                      <div className=" flex flex-1 justify-start items-center px-1">
                                        <span className="border border-white rounded">
                                          <TbPinnedFilled size={15} className="text-white" />
                                        </span>
                                        <div className=" rounded-tl-md py-1.5 capitalize px-2 items-center inline-flex text-sm font-semibold text-white">
                                          Fancy Bets
                                        </div>
                                        <div className="w-1/2 px-2 flex items-center">
                                          {/* <img src={fastimg} alt="textd" className="h-5 w-5" /> */}
                                        </div>
                                      </div>

                                      <div className='text-white font-semibold text-xs mx-1'>
                                        Min: 100 |
                                        Max: {minMaxCoins?.max}
                                      </div>

                                      {/* <span
                                        className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                        onClick={() =>
                                          setIsFancy(false)
                                        }
                                      >
                                        &#10005;
                                      </span> */}

                                      {/* <span
                                        className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                        onClick={() =>
                                          setIsFancyHide(
                                            !isFancyHide
                                          )
                                        }
                                      >
                                        {isFancyHide ? (
                                          <FaMinus />
                                        ) : (
                                          <FaPlus />
                                        )}
                                      </span> */}

                                      {/* <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">
                                          <span
                                            className="bg-white text-[10px] px-2 py-1 font-extrabold rounded-sm flex justify-center items-center"
                                            onClick={openRuleModal}
                                          >
                                            Rules
                                          </span>
                                          <span
                                            onClick={openRuleModal}
                                            className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>


                                        </div>
                                      </div> */}
                                    </div>

                                    <div className=" items-center flex relative bg-white z-[-1]">
                                      <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex">
                                        <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                          {/* {element.runners.length >
                                                        0
                                                        ? element.runners.length
                                                        : null}{" "}
                                                      Selection */}
                                        </div>
                                      </div>

                                      <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                        <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                          <span className="lg:block hidden"></span>
                                          <span className="lg:block hidden"></span>
                                          <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                            No
                                          </span>
                                          <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                            Yes
                                          </span>
                                          <span className="lg:block hidden"></span>
                                          <span className="lg:block hidden"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </header>
                                )}
                              {isFancyHide &&
                                matchScoreDetails &&
                                matchScoreDetails.session &&
                                matchScoreDetails.session.length > 0
                                ? matchScoreDetails.session
                                  .filter(
                                    (commList) => commList.com_perm == "YES"
                                  )
                                  .sort((a, b) => {
                                    const order = {
                                      STRING: 100,
                                      ONLY: 1,
                                      OVER: 2,
                                      "FALL OF": 3,
                                      RUN: 4,
                                      PSHIP: 5,
                                      BOUNDARIES: 6,
                                      HOW: 7,
                                      BALLS: 8,
                                    };

                                    const getSessionType = (sessionName) => {
                                      if (sessionName.includes("ONLY"))
                                        return "ONLY";
                                      if (sessionName.includes("OVER"))
                                        return "OVER";
                                      if (sessionName.includes("FALL OF"))
                                        return "FALL OF";
                                      if (sessionName.includes("RUN"))
                                        return "RUN";
                                      if (sessionName.includes("BOUNDARIES"))
                                        return "BOUNDARIES";
                                      if (sessionName.includes("HOW"))
                                        return "HOW";
                                      if (sessionName.includes("BALLS"))
                                        return "BALLS";
                                      return "STRING"; // Default to STRING if none match
                                    };

                                    const typeA = getSessionType(
                                      a.session_name
                                    );
                                    const typeB = getSessionType(
                                      b.session_name
                                    );

                                    // Compare based on the defined order
                                    let orderComparison =
                                      order[typeA] - order[typeB];

                                    // If types are the same, sort based on numeric value for "OVER"
                                    if (
                                      typeA === "OVER" &&
                                      typeB === "OVER"
                                    ) {
                                      const numberA =
                                        parseInt(
                                          a.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      const numberB =
                                        parseInt(
                                          b.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      return numberA - numberB; // Compare based on numeric values
                                    }

                                    return orderComparison;
                                  })
                                  .map(
                                    (commList, index) =>
                                      !deleteRowFancy.includes(index) && (
                                        <React.Fragment key={index}>
                                          <div
                                            className={` bg-[#fffff] relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11 `}
                                          >
                                            <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex px-2 ">
                                              <div className="w-full leading-3 flex items-center ">
                                                {/* <span className=" lg:hidden flex z-20 pr-1">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-600"
                                                        />
                                                      )}
                                                  </span>

                                                  <span
                                                    className="text-black text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-black text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span> */}

                                                {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                {/* </span> */}
                                                <span className="text-xs truncate font-bold">
                                                  <span className="text-black text-xs truncate">
                                                    {commList.session_name}
                                                  </span>
                                                  <br />
                                                  <p
                                                    key={index}
                                                    className={
                                                      isNaN(
                                                        parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        )
                                                      )
                                                        ? "text-green-500"
                                                        : parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        ) > 0
                                                          ? "text-green-500"
                                                          : parseFloat(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : "text-green-500"
                                                    }
                                                  >
                                                    {/* {isNaN(parseFloat(returnDataFancyObject[commList.session_id])) ? "" : parseFloat(returnDataFancyObject[commList.session_id]) !== 0 ? parseFloat(returnDataFancyObject[commList.session_id]).toFixed(2) : ""}  */}

                                                    {fancyPositionObj[
                                                      commList?.session_id
                                                    ]
                                                      ? (
                                                        Math.floor(
                                                          Number(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) * 100
                                                        ) / 100
                                                      ).toFixed(2)
                                                      : 0}
                                                  </p>
                                                </span>

                                              </div>
                                              {/* {!hideRowFancy.includes(
                                                index
                                              ) && (
                                                  <span className=" lg:flex text-nowrap hidden text-[10px] items-center text-black">
                                                    Stake Limit: 100 -
                                                    {commList?.max == 0 ? sessionCoin?.max : commList?.max}
                                                    <br /> Max Profit:
                                                    {commList?.max == 0 ? sessionCoin?.max : commList?.max}
                                                  </span>
                                                )} */}

                                              {commList.session_id in
                                                fancyPositionObj && (
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal({
                                                        positionData: commList,
                                                      })
                                                    }
                                                    className="text-black lg:hidden block"
                                                  >
                                                    <TbLadder
                                                      size={20}
                                                      className="text-gray-600"
                                                    />
                                                  </span>
                                                )}
                                            </div>
                                            <>
                                              <div className="w-1/2 lg:w-[75%] xl:w-1/2 grid grid-cols-6 gap-1 py-0.5">
                                                <span className=" lg:flex hidden justify-end m-auto">
                                                  {/* {commList.session_id in
                                                                                            fancyPositionObj && ( */}
                                                  {/* <span  onClick={() => handleFancyPositionModal({positionData: commList,}) } className="text-black flex justify-end">
                                                                                            <TbLadder size={20} className="text-gray-400"/>
                                                                                        </span> */}
                                                  {/* )} */}
                                                </span>
                                                <span
                                                  className="md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FAA9BA]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FAA9BA]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );

                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#72BBEF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#72BBEF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>

                                                <span className=" lg:block hidden"></span>
                                                {/* <span className=" lg:flex hidden z-20"> */}
                                                {/* <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-600"
                                                        />
                                                      )}
                                                  </span> */}
                                                {/* <span
                                                    className="text-black text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span> */}
                                                {/* <span
                                                    className="text-black text-[10px] px-2 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span> */}

                                                {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                {/* </span> */}

                                                {commList &&
                                                  commList.running_status &&
                                                  (commList.running_status ===
                                                    "SUSPENDED" ||
                                                    commList.running_status ===
                                                    "CLOSE" ||
                                                    commList.running_status ===
                                                    "Ball Running") ? (
                                                  <div
                                                    className={`w-1/2 px-0.5 right-0 h-full  absolute bg-[#E4E4E4] lg:bg-transparent border-gray-900   flex justify-center items-center`}
                                                  >
                                                    <div className="2xl:px-14 lg:px-14 py-2.5 px-2 text-nowrap border-2 lg:border-[#E4E4E4] rounded font-bold bg-transparent lg:bg-[#E4E4E4] opacity-90">
                                                      <span className="text-black text-xs font-bold  uppercase opacity-100">
                                                        {
                                                          commList.running_status
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </>
                                          </div>

                                          {index <
                                            matchScoreDetails?.session
                                              ?.length -
                                            1 && <hr />}
                                          {hiddenRows.includes(
                                            commList.session_id
                                          ) && (
                                              <BetPlaceMobile
                                                setStakeModal={setStakeModal}
                                                matchName={
                                                  inplayMatch?.matchName
                                                }
                                                closeSec={commList.session_id}
                                                closeRow={closeRow}
                                                openBets={openBets}
                                                betSlipData={betSlipData}
                                                placeBet={placeBet}
                                                count={betSlipData.count}
                                                betLoading={betLoading}
                                                increaseCount={increaseCount}
                                                decreaseCount={decreaseCount}
                                                setBetSlipData={setBetSlipData}
                                              />
                                            )}
                                        </React.Fragment>
                                      )
                                  )
                                : null}
                            </>
                          ) : null}
                        </div>
                      )}

                    {inplayMatch?.isFancy &&
                      isCommFancy &&
                      (activeTab === "Fancy" || activeTab === "all") && (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails?.session &&
                            matchScoreDetails?.session?.length > 0 ? (
                            <>
                              {matchScoreDetails.session.filter(
                                (commList) => commList.com_perm == "NO"
                              ).length > 0 && (
                                  <header className={`bg-${primaryColor} z-[1] relative`}>
                                    <div className=" items-center flex relative z-0 px-1">
                                      <div className=" flex flex-1 justify-start items-center ">
                                        <span className="border border-white rounded">
                                          <TbPinnedFilled size={15} className="text-white" />
                                        </span>
                                        <div className=" rounded-tl-md py-1.5 capitalize px-2 items-center inline-flex text-sm font-semibold text-white">
                                          Comm Fancy Bets
                                        </div>

                                      </div>

                                      {/* <span
                                        className="text-[10px] rounded font-semibold  px-2 py-0.5 border border-red-600 text-red-600"
                                        onClick={() =>
                                          setIsCommFancy(false)
                                        }
                                      >
                                        &#10005;
                                      </span> */}

                                      <div className='text-white font-semibold text-xs mx-1'>
                                        Min: 100 |
                                        Max: {minMaxCoins?.max}
                                      </div>

                                      <span
                                        className="text-white text-[10px] px-2 py-1 font-extrabold rounded-sm cursor-pointer flex justify-center items-center"
                                        onClick={() =>
                                          setIsCommFancyHide(
                                            !isCommFancyHide
                                          )
                                        }
                                      >
                                        {isCommFancyHide ? (
                                          <FaMinus />
                                        ) : (
                                          <FaPlus />
                                        )}
                                      </span>

                                      {/* <div className="  bg-[#333333]  rounded-tr-md flex flex-wrap justify-center items-center">
                                        <div className=" w-full bg-[#333333] rounded-tr-md flex flex-wrap justify-center px-4 py-1 space-x-1 items-center">

                                          <span
                                            onClick={openRuleModal}
                                            className="text-[10px] rounded font-semibold py-1.5 px-2.5 border border-white cursor-pointer"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="2"
                                              height="8"
                                              viewBox="0 0 2 8"
                                              fill="none"
                                            >
                                              <path
                                                _ngcontent-lmb-c38=""
                                                d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
                                                fill="white"
                                              ></path>
                                            </svg>
                                          </span>
                                          
                                        </div>
                                      </div> */}
                                    </div>

                                    <div className=" items-center flex relative bg-white z-[-1]">
                                      <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex">
                                        <div className="  uppercase px-2 items-center inline-flex text-xs font-bold text-white">
                                          {/* {element.runners.length >
                                                        0
                                                        ? element.runners.length
                                                        : null}{" "}
                                                      Selection */}
                                        </div>
                                      </div>

                                      <div className="w-1/2 lg:w-[75%] xl:w-1/2 divide-y divide-black rounded-tr-2xl flex justify-center items-center ">
                                        <div className="grid lg:grid-cols-6 grid-cols-2 text-[10px] text-black gap-1 py-0.5 w-full  rounded-tr-2xl  flex-wrap justify-center  items-center">
                                          <span className="lg:block hidden"></span>
                                          <span className="lg:block hidden"></span>
                                          <span className="lg:col-span-1 rounded-full rounded-bl-none text-nowrap bg-[#FAA9BA] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                            No
                                          </span>
                                          <span className="lg:col-span-1 text-nowrap rounded-full rounded-bl-none bg-[#72BBEF] font-bold text-center lg:!px-1 !px-0 py-1 text-[12px]">
                                            Yes
                                          </span>
                                          <span className="lg:block hidden"></span>
                                          <span className="lg:block hidden"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </header>
                                )}
                              {isCommFancyHide &&
                                matchScoreDetails &&
                                matchScoreDetails.session &&
                                matchScoreDetails.session.length > 0
                                ? matchScoreDetails.session
                                  .filter(
                                    (commList) => commList.com_perm == "NO"
                                  )
                                  .sort((a, b) => {
                                    const order = {
                                      STRING: 100,
                                      ONLY: 1,
                                      OVER: 2,
                                      "FALL OF": 3,
                                      RUN: 4,
                                      PSHIP: 5,
                                      BOUNDARIES: 6,
                                      HOW: 7,
                                      BALLS: 8,
                                    };

                                    const getSessionType = (sessionName) => {
                                      if (sessionName.includes("ONLY"))
                                        return "ONLY";
                                      if (sessionName.includes("OVER"))
                                        return "OVER";
                                      if (sessionName.includes("FALL OF"))
                                        return "FALL OF";
                                      if (sessionName.includes("RUN"))
                                        return "RUN";
                                      if (sessionName.includes("BOUNDARIES"))
                                        return "BOUNDARIES";
                                      if (sessionName.includes("HOW"))
                                        return "HOW";
                                      if (sessionName.includes("BALLS"))
                                        return "BALLS";
                                      return "STRING"; // Default to STRING if none match
                                    };

                                    const typeA = getSessionType(
                                      a.session_name
                                    );
                                    const typeB = getSessionType(
                                      b.session_name
                                    );

                                    // Compare based on the defined order
                                    let orderComparison =
                                      order[typeA] - order[typeB];

                                    // If types are the same, sort based on numeric value for "OVER"
                                    if (
                                      typeA === "OVER" &&
                                      typeB === "OVER"
                                    ) {
                                      const numberA =
                                        parseInt(
                                          a.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      const numberB =
                                        parseInt(
                                          b.session_name.split(" ")[0]
                                        ) || 0; // Extracting number before "OVER"
                                      return numberA - numberB; // Compare based on numeric values
                                    }

                                    return orderComparison;
                                  })
                                  .map(
                                    (commList, index) =>
                                      !deleteRowFancy.includes(index) && (
                                        <React.Fragment key={index}>
                                          <div
                                            className={` bg-[#ffffff] relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                          >
                                            <div className="w-1/2 lg:w-[25%] xl:w-1/2 flex px-2">
                                              <div className="w-full leading-3 flex items-center ">
                                                {/* <span className=" lg:hidden flex z-20 pr-1">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-400"
                                                        />
                                                      )}
                                                  </span>
                                                  <span
                                                    className="text-black text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-black text-[10px] px-0.5 py-1 font-extrabold rounded-sm  flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span> */}

                                                {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                {/* </span> */}
                                                <span className="text-xs truncate font-bold">
                                                  <span className="text-black text-xs truncate">
                                                    {commList.session_name}
                                                  </span>
                                                  <br />
                                                  <p
                                                    key={index}
                                                    className={
                                                      isNaN(
                                                        parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        )
                                                      )
                                                        ? "text-green-500"
                                                        : parseFloat(
                                                          fancyPositionObj[
                                                          commList
                                                            ?.session_id
                                                          ]
                                                        ) > 0
                                                          ? "text-green-500"
                                                          : parseFloat(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) < 0
                                                            ? "text-red-500"
                                                            : "text-green-500"
                                                    }
                                                  >
                                                    {/* {isNaN(parseFloat(returnDataFancyObject[commList.session_id])) ? "" : parseFloat(returnDataFancyObject[commList.session_id]) !== 0 ? parseFloat(returnDataFancyObject[commList.session_id]).toFixed(2) : ""}  */}

                                                    {fancyPositionObj[
                                                      commList?.session_id
                                                    ]
                                                      ? (
                                                        Math.floor(
                                                          Number(
                                                            fancyPositionObj[
                                                            commList
                                                              ?.session_id
                                                            ]
                                                          ) * 100
                                                        ) / 100
                                                      ).toFixed(2)
                                                      : 0}
                                                  </p>
                                                </span>
                                              </div>
                                              {/* {!hideRowFancy.includes(
                                                index
                                              ) && (
                                                  <span className=" lg:flex text-nowrap hidden text-[10px] items-center text-black">
                                                    Stake Limit: 100 -
                                                    {commList?.max == 0 ? sessionCoin?.max : commList?.max}
                                                    <br /> Max Profit:
                                                    {commList?.max == 0 ? sessionCoin?.max : commList?.max}
                                                  </span>
                                                )} */}

                                              {commList.session_id in
                                                fancyPositionObj && (
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal({
                                                        positionData: commList,
                                                      })
                                                    }
                                                    className="text-black lg:hidden block"
                                                  >
                                                    <TbLadder
                                                      size={20}
                                                      className="text-gray-600"
                                                    />
                                                  </span>
                                                )}
                                            </div>
                                            <>
                                              <div className="w-1/2 lg:w-[75%] xl:w-1/2 grid grid-cols-6 gap-1 py-0.5">
                                                <span className=" lg:flex hidden justify-end m-auto">
                                                  {/* {commList.session_id in
                                                                                            fancyPositionObj && ( */}
                                                  {/* <span  onClick={() => handleFancyPositionModal({positionData: commList,}) } className="text-black flex justify-end">
                                                                                            <TbLadder size={20} className="text-gray-400"/>
                                                                                        </span> */}
                                                  {/* )} */}
                                                </span>
                                                <span
                                                  className="md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FAA9BA]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "No",
                                                      odds: commList.oddsNo,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "N",
                                                      run: commList.runsNo,
                                                      selectionId:
                                                        commList.session_id,
                                                      price: commList.runsNo,
                                                      size: commList.oddsNo,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsNo}
                                                        size={(
                                                          commList.oddsNo * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#FAA9BA]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#f996ab]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );

                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#72BBEF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>
                                                <span
                                                  className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block "
                                                  onClick={() => {
                                                    toggleRowVisibility(
                                                      commList.session_id
                                                    );
                                                    handleBackOpen({
                                                      data: commList,
                                                      type: "Yes",
                                                      odds: commList.oddsYes,
                                                      name: commList.session_name,
                                                      nameSession:
                                                        commList.session_name,
                                                      betFor: "fancy",
                                                      oddsType: "fancy",
                                                      betType: "Y",
                                                      run: commList.runsYes,
                                                      selectionId:
                                                        commList.session_id,
                                                      betfairMarketId:
                                                        marketId,
                                                      price: commList.runsYes,
                                                      size: commList.oddsYes,
                                                      position:
                                                        returnDataFancyObject,
                                                    });
                                                  }}
                                                >
                                                  {!hideRowFancy.includes(
                                                    index
                                                  ) && (
                                                      <Blinking
                                                        price={commList.runsYes}
                                                        size={(
                                                          commList.oddsYes * 100
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")}
                                                        color={"bg-[#72BBEF]"}
                                                        blinkColor={
                                                          "bg-[#CDEBEB]"
                                                        }
                                                        textColors={
                                                          "text-black"
                                                        }
                                                        boderColors={
                                                          "border-[#489bbd]"
                                                        }
                                                      />
                                                    )}
                                                </span>

                                                <span className=" lg:block hidden"></span>
                                                {/* <span className=" lg:flex hidden z-20">
                                                  <span
                                                    onClick={() =>
                                                      handleFancyPositionModal(
                                                        {
                                                          positionData:
                                                            commList,
                                                        }
                                                      )
                                                    }
                                                    className="text-black flex items-center justify-center"
                                                  >
                                                    {!hideRowFancy.includes(
                                                      index
                                                    ) && (
                                                        <TbLadder
                                                          size={20}
                                                          className="text-gray-600"
                                                        />
                                                      )}
                                                  </span>
                                                  <span
                                                    className="text-black text-[10px] px-2 py-1 font-extrabold rounded-sm cursor-pointer flex justify-center items-center"
                                                    onClick={() => {
                                                      setDeleteRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <ImCross />
                                                  </span>
                                                  <span
                                                    className="text-black text-[10px] px-2 py-1 font-extrabold rounded-sm cursor-pointer flex justify-center items-center"
                                                    onClick={() => {
                                                      setHideRowFancy(
                                                        (prevState) => {
                                                          if (
                                                            prevState.includes(
                                                              index
                                                            )
                                                          ) {
                                                            return prevState.filter(
                                                              (item) =>
                                                                item !== index
                                                            );
                                                          } else {
                                                            return [
                                                              ...prevState,
                                                              index,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {hideRowFancy.includes(
                                                      index
                                                    ) ? (
                                                      <FaPlus />
                                                    ) : (
                                                      <FaMinus />
                                                    )}
                                                  </span> */}

                                                {/* <span onClick={() => this.hideDiv(element.Selection_id)} className='w-5 h-5 bg-black rounded-full flex justify-center items-center text-white font-bold'>&times;</span> */}
                                                {/* </span> */}

                                                {commList &&
                                                  commList.running_status &&
                                                  (commList.running_status ===
                                                    "SUSPENDED" ||
                                                    commList.running_status ===
                                                    "CLOSE" ||
                                                    commList.running_status ===
                                                    "Ball Running") ? (
                                                  <div
                                                    className={`w-1/2 px-0.5 right-0 h-full  absolute bg-[#E4E4E4] lg:bg-transparent border-gray-900   flex justify-center items-center`}
                                                  >
                                                    <div className="2xl:px-14 lg:px-14 py-2 px-2 text-nowrap border-2 lg:border-[#E4E4E4] rounded font-bold bg-transparent lg:bg-[#E4E4E4] opacity-90">
                                                      <span className="text-black text-xs font-bold  uppercase opacity-100">
                                                        {
                                                          commList.running_status
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </>
                                          </div>

                                          {index <
                                            matchScoreDetails?.session
                                              ?.length -
                                            1 && <hr />}
                                          {hiddenRows.includes(
                                            commList.session_id
                                          ) && (
                                              <BetPlaceMobile
                                                setStakeModal={setStakeModal}
                                                matchName={
                                                  inplayMatch?.matchName
                                                }
                                                closeSec={commList.session_id}
                                                closeRow={closeRow}
                                                openBets={openBets}
                                                betSlipData={betSlipData}
                                                placeBet={placeBet}
                                                count={betSlipData.count}
                                                betLoading={betLoading}
                                                increaseCount={increaseCount}
                                                decreaseCount={decreaseCount}
                                                setBetSlipData={setBetSlipData}
                                              />
                                            )}
                                        </React.Fragment>
                                      )
                                  )
                                : null}
                            </>
                          ) : null}
                        </div>
                      )}


                  </div>



                  <div className='pt-4 xl:w-2/5 px-1'>
                    <div className="sm:block hidden">
                      <div className={`border-white border-b-[1px] bg-${primaryColor}`}>
                        <div className="flex text-white justify-between items-center px-2">
                          <div className="flex gap-2 py-2 text-sm font-semibold">Live TV</div>
                          <div
                            onClick={() => handleChange()}
                            className={`flex items-center justify-center w-12 h-full rounded-full px-1 ${isChecked ? 'bg-[#28a745]' : 'bg-[#FF0000]'}`}>
                            <label className="flex items-center cursor-pointer relative">
                              <input
                                type="checkbox"
                                className="sr-only"
                                onChange={handleChange}
                                checked={isChecked}
                              />
                              <div
                                className={`block relative w-[65px] h-[22px] py-[1px] text-center rounded-full before:absolute before:bg-white before:w-[20px] before:h-[20px] before:text-center before:rounded-full before:transition-all before:duration-500 ${isChecked
                                  ? 'before:left-9'
                                  : 'before:left-3 peer-checked:before:left-0 peer-checked:before:bg-white'
                                  }`}
                              ></div>
                            </label>
                          </div>
                        </div>
                        <div>
                          {inplayMatch.isTv ? (
                            <>
                              {isScorecardOpen === "tv" && inplayMatch.tvUrl && (
                                <div className="bg-white w-full rounded-lg border-2 border-secondary">
                                  <div className="details">
                                    <div
                                      className={`w-full relative md:text-sm text-[10px]`}
                                    >
                                      <iframe
                                        src={
                                          inplayMatch && inplayMatch.tvUrl
                                            ? inplayMatch.tvUrl
                                            : ""
                                        }
                                        title=" "
                                        loading="lazy"
                                        className="w-[100%] h-[300px]"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <BetListComponent
                      oddsBetData={oddsBetData}
                      fancyBetData={fancyBetData}
                      matchName={inplayMatch?.matchName}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* // )} */}
          </div>

          <div
            ref={scrollRef}
            className={`lg:w-4/12 px-2 py-2 right-1 lg:block hidden overflow-hidden ${isFixed ? "dddddd" : "fffffffff"
              }`}
          >
            {inplayMatch.isTv ? (
              <>
                {tvShow && (
                  <div className="bg-white w-full h-72">
                    <div className="details">
                      <div className={`w-full relative md:text-sm text-[10px]`}>
                        <iframe
                          src={
                            inplayMatch && inplayMatch.tvUrl
                              ? inplayMatch.tvUrl
                              : ""
                          }
                          title=" "
                          loading="lazy"
                          className="w-[100%] h-[300px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : null}

          </div>
          {fancypositionModal && (
            <FancyLadder
              handleClose={handleClose}
              positionData={positionData}
              marketId={marketId}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default MatchViewDeatils;


{/* <Rules isOpen={isRulesOpen} closeModal={closeRuleModal} /> */ }
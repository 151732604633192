import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetList } from "../../redux/_reducers/_user_reducers";
import moment from "moment";

const Exposer = () => {
  const [betListData, setBetListData] = useState([]);
  const dispatch = useDispatch();
  const { sportBetList, loading } = useSelector(state => state.user);

  useEffect(() => {
    getUnsettalBet();
  }, [dispatch]);

  useEffect(() => {
    showBets();
  }, [sportBetList]);

  const getUnsettalBet = () => {
    const reqdata = {
      fancyBet: true,
      oddsBet: true,
      isDeclare: false,
      diamondBet: true,
      isClientExposure: true
    };
    dispatch(getBetList(reqdata));
  };

  const showBets = () => {
    if (sportBetList) {
      setBetListData(sportBetList);
    }
  };


  return (
    <div className="overflow-auto">
      <table className="w-full">
        <thead className="text-[12px] text-[#243a48] bg-[#E4E4E4] border border-white">
          <tr className="border border-white">
            <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[120px]">
              <div className="flex justify-between items-center gap-1">
                Match
              </div>
            </th>
            <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[200px]">
              <div className="flex justify-between items-center gap-1">
                Stake
              </div>
            </th>
            <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">
              <div className="flex justify-between items-center gap-1">
                Rate
              </div>
            </th>
            <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
              <div className="flex justify-between items-center gap-1">
                Type
              </div>
            </th>
            <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
              <div className="flex justify-between items-center gap-1">
                Time
              </div>
            </th>
            <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
              <div className="flex justify-between items-center gap-1">
                Market Type
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
         
          {betListData && betListData?.length > 0 ? betListData?.map((item, idx) => (
            <tr key={idx} className="bg-white border-b-[1px] border-secondary uppercase">
              <td className="px-2 text-[#333] py-2 text-[12px] font-medium border border-[#ddd] whitespace-nowrap">{item?.market}</td>
              <td className="px-2 text-[#333] py-2 text-[12px] font-medium border border-[#ddd] whitespace-nowrap">{item?.amount}</td>
              <td className="px-2 text-[#333] py-2 text-[12px] font-medium border border-[#ddd] whitespace-nowrap">{item?.rate}</td>
              <td className="px-2 text-[#333] py-2 text-[12px] font-medium border border-[#ddd] whitespace-nowrap">{item?.type}</td>
              <td className="px-2 text-[#333] py-2 text-[12px] font-medium border border-[#ddd] whitespace-nowrap">{moment(item.time).format("YYYY-MM-DD hh:mm:ss a")}</td>
              <td className="px-2 text-[#333] py-2 text-[12px] font-medium border border-[#ddd] whitespace-nowrap">{item?.marketType}</td>
            </tr>
          )) : (
            <tr className='text-center border-2 px-2 py-3 text-[12px]'>
              <th colSpan={6}>No data found</th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Exposer;

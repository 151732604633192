import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import Lucky7arules from './images/lucky7b-rules.jpg';
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import GameCard from "./casino_components/GameCard";
import Card from "./casino_components/Card";
import GameCard2 from "./casino_components/GameCard2";
import { FaInfoCircle } from "react-icons/fa";
// import { httpPost, httpPostBet } from "../../../../middelware/Http";
// import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";
import { httpPost, httpPostBet } from "../../../middelware/CasinoMiddleware/Http";
import Loader from "../../../component/Loader/Loader";

class Lucky7b extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "lucky7eu",
      betList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      LoadingBet: false,
      isModalOpen: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };


  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  handleBackOpen = (data) => {
    this.betForSet(data.nation)
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.rate, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time < 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);
  };

  betForSet = (nation) => {
    let value = nation.split(' ')[0];
    if (value === "HIGH") {
      this.setState({ betFor: "lowHigh" })
    } else if (value === "LOW") {
      this.setState({ betFor: "lowHigh" })
    } else if (value === "Card") {
      this.setState({ betFor: "cards" })
    } else if (value === "Red") {
      this.setState({ betFor: "color" })
    } else if (value === "Black") {
      this.setState({ betFor: "color" })
    } else if (value === "Odd" || "Even") {
      this.setState({ betFor: "oddEven" })
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  componentDidMount() {

    let requestData = {
      eventId: '3032',
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "lucky7eu",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionationtempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let betList = getCasinoDetails?.data?.casinoBetData

    let totalProfitLoss = 0
    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleClose = () => {
    this.setState({ newCasinoModal: false, betOpen: false, stakeopen: false, backBetModal: false, backBetModalMobile: false, });
  };
  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };


  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };


  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid,
      "rate": (this.state.count - this.state.oddsDifference) + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "lucky7eu",
      "eventId": '3032',
      "betFor": this.state.betFor + "",
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
        this.betList('3032')
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)

        // toast.error(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleToggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, isModalOpen, minStake, maxStake } = this.state;
    let LOWCard = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[0] ? casinoData.data.t2[0] : {};
    let HIGHCard = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[1] ? casinoData.data.t2[1] : {};
    let Even = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[2] ? casinoData.data.t2[2] : {};
    let Odd = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[3] ? casinoData.data.t2[3] : {};
    let Red = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[4] ? casinoData.data.t2[4] : {};
    let Black = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[5] ? casinoData.data.t2[5] : {};
    let CardA = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[6] ? casinoData.data.t2[6] : {};
    let Card2 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[7] ? casinoData.data.t2[7] : {};
    let Card3 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[8] ? casinoData.data.t2[8] : {};
    let Card4 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[9] ? casinoData.data.t2[9] : {};
    let Card5 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[10] ? casinoData.data.t2[10] : {};
    let Card6 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[11] ? casinoData.data.t2[11] : {};
    let Card7 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[12] ? casinoData.data.t2[12] : {};
    let Card8 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[13] ? casinoData.data.t2[13] : {};
    let Card9 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[14] ? casinoData.data.t2[14] : {};
    let Card10 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[15] ? casinoData.data.t2[15] : {};
    let CardJ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[16] ? casinoData.data.t2[16] : {};
    let CardQ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[17] ? casinoData.data.t2[17] : {};
    let CardK = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[18] ? casinoData.data.t2[18] : {};
    let t1 = casinoData && casinoData.data && casinoData.data && casinoData.data.t1 && casinoData.data.t1[0] ? casinoData.data.t1[0] : {};

    return (
      <>

        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader/>
          // <div>Loader...</div>
          :
          <div className={`w-full relative h-full  overflow-y-auto text-sm flex my-2`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Lucky7-B"}
                shortName={shortName ? shortName : "lucky7eu"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full lg:pt-1 pt-0 lg:space-x-2">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={Lucky7arules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (

                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "Lucky7-B"}
                        ruleImage={Lucky7arules}
                        t1={t1}
                      />

                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px] border border-[#24e6a4]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                        <div className=" flex justify-between">
                          <div className="absolute top-0 left-0">
                            <div className="p-1.5">
                              {/* <div className="text-white text-[10px] font-[500] ">CARD</div> */}
                              <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-8 w-7 border-[1px] border-yellow-300" />
                            </div>
                          </div>

                          <div className="flex justify-end items-end absolute bottom-2 right-1">
                            <FlipCountdown
                              titlePosition='hidden'
                              hideYear
                              hideMonth
                              hideDay
                              hideHour
                              hideMinute
                              endAtZero
                              size='small'
                              endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-y-1.5 ">

                        <div className=" mt-1.5 border border-[#24e6a4]">
                          <div className="odds-bet py-1 w-full ">
                            <div className=" grid grid-cols-5 md:gap-6 text-center lg:px-2.5 px-1.5 w-full">
                              <div class="md:col-span-2 col-span-2 w-full">
                                <GameCard
                                  handleBackOpen={this.handleBackOpen}
                                  Data={LOWCard}
                                  Name={LOWCard.nat ? LOWCard.nat : LOWCard.nation ? LOWCard.nation : "LOWCard"}
                                  section1Ref={this.section1Ref}
                                  posArray={posArray}
                                  oddsDifference={oddsDifference}
                                />
                              </div>
                              <div class="flex justify-center items-center w-full">
                                <div className=" flex justify-center items-center p-1 rounded-sm">
                                  <img src="/images/7.jpg" alt="aaaaaa" className="lg:h-14 lg:w-12 h-13 w-11" />
                                </div>
                              </div>
                              <div class="md:col-span-2 col-span-2 w-full">
                                <GameCard
                                  handleBackOpen={this.handleBackOpen}
                                  Data={HIGHCard}
                                  Name={HIGHCard.nat ? HIGHCard.nat : HIGHCard.nation ? HIGHCard.nation : "HIGHCard"}
                                  section1Ref={this.section1Ref}
                                  posArray={posArray}
                                  oddsDifference={oddsDifference}
                                />
                              </div>
                            </div>
                            <div className="flex justify-end items-center font-normal lg:text-[14px] text-[10px] black-text px-2.5">
                              <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                            </div>
                          </div>
                        </div>

                        <div className="lg:flex grid grid-cols-2 gap-3">

                          <div className="w-full odds-bet  border border-[#24e6a4]">
                            <div class="w-full grid grid-cols-2 lg:gap-6 gap-2 h-[84px] py-2 text-center lg:px-2.5 px-1.5">
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Even}
                                Name={Even.nat ? Even.nat : Even.nation ? Even.nation : "Even"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                                oddsDifference={oddsDifference}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Odd}
                                Name={Odd.nat ? Odd.nat : Odd.nation ? Odd.nation : "Odd"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                                oddsDifference={oddsDifference}
                              />
                            </div>
                            <div className="flex justify-end items-center font-normal lg:text-[14px] text-[10px] black-text px-2.5 pb-1">
                              <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                            </div>
                          </div>

                          <div className=" w-full odds-bet  border border-[#24e6a4]">
                            <div class="w-full grid grid-cols-2 lg:gap-6 gap-2 h-[84px] py-2 text-center lg:px-2.5 px-1.5">
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Red}
                                select={"Red"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                                oddsDifference={oddsDifference}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Black}
                                select={"Black"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                                oddsDifference={oddsDifference}
                              />

                            </div>
                            <div className="flex justify-end items-center font-normal lg:text-[14px] text-[10px] black-text px-2.5 pb-1">
                              <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                            </div>
                          </div>

                        </div>

                        <div className="odds-bet mt-1 border border-[#24e6a4]">

                          <div className="p-2 relative lg:hidden flex justify-between items-center">
                            <div className="flex justify-center items-center w-full">
                              <p className="text-black text-[14px] font-bold py-1">{CardA?.rate ? CardA.rate - oddsDifference : 0}</p>
                            </div>
                            <div className="text-[#52796f] flex justify-end items-center cursor-pointer " onClick={this.handleToggleModal}>
                              <FaInfoCircle className="hover:text-[#007bff] " size={16} />
                            </div>

                            {isModalOpen && (
                              <div className="modal bg-white absolute z-50 top-8 right-4">
                                <div className="modal-content">
                                  <div className="w-16 text-center font-normal lg:text-[15px] text-[10px] py-1.5 black-text whitespace-nowrap">
                                    <span className="font-[500]">MIN</span>:{minStake}<br /><span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <p className="text-black text-center lg:block hidden text-[14px] font-bold py-1.5">{CardA?.rate ? CardA.rate - oddsDifference : 0}</p>
                          <div class="w-11/12 py-1 mx-auto grid grid-cols-7 lg:flex justify-center items-center md:space-x-2 space-x-0 gap-2 px-2 text-center ">
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={CardA}
                              num={"1"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card2}
                              num={"2"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card3}
                              num={"3"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card4}
                              num={"4"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card5}
                              num={"5"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card6}
                              num={"6"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card7}
                              num={"7"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card8}
                              num={"8"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card9}
                              num={"9"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={Card10}
                              num={"10"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={CardJ}
                              num={"11"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={CardQ}
                              num={"12"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                            <Card
                              handleBackOpen={this.handleBackOpen}
                              Data={CardK}
                              num={"13"}
                              section1Ref={this.section1Ref}
                              posArray={posArray}
                            />
                          </div>
                          <div className="lg:flex hidden justify-end items-center font-normal lg:text-[14px] text-[12px] black-text px-2.5 py-1.5">
                            <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                          </div>
                        </div>

                      </div>

                      <div className=" pb-2 bg-black/5 mt-0.5 border border-[#24e6a4]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 lg:justify-end justify-center items-center py-1.5 px-2 ">
                          {casinoData && casinoData.result && casinoData.result ? casinoData.result.map((element, index) => (
                            <div onClick={() =>
                              this.handleResultModel(element)} className={`w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700 ${element && element.result && element.result === "0" ? "text-[#08c] bg-[#355e3b]" : element && element.result && element.result === "1" ? "bg-[#355e3b] text-[#ff4500]" : element && element.result && element.result === "2" ? "text-[#FFFF2E] bg-[#355e3b]" : "text-[#FFFF2E] bg-[#355e3b]"}`} >
                              <p className={`font-[700] text-[14px] `}>
                                {element && element.result && element.result === "0" ? "T" : element && element.result && element.result === "1" ? "L" : element && element.result && element.result === "2" ? "H" : "-"}
                              </p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>

                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}

              </div>

            </div>
          </div>

        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Lucky7b);

import { apiCall } from "../../middelware/Http";

async function getMatchList(data) {
  try {
    const user = await apiCall("POST", "sports/matchList", data);
    if (user) {
      const matchList = user?.data && Object.keys(user?.data).length > 0 ? user?.data : [];
      localStorage.setItem("matchList", JSON.stringify(matchList));
      return user;
    }
  } catch (error) {
    console.error("MatchList error:", error);
    return Promise.reject(error);
  }
}


async function getAccountStatement(data) {
  try {
    const user = await apiCall("POST", "user/userStatement", data);

    if (user) {
      return user;
    }
  } catch (error) {
    console.error("Account Statement error:", error);
    return Promise.reject(error);
  }
}

async function getDepositWithdrawList(data) {
  try {
    const user = await apiCall("POST", "website/getDepositWithdrawList", data);

    if (user) {
      return user;
    }
  } catch (error) {
    console.error("Account Statement error:", error);
    return Promise.reject(error);
  }
}
async function getuserLedger(data) {
  try {
    const user = await apiCall("POST", "user/userLedger", data);

    if (user) {
      return user;
    }
  } catch (error) {
    console.error("ledger error:", error);
    return Promise.reject(error);
  }
}
async function getDomainSettingData(data) {
  try {
    const user = await apiCall("POST", "website/domainSettingByDomainName", data);

    if (user) {
      localStorage.setItem('clientdomainSetting', JSON.stringify(user.data));
      return user;
    }
  } catch (error) {
    console.error("Domain setting error:", error);
    return Promise.reject(error);
  }
}
async function getUserBalance (data) {
  try {
    const user = await apiCall("POST", "user/userBalance", data);

    if (user) {

      localStorage.setItem('clientBalance', JSON.stringify(user.data.coins));
      localStorage.setItem('clientExposure', JSON.stringify(user.data.exposure));
      localStorage.setItem('clientProfitLoss', JSON.stringify(user.data.profitLoss));
      return user;
    }
  } catch (error) {
    console.error("Domain setting error:", error);
    return Promise.reject(error);
  }
}

async function userUpdate (data) {
  try {
    const user = await apiCall("PATCH", "user/userUpdate", data);

    if (user) {
      console.log(user)
      return user;
    }
  } catch (error) {
    console.error("user Update error:", error);
    return Promise.reject(error);
  }
}


async function getInternationalGroupCasinoList (data) {
  try {
    const cosinoGroupList = await apiCall("POST", "website/getInternationalGroupCasinoList", data);
    if (cosinoGroupList) {
      localStorage.setItem("cosinoGroupList", JSON.stringify(cosinoGroupList.data));
      return cosinoGroupList;
    }
  } catch (error) {
    console.error("user Update error:", error);
    return Promise.reject(error);
  }
}


async function getCasinoListByProviderName (data) {
  try {
    const cosinoGroupList = await apiCall("POST", "website/getCasinoListByProviderName", data);
    if (cosinoGroupList) {
      return cosinoGroupList;
    }
  } catch (error) {
    console.error("user Update error:", error);
    return Promise.reject(error);
  }
}


async function getCasinoListByCateogeory (data) {
  try {
    const casinoListByCateogeory = await apiCall("POST", "website/getCasinoListByCateogeory", data);
    if (casinoListByCateogeory) {
      return casinoListByCateogeory;
    }
  } catch (error) {
    console.error("user Update error:", error);
    return Promise.reject(error);
  }
}
async function getResult (data) {
  try {
    const casinoListByCateogeory = await apiCall("POST", "website/getResults", data);
    if (casinoListByCateogeory) {
      return casinoListByCateogeory;
    }
  } catch (error) {
    console.error("user Update error:", error);
    return Promise.reject(error);
  }
}

async function getBetList (data) {
  try {
    const casinoListByCateogeory = await apiCall("POST", "sports/betsList", data);
    if (casinoListByCateogeory) {
      return casinoListByCateogeory;
    }
  } catch (error) {
    console.error("user Update error:", error);
    return Promise.reject(error);
  }
}




export const userServices = {
  getBetList,
  getMatchList,
  getAccountStatement,
  getuserLedger,
  getDomainSettingData,
  getUserBalance,
  getDepositWithdrawList,
  userUpdate,
  getInternationalGroupCasinoList,
  getCasinoListByProviderName,
  getCasinoListByCateogeory,
  getResult
};

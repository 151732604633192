import Sidebar from "../component/sidebar/Sidebar";
import Dashboard from "../pages/Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../component/header/Header";
import { useState } from "react";
import Footer from "../component/footer/Footer";
import Profile from "../pages/Profile";
import AccountStatement from "../pages/AccountStatement";
import Deposit from "../component/Deposit/Deposit";
import Withdrawal from "../component/Withdrawal/Withdrawal";
import MatchViewDeatils from "../pages/matchDeatils";
import ProfitLoss from "../pages/ProfitLoss";
import Information from "../component/information/Information";
import Contact from "../component/content/content";
import CasinoList from "../pages/CasinoList";
import Teenpattit20 from "../pages/DiamondCasino/Casino/Teenpattit20";
import Worlimatka from "../pages/DiamondCasino/Casino/Worlimatka";
import Dragontiger20 from "../pages/DiamondCasino/Casino/Dragontiger20";
import Teenpattionday from "../pages/DiamondCasino/Casino/Teenpattionday";
import Lucky7b from "../pages/DiamondCasino/Casino/Lucky7b";
import Amarakbaranthony from "../pages/DiamondCasino/Casino/Amarakbaranthony";
import Cards32A from "../pages/DiamondCasino/Casino/Cards32A";
import Dragontiger202 from "../pages/DiamondCasino/Casino/Dragontiger202";
import Cards32B from "../pages/DiamondCasino/Casino/cards32B";
import Teenpattittest from "../pages/DiamondCasino/Casino/Teenpattittest";
import DepositWithdrwal from "../pages/DepositWithdrwal";
import Inplay from "../pages/Inplay";
import PrivateRoute from "../routes/PrivateRoute";
import SlotGames from "../pages/SlotGames";
import Andarbahar2 from "../pages/DiamondCasino/Casino/Andarbahar2";
import Greyhound from "../pages/Greyhound/Greyhound";
import HorseRacing from "../pages/HorseRacing/HorseRacing";
import IntCasino from "../pages/IntCasino/IntCasino";
import Rules from "../pages/Rules";
import AllCricketMatches from "../pages/AllCricketMatches";
import AllSoccerMatches from "../pages/AllSoccerMatches";
import AllTennisMatches from "../pages/AllTennisMatches";
import IframeCasino from "../pages/DiamondCasino/Casino/IframeCasino/IframeCasino";
import IframeCasinonew from "../pages/DiamondCasino/Casino/IframeCasino/IframeCasinonew";
import IframeQtech from "../pages/DiamondCasino/Casino/IframeCasino/IframeQtech";
import Casino from "../pages/DiamondCasino/AllCasino/Casino";
import Result from "../pages/Result";
import SetButtonValue from "../pages/SetButtonValue";
import BetHistory from "../pages/BetHistory";
import Exposer from "../pages/Expopser/Exposer";


const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <>


      <section className="w-full h-[100vh]">
        <div className="">
          <Header setSidebarOpen={setIsSidebarOpen} />
          <div className="w-full px-1 flex">
            <div className="lg:min-w-[280px] lg:max-w-[280px] border-r border-gray-300 bg-white z-[9999]">
              <Sidebar
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen} />
            </div>
            <div className="lg:w-[calc(100%-280px)]  w-full  mb-6">
            {/* overflow-auto */}
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                {/* <Route path="/demo" element={<Dashboard />} /> */}
                <Route path="/bet-history" element={<BetHistory />} />
                <Route path="/inplay" element={<Inplay />} />
                <Route path="/match-details/:marketId?/:eventId?" element={<PrivateRoute element={<MatchViewDeatils />} />} />
                <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
                <Route path="/account-statement" element={<PrivateRoute element={<AccountStatement />} />} />
                <Route path="/deposit-withdrwal" element={<PrivateRoute element={<DepositWithdrwal />} />} />
                <Route path="/profit-loss" element={<PrivateRoute element={<ProfitLoss />} />} />
                <Route path="/deposit" element={<PrivateRoute element={<Deposit />} />} />
                <Route path="/withdrawal" element={<PrivateRoute element={<Withdrawal />} />} />
                <Route path="/information" element={<PrivateRoute element={<Information />} />} />
                <Route path="/contact" element={<PrivateRoute element={<Contact />} />} />
                <Route path="/casino" element={<Casino />} />
                <Route path="/result" element={<Result />} />
                <Route path="/Slot-games" element={<SlotGames />} />
                <Route path="/Rules" element={<Rules />} />
                <Route path="/setButton-value" element={<SetButtonValue />} />
               
                <Route path="/teen20/:eventId" element={<PrivateRoute element={<Teenpattit20 />} />} />
                <Route path="/worli/:eventId" element={<PrivateRoute element={<Worlimatka />} />} />
                <Route path="/abj/:eventId?" element={<PrivateRoute element={<Andarbahar2 />} />} />
                <Route path="/dt20/:eventId" element={<PrivateRoute element={<Dragontiger20 />} />} />
                <Route path="/teen/:eventId" element={<PrivateRoute element={<Teenpattionday />} />} />
                <Route path="/lucky7eu/:eventId" element={<PrivateRoute element={<Lucky7b />} />} />
                <Route path="/card32-a/:eventId" element={<PrivateRoute element={<Cards32A />} />} />
                <Route path="/aaa/:eventId" element={<PrivateRoute element={<Amarakbaranthony />} />} />
                <Route path="/dt202/:eventId" element={<PrivateRoute element={<Dragontiger202 />} />} />
                <Route path="/card32eu/:eventId" element={<PrivateRoute element={<Cards32B />} />} />
                <Route path="/teen9/:eventId" element={<PrivateRoute element={<Teenpattittest />} />} />
                <Route path="/greyhound" element={<PrivateRoute element={<Greyhound />} />} />
                <Route path="/horse-recing" element={<PrivateRoute element={<HorseRacing />} />} />
                <Route path="/int-casino" element={<PrivateRoute element={<IntCasino />} />} />
                <Route path="/allcricket" element={<PrivateRoute element={<AllCricketMatches />} />} />
                <Route path="/allsoccer" element={<PrivateRoute element={<AllSoccerMatches />} />} />
                <Route path="/alltennis" element={<PrivateRoute element={<AllTennisMatches />} />} />
                <Route path="/iframe-casino/:gameId?" element={<PrivateRoute element={<IframeCasino />} />} />
                <Route path="/iframe-casino-new/:provider?/:gameId?" element={<PrivateRoute element={<IframeCasinonew />} />} />
                <Route path="/iframe-qtech-casino/:gameId?" element={<PrivateRoute element={<IframeQtech />} />} />
              
                <Route path="/unsettal-bet" element={<PrivateRoute element={<Exposer />} />} />
              </Routes>
            </div>
          </div>
        </div>
      </section>

    </>

  );
};

export default Layout;

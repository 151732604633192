export const domainName = 'magic_win'
export const  SPORTSCONSTANT =[
    {
        text: 'Cricket',
        count: "4",
        iconImg:"/winjaImages/DashboardIcon/cricket-ball.png"
    },
    {
        text: 'Tennis',
        count: "2",
        iconImg:"/winjaImages/DashboardIcon/tennis-ball.png"
    },
    {
        text: 'Football',
        count: "1",
        iconImg:"/winjaImages/DashboardIcon/football-ball.png"
    },
    // {
    //     text: 'Election',
    //     count: "999",
    //      iconImg:"winjaImages/DashboardIcon/election.png"
    // },
    

]

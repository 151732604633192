
export const primaryColor="[rgba(0,8,73,0.9)]"

export const HomeJson = [
    { pathName: "casino.jpg", section: "banner" },
    { pathName: "ipl-2024.jpg", section: "banner" },
    { pathName: "soccer-banner.jpg", section: "banner" },
    { pathName: "t-20-orld-cup-2024.jpg", section: "banner" },
    { pathName: "tennis-banner.jpg", section: "banner" },
    { pathName: "footballground.png", section: "trending" },
    { pathName: "tinpati.png", section: "trending" },
    { pathName: "casino.png", section: "trending" },
    { pathName: "footballground.png", section: "trending" },
    { pathName: "tinpati.png", section: "trending" },
    { pathName: "casino.png", section: "trending" },
    { pathName: "footballground.png", section: "trending" },
    { pathName: "tinpati.png", section: "trending" },
    { pathName: "casino.png", section: "trending" },
    { pathName: "footballground.png", section: "trending" },
    { pathName: "tinpati.png", section: "trending" },
    { pathName: "casino.png", section: "trending" },
  
    


    { pathName: "20-20-Dragon-tiger.png", section: "allGames" ,name:'20-20-Dragon-tiger'},
    { pathName: "Bollywood-casino.png", section: "allGames", name:'Bollywood-casino'},
    { pathName: "32card-A.png", section: "allGames" ,name:'32card-A' },
    { pathName: "Amar-Akbar-Anthony.png", section: "allGames"  ,name:'Amar-Akbar-Anthony'},
    { pathName: "Bollywood-casino.png", section: "allGames"  ,name:'Bollywood-casino'},
    { pathName: "lucky-7-A.png", section: "allGames" ,name:'lucky-7-A' },
    { pathName: "lucky-7-B.png", section: "allGames"  ,name:'lucky-7-B'},
    { pathName: "20-20-teenpati.png", section: "allGames"  ,name:'20-20-teenpati'},
    { pathName: "cricket.png", section: "games" ,name:'Cricket'  },
    { pathName: "football.svg", section: "games",name:'Football'  },
    { pathName: "poker-cards.svg", section: "games" ,name:'casino' },
    { pathName: "tennis.png", section: "games" ,name:'Tennis'  },



    { pathName: "NewBanner1.jpg", section: "latestGames" , },
    { pathName: "NewBanner2.jpg", section: "latestGames" , },
    { pathName: "NewBanner3.jpg", section: "latestGames" , },
    { pathName: "NewBanner4.jpg", section: "latestGames" , },
    { pathName: "NewBanner5.jpg", section: "latestGames" , },






  ];


  export const dashboardImages= [
    { pathName: "1-1.jpg" },
    { pathName: "football 3.jpg" },
    { pathName: "IMG-20240411-WA0058.jpg" },
    { pathName: "IMG-20240419-WA0021.jpg" },
    { pathName: "IMG-20250131-WA0108.jpg" },
    { pathName: "IMG-20250131-WA0109.jpg" },
    { pathName: "IMG-20250131-WA0110.jpg" },
    { pathName: "New Banner 3(1).jpg" },
    { pathName: "Tennis (1).jpg" },
    { pathName: "Tennis.jpg" },
    
    
    
  ]
  export const SPORTSCONSTANT =[
    {
        text: 'Cricket',
        count: "4",
    },
    {
        text: 'Tennis',
        count: "2",
    },
    {
        text: 'Politics',
        count: "2003",
    },
    {
        text: 'Football',
        count: "1",
    },
    {
        text: 'Kabaddi',
        count: "0",
    },
    

]
import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { CgProfile } from "react-icons/cg";
import { FaRegEye, FaRegEyeSlash, FaUsersRectangle } from "react-icons/fa6";
import { IoMdSettings } from 'react-icons/io';
import { MdPassword } from 'react-icons/md';
import { TiMinus, TiTick } from 'react-icons/ti';
import ProfileModal from '../component/ProfileModal/ProfileModal';
import CustomPagination from '../component/CustomPagination/CustomPagination';
import { apiCall } from '../middelware/Http';
import { domainName } from '../middelware/global';
import { message } from 'antd';
import { primaryColor } from './Home/HomeJson';


const Profile = () => {
    const userData = JSON.parse(localStorage.getItem(`user_info_${domainName}`))
    const [passwords, setPasswords] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
    });
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});

   
    
    const [passwordConditions, setPasswordConditions] = useState({
        hasMinLength: false,
        hasMinLetters: false,
        hasDigit: false,
    });
    const getConditionClass = (condition) => (condition ? 'text-green-500' : 'text-red-500');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
        
    };
    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
        setPasswordConditions({
            hasMinLength: value.length >= 8, // At least 1 character
            hasMinLetters: /[a-zA-Z]/.test(value) && value.replace(/[^a-zA-Z]/g, "").length >= 1, // At least 3 letters
            hasDigit: /\d/.test(value), // At least 1 digit
        });
    };

    // const handleSubmit = async () => {
    //     const newErrors = {};

    //     if (!passwords.oldPassword) {
    //         newErrors.oldPassword = "Old Password is required.";
    //     }
    //     if (!passwords.password) {
    //         newErrors.password = "New Password is required.";
    //     }
    //     if (!passwords.confirmPassword) {
    //         newErrors.confirmPassword = "Confirm Password is required.";
    //     } else if (passwords.password !== passwords.confirmPassword) {
    //         newErrors.confirmPassword = "New Password and Confirm Password must match.";
    //     }

    //     setErrors(newErrors);


    //     if (Object.keys(newErrors).length === 0) {
    //         const result = await apiCall("PATCH", "user/updateUserPassword", passwords);
    //         console.log(result, "ghjghgjhjghgjgjjg");
    //         if(result){

    //         }
    //     }
    // };

    const handleSubmit = async () => {
        const newErrors = {};

        if (!passwords.oldPassword) {
            newErrors.oldPassword = "Old Password is required.";
        }
        if (!passwords.password) {
            newErrors.password = "New Password is required.";
        }
        if (!passwords.confirmPassword) {
            newErrors.confirmPassword = "Confirm Password is required.";
        } else if (passwords.password !== passwords.confirmPassword) {
            newErrors.confirmPassword = "New Password and Confirm Password must match.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const result = await apiCall("PATCH", "user/updateUserPassword", passwords);
                console.log(result, "Password updated successfully");

                if (result) {
                    message.success(result?.message)
                    setTimeout(() => {
                        localStorage.clear()
                        window.location.href = '/dashboard'
                    }, 1000)

                }
            } catch (error) {
                message.error(error?.data?.message)
                console.error("Error updating password:", error); // Log error here
                // You can also set an error state to show user-friendly messages
                setErrors((prev) => ({ ...prev, api: "An error occurred while updating the password." }));
            }
        }
    };


    const [isToggled, setIsToggled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempToggle, setTempToggle] = useState(false);

    const handleToggleClick = () => {
        setTempToggle(isToggled);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = () => {
        setIsToggled(!tempToggle);
        setIsModalOpen(false);
    };

    return (
        <div className=" text-white min-h-screen ">
            <div className={`w-full text-[12px] text-white p-1.5 font-bold bg-${primaryColor}`}>
                Change Password

            </div>
            {/* <div className=" mx-auto">
                <div className="flex items-start border-2 border-secondary p-6 rounded-lg mb-6 transition-colors relative" >
                    <div className='absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2'>
                        <div className="text-xl "><CgProfile />
                        </div>
                        <h2 className="text-md font-semibold">User Details</h2>

                    </div>
                    <div className="">
                        <p className="text-[15px] text-white leading-relaxed flex justify-center items-center space-x-2">
                            <span className='text-button'><FaUsersRectangle />
                            </span>
                            <span className='font-bold'> User Name</span></p>
                        <p className="text-[15px] text-white leading-relaxed flex justify-center items-center ml-4 space-x-2">

                            <span className='font-bold ml-4'>{` ${userData?.username} [${userData?.name}]`} </span></p>
                    </div>
                </div>
            </div> */}
            <div className=" mx-auto sm:px-3 md:px-6 px-4">
                
                    {/* <div className="absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-start items-center bg-main px-2">
                        <div className="text-xl">
                            <MdPassword />
                        </div>
                        <h2 className="text-md font-semibold">Change Password</h2>
                    </div> */}

                    <div className=" w-full">
                        <div>
                            <label className="block text-gray-700 text-[12px]  mb-1.5 mt-2">Old Password</label>
                            <div className="relative">
                                <input
                                    type={showOldPassword ? "text" : "password"}
                
                                    name="oldPassword"
                                    value={passwords.oldPassword}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-[4px] shadow-inner text-black rounded-[5px] border border-gray-300  focus:outline-none "
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-2 flex items-center text-gray-700"
                                    onClick={() => setShowOldPassword((prev) => !prev)}
                                >
                                    {showOldPassword ? (
                                        <FaRegEye  size={19}/>  
                                    ) : (
                                        
                                        <FaRegEyeSlash size={19}  className='' />
                                    )}
                                </button>
                            </div>
                            {errors.oldPassword && (
                                <p className="text-red-500 text-sm mt-1">{errors.oldPassword}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-gray-700 text-[12px] mb-1.5 mt-5">New Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                   
                                    name="password"
                                    value={passwords.password}
                                    onChange={handleNewInputChange}
                                    className="w-full px-4 py-[4px] shadow-inner text-black rounded-[5px] border border-gray-300  focus:outline-none"
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-2 flex items-center text-gray-700"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? (
                                       <FaRegEye  size={19}/>  
                                    ) : (
                                        
                                        <FaRegEyeSlash size={19}  className='' />
                                    )}
                                </button>
                            </div>
                            <div className='flex flex-col mt-1 text-[rgb(250,35,35)] gap-0.5 text-[12px] font-bold'>
        <div className={`flex flex-row gap-1 ${getConditionClass(passwordConditions.hasMinLength)}`}>
            <TiTick size={20} /> At least 8 Character
        </div>
        <div className={`flex flex-row gap-1 ${getConditionClass(passwordConditions.hasMinLetters)}`}>
            <TiTick size={20} /> At least 1 Letters
        </div>
        <div className={`flex flex-row gap-1 ${getConditionClass(passwordConditions.hasDigit)}`}>
            <TiTick size={20} /> At least 1 Digit
        </div>
    </div>
                            {errors.password && (
                                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-gray-700 text-[12px]  mb-1.5 mt-5">
                                Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                   
                                    name="confirmPassword"
                                    value={passwords.confirmPassword}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-[4px] shadow-inner text-black rounded-[5px] border border-gray-300  focus:outline-none "
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-2 flex items-center text-gray-700"
                                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                                >
                                    {showConfirmPassword ? (
                                        <FaRegEye  size={19}/>  
                                    ) : (
                                        
                                        <FaRegEyeSlash size={19}  className='' />
                                    )}
                                </button>
                            </div>
                            {errors.confirmPassword && (
                                <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-start mt-6">
                        <button
                            onClick={handleSubmit}
                            className=" h-[32px] text-[14px]  shadow-white  px-[20px]   py-1 border-[2px] rounded-[3px] flex justify-center items-center whitespace-nowrap text-white   border-[rgb(87,87,87)]
                         bg-gradient-to-t from-[#545454] to-[rgb(110,109,107)]  bg-[rgb(110,109,107)]  "
                        >
                           <span className=''> Save</span>
                        </button>
                    </div>
             
            </div>
            {/* <div className=" mx-auto">
                <div className="flex items-start border-2 border-secondary p-6 rounded-lg mb-6 transition-colors relative" >
                    <div className='absolute -top-[16px] space-x-2 text-[#7b7b7b] max-w-[85%] flex justify-satrt items-center bg-main px-2'>
                        <div className="text-xl "><IoMdSettings />
                        </div>
                        <h2 className="text-md font-semibold">Manage Bank Account</h2>

                    </div>

                    <div class="overflow-x-auto w-full">
                        <table class="w-full text-sm text-left text-white bg-main border border-secondary capitalize">
                            <thead class="text-xs uppercase bg-[#404040]">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        cardHolder Name
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        account No
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        iban
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-main border-b-[1px] border-secondary">
                                    <th scope="row" class="px-6 py-4 font-medium  whitespace-nowrap">
                                        Shakti Kumar
                                    </th>
                                    <td class="px-6 py-4">
                                        1441001700307646
                                    </td>
                                    <td class="px-6 py-4">
                                        PUNB180010
                                    </td>
                                    <td class="px-6 py-4 flex justify-satrt items-center space-x-2 ">
                                        <button
                                            onClick={handleToggleClick}
                                            className={`w-8 h-3 flex items-center rounded-full transition-colors duration-300 ${isToggled ? "bg-button" : "bg-gray-400"}`}>
                                            <div className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${isToggled ? "translate-x-4" : "translate-x-0"}`}>
                                                <span className="flex items-center justify-center pt-0.5 text-main">
                                                    {isToggled ?
                                                        <><AiOutlineCheck /></>
                                                        : <> <TiMinus />
                                                        </>}
                                                </span>
                                            </div>
                                        </button>
                                        <span
                                            className={`font-bold ${isToggled ? "text-green-600" : "text-red-600"
                                                }`}
                                        >
                                            {isToggled ? "Activated" : "Deactivated"}
                                        </span>

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <CustomPagination />
                    </div>
                </div>

            </div> */}
            {isModalOpen && (
                <ProfileModal handleChange={handleChange} handleCancel={handleCancel} isToggled={isToggled} />
            )}
        </div>
    );
};

export default Profile;
import React from "react";
import {useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";



function IntCasino() {
    const sliderImage = [
        { imgs: "/assets/SlotGamesImages/img1.png", title: "img1", gameId: 900000 },
        { imgs: "/assets/SlotGamesImages/img2.png", title: "img2", gameId: 100001 },
        { imgs: "/assets/SlotGamesImages/img3.jpg", title: "img3", gameId: 100000 },
        { imgs: "/assets/SlotGamesImages/img5.jpg", title: "img5", gameId: 600113 },
        { imgs: "/assets/SlotGamesImages/img6.jpg", title: "img6", gameId: 500001 },
        { imgs: "/assets/SlotGamesImages/img4.jpeg", title: "img4", gameId: 201206 },
        { imgs: "/assets/SlotGamesImages/img7.png", title: "img7", gameId: 400000 },
        { imgs: "/assets/SlotGamesImages/img8.png", title: "img8", gameId: 600000 },

    ];
    const navigate = useNavigate();

    const handalClickNavigate = (url) => {
        localStorage.getItem("token")
        ?  navigate('/iframe-casino/' + url)
        : localStorage.setItem("unauthorized", true);

    }

    return (
        <>
         <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
            {sliderImage.map((item, index) => (
                            <div key={index} className="">
                                <div onClick={() => handalClickNavigate(item.gameId)}>
                                    <img src={item.imgs} alt={item.title} className='w-[500px] lg:w-[480px] rounded-md border-2 border-button border-solid' />
                                </div>
                            </div>
                        ))}
                    </div>
        </>
    )
}

export default IntCasino;


import React from 'react';

export default function RoundedTabBmx(props) {
  return (
    <div className="bg-[#24e6a4] white-text text-[14px] font-[500] tracking-normal p-2 flex justify-between items-center">
      <span>
        Last Result
      </span>
      <span className="cursor-pointer hover:underline">View All</span>
    </div>
  );
}
import { Carousel } from "antd"

function InplaySlider() {
    const crouselJson = [
        {
            section: "banner",
            name: "Banner 1",
            pathName: "slider-1.jpeg",
        },
        {
            section: "banner",
            name: "Banner 2",
            pathName: "slider-2.jpeg",
        },
        {
            section: "banner",
            name: "Banner 3",
            pathName: "slider-3.jpeg",
        },
        {
            section: "banner",
            name: "Banner 4",
            pathName: "slider-4.jpeg",
        },
        {
            section: "banner",
            name: "Banner 5",
            pathName: "slider-5.jpeg",
        },

    ]
    return (
        <div>
            <div className="w-full  h-full ">
        {crouselJson && crouselJson.length > 0 ? (
          <Carousel
            autoplay
            dots={false} // You can switch to `false` if you prefer no dots
            speed={500}
            autoplaySpeed={1500}
            pauseOnHover={false} // Pause carousel on hover
            easing="ease-in-out" // Smooth easing for transitions
            arrows={false} // Show arrows for navigation
          >
            {crouselJson.filter((image) => image.section === "banner").map(
              (game, index) => (
                <div key={index} className="w-full">
                  <img
                    src={`/winjaImages/banner/${game.pathName}`}
                    alt={game.name || `Slide ${index + 1}`}
                    className="w-full h-full object-cover  shadow-md"
                    // className="w-full h-[140px] sm:h-[180px] md:h-[200px] lg:h-[300px] xl:h-[450px] object-cover  shadow-md"
                    loading="lazy"
                  />
                </div>
              )
            )}
          </Carousel>
        ) : (
          <div className="text-center text-white">No images available.</div>
        )}
      </div>
        </div>
    )
}

export default InplaySlider

import axios from "axios";

let domainSetting = (localStorage.getItem('clientdomainSetting') && localStorage.getItem('clientdomainSetting') != "undefined" && localStorage.getItem('clientdomainSetting') != "null" ) ?  JSON.parse(localStorage.getItem('clientdomainSetting')) : null;

export const baseUrl = {


    // BACKEND_URL: 'https://api.reddyplus100.com/v1/',
    BACKEND_URL: 'https://api.bsf7.com/v1/',
    SOCKET_URL: 'https://socket.trovetown.co/',
    // BASE_IMAGE_DOMAIN: 'https://powerplay247.in/images1',

};


function authHeader() {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;


    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}

export const apiCall = async (method, path, payload) => {

    try {
        const response = await axios({
            method,
            url: baseUrl.BACKEND_URL + path,
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
        });

        return response.data;
    } catch (error) {
        if (Number(error?.response?.data?.code) === 3 || Number(error?.response?.status) === 401) {
            localStorage.clear();
            window.location.href = '/';
        } else if (error.response) {
            throw error.response;
        } else if (error.request) {

            throw error.request;
        } else {
            console.error(error, "Error occurred during request setup");
            throw new Error(error.message);
        }
    }
};

export const apiCallForm = async (method, path, payload) => {

    try {
        const response = await axios({
            method,
            url: baseUrl.BACKEND_URL + path,
            data: payload,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader(),
            },
        });

        return response.data;
    } catch (error) {
    
        
        if (Number(error?.response?.data?.code) === 3 || Number(error?.response?.status) === 401) {
            localStorage.clear();
            window.location.href = '/';
            // throw error.response
        } else if (error.response) {
            throw error.response;
        } else if (error.request) {
            throw new Error('No response received from the server');
        } else {
            console.error(error, "Error occurred during request setup");
            throw new Error(error.message);
        }
    }
};

export const httpPostFormData = async (url, data, isNotify) => {
    try {
        const result = await axios({
            method: "POST",
            url: baseUrl.BACKEND_URL + url,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });


        // await invalidToken(result);

        if (result.data) {
            if (result.data.error && isNotify) {
                // error(result.message)
                //alert(result.data.message)
            }
            else if (isNotify && !result.data.error) {
                // success(result.message)
                alert(result.data.message)
            }
            return result.data
        } else {
            return false
        }
    } catch (err) {
        console.error(err);
        // toast.error(err.response.data.message);
        if (err.request.status) {
            // invalidHeadres(err.request.status);
        }
    }
};
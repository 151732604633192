import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "antd";
import { useSelector } from "react-redux";
import { baseUrl } from "../middelware/Http";
import { httpPost } from "../middelware/CasinoMiddleware/Http";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Login from "../component/login/Login";

function SignIn({ isSignUpOpen, setIsSignUpOpen }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("password");
  const [inputFocused, setInputFocused] = useState(false);
  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    username: "",
    password: "",
    referralCode: ""
  });
  const closeModal = () => {
    setIsLoginOpen(!isLoginOpen);

  };
  const auth = useSelector((state) => state.auth);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let truncatedValue = value;
    if (name === "mobileNo") {
      // Truncate value to 10 digits if it exceeds
      if (value.length > 10) {
        return setErrors({
          ...errors,
          mobileNo: "Mobile number must be 10 digits",
        });
      }
      truncatedValue = value.slice(0, 10);
    }
    setUser({ ...user, [name]: truncatedValue });
    setErrors({ ...errors, [name]: "" }); // Clear errors when the input changes
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!user.mobileNo || user.mobileNo.length !== 10) {
      setErrors({ ...errors, mobileNo: "Mobile number must be 10 digits" });
      return;
    }
    if (!user.name || user.name.length < 3) {
      setErrors({ ...errors, name: "Name must be at least 3 characters long" });
      return;
    }

    // Validate password
    if (!user.password || user.password.length < 6) {
      setErrors({
        ...errors,
        password:
          "Password must be at least 6 characters long and must be in the format: Ab1234",
      });
      return;
    }
    // Check for uppercase letters
    if (!/[A-Z]/.test(user.password)) {
      setErrors({ ...errors, password: "Password must contain at least one uppercase letter" });
      return;
    }
    // Check for lowercase letters
    if (!/[a-z]/.test(user.password)) {
      setErrors({ ...errors, password: "Password must contain at least one lowercase letter" });
      return;
    }
    // Check for numbers
    if (!/\d/.test(user.password)) {
      setErrors({ ...errors, password: "Password must contain at least one digit" });
      return;
    }

    // Check for special characters
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(user.password)) {
      setErrors({ ...errors, password: "Password must contain at least one special character" });
      return;
    }

    setLoading(true);
    try {
      const loginDetails = {
        domainUrl: window.location.origin,
        name: user.name,
        username: user.username,
        mobileNo: user.mobileNo,
        password: user.password,
      };
      if (user.referralCode) {
        loginDetails.referralCode = user.referralCode;
      }
      const response = await httpPost("website/registerClient", loginDetails);

      if (response) {
        setUser({
          name: "",
          username: "",
          mobileNo: "",
          password: "",
          referralCode: ""
        });
        navigate("/");
        message.success(response?.message);
      } else {
        message.error("Registration failed. Please check your details.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred during registration. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); // password Hide and Show Icons
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handalClickNavigate = (url) => {
    navigate(url);
  };

  let domainSetting = JSON.parse(localStorage.getItem("clientdomainSetting"));
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleOnSubmit(e);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex justify-center items-center bg-[rgba(0,0,0,0.9)] " 
        onClick={()=>
          setIsSignUpOpen(false)
        }
      >
        <div className="bg-white rounded-lg p-6 w-full sm:w-2/5 mx-2" onClick={(e)=> e.stopPropagation()}>
          <div className="flex items-center justify-center mb-4  px-6 py-4">
            <img src="/assets/logo.png" alt="power play247" className="h-10" />
            {/* <button
              onClick={() => setIsSignUpOpen(false)}
              className="text-white bg-gray-700/70 w-9 h-9 rounded-full flex justify-center items-center  hover:text-white text-4xl"
            >
              &times;
            </button> */}
          </div>

          <div className="space-y-4">
            {/* Form Inputs (Same as before) */}
            <div>
              <input
                type="text"
                name="name"
                value={user.name}
                onChange={handleOnChange}
                placeholder="Enter Name"
                className="w-full p-2 border border-blue-950 focus:border focus:border-blue-950  outline-none rounded"
              />
              {errors.name && <div className="text-red-500">{errors.name}</div>}
            </div>

            <div>
              <input
                type="text"
                name="username"
                value={user.username}
                onChange={handleOnChange}
                placeholder="Enter Username"
                className="w-full p-2  border border-blue-950 focus:border focus:border-blue-950  outline-none rounded"
              />
              {errors.username && <div className="text-red-500">{errors.username}</div>}
            </div>

            <div>
              <input
                type="tel"
                name="mobileNo"
                value={user.mobileNo}
                onChange={handleOnChange}
                placeholder="Enter Mobile No (10 digits)"
                className="w-full p-2 focus:border focus:border-blue-950  outline-none rounded border border-blue-950"
              />
              {errors.mobileNo && <div className="text-red-500">{errors.mobileNo}</div>}
            </div>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={user.password}
                onChange={handleOnChange}
                placeholder="Password"
                className="w-full p-2 focus:border focus:border-blue-950  outline-none rounded border border-blue-950"
              />
              <button
                onClick={toggleShowPassword}
                className="absolute text-white right-3 top-2 text-lg"
              >
                {showPassword ? <BsFillEyeSlashFill /> : <BsEyeFill />}
              </button>
            </div>
            {errors.password && <div className="text-red-500">{errors.password}</div>}

            <div className="relative">
              <input
                type="text"
                name="referralCode"
                id="referralCode"
                value={user.referralCode}
                onChange={handleOnChange}
                onKeyPress={handleKeyPress}
                placeholder="Referral Code"
                className="w-full p-2 focus:border focus:border-blue-950  outline-none rounded border border-blue-950"
              />
            
            </div>
            {errors.referralCode && <div className="text-red-500 text-sm mb-1">{errors.referralCode}</div>}

            <button
              onClick={handleOnSubmit}
              className={`w-full py-2 bg-blue-950 text-white rounded mt-4 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={loading}
            >
              {loading ? "Loading..." : "Register"}
            </button>
            <div className="flex items-center w-full justify-center">
              <span className="text-sm text-center">
                 Don't have an Account ?{" "}
                  <span className="text-blue-950 underline"   
            //       onClick={() => {
            //     setIsLoginOpen(true);
    
            // }
            
            // }
            >
                    SignIn
                  </span>{" "}
                 
                </span>
              </div>
              {isLoginOpen && <Login isOpen={isLoginOpen} closeModal={closeModal} />}

          </div>
        </div>
      </div>

    </>
  );
}

export default SignIn;



import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetList } from "../redux/_reducers/_user_reducers";
import moment from "moment";
import Loader from "../component/Loader/Loader";
import { primaryColor } from "./Home/HomeJson";
import UnsettalBets from "../component/unsettalBet/UnsettalBets";

const BetHistory = () => {
  const [userBetHistory, setUserBetHistory] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [isFetch, setIsFetch] = useState(false);
  const { sportBetList, loading } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedValue, setSelectedValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;
  const [isPast, setisPast] = useState(false);

  const dispatch = useDispatch();



  useEffect(() => {
    if (sportBetList) {
      const casinoBetData = sportBetList?.casinoBetData;
      let filteredCasinoData = [];
      if (Array.isArray(casinoBetData)) {
        casinoBetData.forEach((casinoData) => {
          filteredCasinoData.push({
            userInfo: casinoData?.userInfo,
            description: casinoData?.subProviderName,
            selection: "Casino",
            selectionId: casinoData?.selectionId,
            stack: casinoData?.actualBetAmount,
            odds: casinoData?.odds,
            Type: "Casino Odds",
            createdAt: casinoData?.createdAt,
            profit: casinoData?.debitAmount,
            loss: casinoData?.creditAmount,
            type: casinoData?.type,
            isDeclare: casinoData?.isDeclare,
            ip: casinoData?.ip,
            run: "",
            oddsType: casinoData?.oddsType,
            positionInfo: casinoData?.positionInfo,
            providerName: casinoData?.providerName,

            // "betType": "Casino",
            // "actualBetAmount": 20,
            // "debitAmount": 40,
            // "creditAmount": 0,
            // "rollbackAmount": 0,
            // "rollbackReason": "",
            // "gameId": "201206",
            // "gameName": "AVIATOR",
            // "providerName": "DC",
            // "subProviderName": "Spribe",
            // "operatorId": "",
            // "transactionId": "841d77e2-ea49-54b4-a8fe-513b3b49a61d",
            // "roundId": "fcfbec36-18d3-5609-a993-a821fd5779d1",
            // "isDeclare": true,
            // "isDeleted": false,
            // "userInfo": {
            //     "username": "shubham",
            //     "name": "shubham"
            // },
            // "ip": null,
            // "createdAt": "2025-01-13T07:51:37.471Z",
            // "id": "6784c6094a27e543220c8c96"
          });
        });
      }

      const fancyBetData = sportBetList?.fancyBetData;
      let filteredFancyData = [];
      if (Array.isArray(fancyBetData)) {
        fancyBetData.forEach((fancyData) => {
          filteredFancyData.push({
            userInfo: fancyData?.userInfo,
            description: fancyData?.sessionName,
            selection: "Fancy",
            selectionId: fancyData?.selectionId,
            stack: fancyData?.amount,
            odds: fancyData?.odds,
            run: fancyData?.run,
            Type: "Fancy Odds",
            createdAt: fancyData?.createdAt,
            profit: fancyData?.profit,
            loss: fancyData?.loss,
            type: fancyData?.type,
            isDeclare: fancyData?.isDeclare,
            ip: fancyData?.ip,
            oddsType: fancyData?.oddsType,
            positionInfo: fancyData?.positionInfo,
          });
        });
      }

      const oddsBetData = sportBetList?.oddsBetData;
      let filteredOddsData = [];
      if (Array.isArray(oddsBetData)) {
        oddsBetData.forEach((oddsData) => {
          filteredOddsData.push({
            userInfo: oddsData?.userInfo,
            description: oddsData?.teamName,
            selection: oddsData?.oddsType,
            selectionId: oddsData?.selectionId,
            stack: oddsData?.amount,
            odds: oddsData?.odds,
            Type: "Match Odds",
            createdAt: oddsData?.createdAt,
            profit: oddsData?.profit,
            loss: oddsData?.loss,
            type: oddsData?.type,
            isDeclare: oddsData?.isDeclare,
            ip: oddsData?.ip,
            run: "",
            oddsType: oddsData?.oddsType,
            positionInfo: oddsData?.positionInfo,
          });
        });
      }
      setUserBetHistory([
        ...filteredCasinoData,
        ...filteredFancyData,
        ...filteredOddsData,
      ]);
    }
  }, [sportBetList, currentPage]);

  useEffect(() => {
    userBetHistory?.forEach((obj) => {
      let { selectionId, type, positionInfo } = obj;
      let profit = 0;
      let loss = 0;

      if (type === "L") {
        let keys = Object?.keys(positionInfo);
        keys.forEach((key) => {
          if (key === selectionId) {
            profit += positionInfo[key];
          } else {
            loss += positionInfo[key];
          }
        });
      } else if (type === "K") {
        let keys = Object?.keys(positionInfo);
        keys.forEach((key) => {
          if (key === selectionId) {
            loss += positionInfo[key];
          } else {
            profit += positionInfo[key];
          }
        });
      } else if (type === "N") {
        profit = obj.profit;
        loss = obj.loss;
      } else if (type === "Y") {
        profit = obj.profit;
        loss = obj.loss;
      }
      userBetHistory.push({
        ...obj,
        profit: profit,
        loss: loss,
      });
    });
    setData(userBetHistory);
  }, [userBetHistory]);

  useEffect(() => {
    getBetLists();
  }, [currentPage, isPast]);

  const getBetLists = async () => {
    const reqData = !isPast ? {
      "fancyBet": true,
      "oddsBet": true,
      "isDeclare": false,
      "diamondBet": true,
      "isClientExposure": true
  } :{
      fancyBet: true,
      oddsBet: true,
      casinoBet: true,
      isDeleted: false,
     
    };
    dispatch(getBetList(reqData));
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const renderSortArrows = (key) => (
    <span className="flex flex-col items-center -space-y-1.5">
      <button
        onClick={() => handleSort(key)}
        className={`text-xs ${
          sortConfig.key === key && sortConfig.direction === "ascending"
            ? "opacity-100 text-gray-800"
            : "opacity-50 text-gray-400"
        }`}
      >
        ▲
      </button>
      <button
        onClick={() => handleSort(key)}
        className={`text-xs ${
          sortConfig.key === key && sortConfig.direction === "descending"
            ? "opacity-100 text-gray-800"
            : "opacity-50 text-gray-400"
        }`}
      >
        ▼
      </button>
    </span>
  );

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // const handleBetsChange = (e) => {
  //     setBetType(e.target.value);
  // };
  // const handleSportChange = (e) => {
  //     setSportType(e.target.value);
  // };

  const handleSubmit = () => {
    getBetList();
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    // setPayloadData({
    //     ...payloadData,
    //     statementFor: e.target.value,
    // });
    const reqData = {
      page: currentPage,
      size: e.target.value,
      fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : undefined,
      toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : undefined,
    };
    dispatch(getBetList(reqData));
  };

  const onPaginationChange = (page) => {
    setCurrentPage(page);
  };



  return loading ? (
    <Loader loaderTrue={loading} />
  ) : (
    <>
      <div className="w-full rounded-md shadow-md md:!mt-0 mt-2">
        <div
          className={`w-full text-[12px] text-white p-1.5 font-bold bg-${primaryColor}`}
        >
          My Bets
        </div>

        <div className="flex ">
          <div
            className={`text-[12px] flex justify-center font-semibold items-center  mt-3 ml-3 mb-3 mr-[1px] w-[105px] py-[2.5px] rounded-sm border border-[#cccc] ${
              !isPast ? "bg-[#24e6a4] text-white" : "text-[#3B5160]"
            }`}
            onClick={() => setisPast(false)}
          >
            Current
          </div>
          <div
            className={`text-[12px] flex justify-center font-semibold items-center  mt-3 mb-3 w-[105px] py-[2.5px] rounded-sm border border-[#cccc] ${
               !isPast ? "text-[#3B5160]" : "bg-[#24e6a4] text-white"
            }`}
            onClick={() => setisPast(true)}
          >
            Past
          </div>
        </div>
      </div>
      {!isPast && (
        <div>
          <UnsettalBets sportBetList={!isPast ? sportBetList : []} />
        </div>
      )} 
      {isPast && (
        <div className="w-full flex flex-col md:gap-4 gap-2 px-2">
          <div className="w-full rounded-md shadow-md">
            <div className="w-full bg-white">
              <div className="lg:flex gap-4 w-full mt-2">
                <div className="w-full flex-1 overflow-auto">
                  <table className="w-full table table-bordered table-sm text-[#212529]">
                    <thead className="text-[12px] text-[#243a48] bg-[#E4E4E4] border border-white">
                      <tr className="border border-white">
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[120px]">
                          <div className="flex justify-between items-center gap-1">
                            Placed {renderSortArrows("createdAt")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[200px]">
                          <div className="flex justify-between items-center gap-1">
                            Description {renderSortArrows("")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">
                          <div className="flex justify-between items-center gap-1">
                            Section Type {renderSortArrows("type")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
                          <div className="flex justify-between items-center gap-1">
                            B/L {renderSortArrows("")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
                          <div className="flex justify-between items-center gap-1">
                            Odds {renderSortArrows("odds")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[100px]">
                          <div className="flex justify-between items-center gap-1">
                            Stake{renderSortArrows("stack")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">
                          {" "}
                          <div className="flex justify-between items-center gap-1">
                            Bet Status {renderSortArrows("")}
                          </div>
                        </th>
                        <th className="px-1 py-2 text-start text-[#243a48] border-[0.5px] border-white whitespace-nowrap w-[150px]">
                          {" "}
                          <div className="flex justify-between items-center gap-1">
                            Exp/profit {renderSortArrows("")}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm bg-white">
                      {data && data.length > 0 ? (
                        data.map((betList, index, row) => (
                          <React.Fragment key={index}>
                            <tr
                              key={index}
                              className="bg-white border-b-[1px]  border-secondary uppercase"
                            >
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd] whitespace-nowrap">
                                {moment(betList?.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )}
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap ">
                                {betList?.description}
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">
                                {betList?.type === "N"
                                  ? "No"
                                  : betList?.type === "Y"
                                  ? "Yes"
                                  : betList?.type === "L"
                                  ? "Lagai"
                                  : betList?.type === "K"
                                  ? "Khai"
                                  : ""}
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">
                                -
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">
                                {betList?.odds}
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">
                                {betList?.stack}
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">
                                -
                              </td>
                              <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd]  whitespace-nowrap">
                                {betList?.profit.toFixed(2)}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center p-2 bg-[#dee2e6]/50 font-semibold"
                          >
                            No Bet List Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <CustomPagination
                          totalCount={sportBetList?.totalCount}
                          pageSize={pageSize}
                          currentPage={currentPage}
                          onChange={onPaginationChange}
                      /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BetHistory;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getuserLedger } from "../redux/_reducers/_user_reducers";
import CustomPagination from "../component/CustomPagination/CustomPagination";
import { domainName } from "../middelware/global";
import { FaCircleInfo } from "react-icons/fa6";
import { primaryColor } from "./Home/HomeJson";

function ProfitLoss() {
  const [payloadData, setPayloadData] = useState({
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    statementFor: "",
  });
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const dispatch = useDispatch();
  const { userLedgerData } = useSelector((state) => state.user);
  const ledgerData = userLedgerData?.ledgerData;
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 5;
  const downlineUserId = JSON.parse(
    localStorage.getItem(`user_info_${domainName}`)
  )?.userId;
  const [modalData, setModalData] = useState({
    status: false,
    id: "",
  });

  useEffect(() => {
    // const reqData = {
    //     "pageNo": pageNumber,
    //     "size": pageSize
    // }
    // dispatch(getuserLedger(reqData));
    handleSubmit();
  }, [dispatch, pageNumber]);

  const groupedData = {};
  ledgerData?.forEach((item) => {
    const dateKey = moment(item.date).format("YYYY-MM-DD");
    groupedData[dateKey] = groupedData[dateKey] || [];
    groupedData[dateKey].push(item);
  });

  let totalAmount = 0;
  ledgerData?.map((data) => {
    totalAmount += data.amount;
  });
  let balance = 0;
  let showAmount = 0;
  let finalData = ledgerData?.map((data) => {
    balance = totalAmount - showAmount;
    showAmount += data.amount;
    return {
      amount: data.amount,
      date: data.date,
      balance: balance,
      ledgerType: data.ledgerType,
      remark: data.remark,
      eventName: data.eventName,
      statementFor: data.statementFor,
      isComm: data.isComm,
      marketId: data.marketId,
      createdAt: data.createdAt,
      selectionId: data.selectionId || "0",
    };
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayloadData({
      ...payloadData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (ledgerData?.length) {
      setData(finalData);
    }
  }, [ledgerData]);


  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...finalData || []].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const renderSortArrows = (key) => (
    <span className="flex flex-col  justify-center  gap-0  items-center">
      <button
        onClick={() => handleSort(key)}
        className={`text-[9px] h-1 ${sortConfig.key === key && sortConfig.direction === "ascending"
          ? "opacity-100 text-gray-800"
          : "opacity-50 text-gray-400"
          }`}
      >
        ▲
      </button>
      <button
        onClick={() => handleSort(key)}
        className={`text-[9px] ${sortConfig.key === key && sortConfig.direction === "descending"
          ? "opacity-0 text-gray-800"
          : "opacity-0 text-gray-400"
          }`}
      >
        ▼
      </button>
    </span>
  );

  // Handle change for the select dropdown
  //   const handleSelectChange = (e) => {
  //     setPayloadData({
  //             "downlineUserId": downlineUserId
  //     });
  //     const reqData = {
  //       fromDate: payloadData.fromDate,
  //       toDate: payloadData.toDate,
  //       "pageNo": pageNumber,
  //       "size": pageSize,
  //       statementFor: e.target.value,
  //     };
  //     dispatch(getuserLedger(reqData));
  //   };
  const handleReset = () => {
    setPayloadData({
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),

    });
    handleSubmit()
  };


  const handleSubmit = () => {
    const reqData = {
      downlineUserId: downlineUserId,
    };
    dispatch(getuserLedger(reqData));
  };

  const openModal = (id) => {
    setModalData({ status: true, id });
  };

  const closeModal = () => {
    setModalData({ status: false, id: "" });
  };

  return (
    <>
      {modalData.status && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 mx-2">
          <div className="bg-primary  max-w-[550px] md:min-w-[500px] min-w-[100%] rounded-2xl shadow-lg">
            <div className="flex items-center justify-between mb-4 border-b border-secondary px-6 py-4">
              <img
                src="/assets/logo.png"
                alt="power play247"
                className="h-10"
              />
              <button
                onClick={closeModal}
                className="text-white bg-gray-700/70 w-9 h-9 rounded-full flex justify-center items-center  hover:text-white text-4xl"
              >
                &times;
              </button>
            </div>
            <div className="m-2 p-2 bg-gradient-to-b from-[#84c2f1] space-y-2 font-semibold capitalize to-blue-50 rounded-lg">
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>remark</span> <span>{modalData?.id?.remark}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>date</span>{" "}
                <span>
                  {moment(modalData?.id?.date).format("DD-MM-YYYY HH:MM")}
                </span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>eventName</span> <span>{modalData?.id?.eventName}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>ledgerType</span> <span>{modalData?.id?.ledgerType}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>marketId</span> <span>{modalData?.id?.marketId}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <section className="w-full  ">
        <div className="">
          <div className="">
            <div className="">
              <div className={`w-full text-[12px] text-white p-1.5 font-bold bg-${primaryColor}`}>
                Betting Profit And Loss

              </div>
              <div className="sm:mx-[40px] mx-[10px]">
                <div>
                  <div className="  md:grid-cols-2   sm:grid-cols-1 lg:grid-cols-[1fr_1fr] grid grid-cols-1     justify-center">
                    <div className="flex  sm:flex-row flex-col sm:gap-7 gap2 ">
                      <div className="flex flex-col lg:w-[26%]  justify-start items-start  ">
                        <label className="text-[12px] ">
                          Choose from Date
                        </label>

                        <input
                          type="date"
                          name="fromDate"
                          id="fromDate"
                          value={payloadData.fromDate}
                          onChange={handleChange}
                          className="text-[12px]  px-[11px] py-[15px] bg-white h-[30px] w-full border shadow-inner  rounded-[4px]"
                        />
                      </div>
                      <div className="flex lg:w-[26%]   flex-col justify-start items-start ">
                        <label className="text-[12px]">
                          {" "}
                          Choose to Date
                        </label>

                        <input
                          type="date"
                          name="toDate"
                          id="toDate"
                          value={payloadData.toDate}
                          onChange={handleChange}
                          className="text-[12px]   px-[11px] py-[15px] bg-white h-[30px] w-full border shadow-inner  rounded-[4px]"
                        />
                      </div>
                    </div>

                    <div className="">
                      <div className="sm:m-0 mt-2  flex sm:flex-row flex-col sm:gap-14 gap-2 ">
                        {/* <div className="sm:w-[28%] w-full">
                          <div className="flex flex-col  justify-start items-start ">
                            <label className="text-[12px] ">Sports List</label>

                            <select
                              id=""
                              className="text-[12px] sm:w-full px-[11px]  bg-white h-[30px] w-full border shadow-inner  rounded-[4px]"
                              value={payloadData.statementFor}
                            >
                              <option value="">All</option>
                              <option value="profitLoss">cricket</option>
                              <option value="ACCOUNT_STATEMENT">scoccer</option>
                              <option value="ACCOUNT_STATEMENT">tennis</option>
                              <option value="ACCOUNT_STATEMENT">casino</option>
                            </select>
                          </div>
                        </div> */}
                        <div>
                          <button className="bg-gradient-to-b from-[#333] to-black rounded-[4px] min-h-[30px] mr-2 sm:ml-2" onClick={handleSubmit}>
                            <span className="text-white text-[13px] px-[19px]   py-[20px]">
                              Go
                            </span>
                          </button>
                          <button className="bg-gradient-to-b from-[#333] to-black rounded-[4px] min-h-[30px]">
                            <span className="text-white text-[13px] px-[19px]   py-[20px]" onClick={handleReset}>
                              Reset
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* 
<div>
  <button
    type="submit"
    className="bg-button mt-1 text-white text-sm font-bold px-4 py-3.5   w-full md:w-52   rounded-sm"
    onClick={handleSubmit}
  >
    Load Report
  </button>
</div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 space-y-1">
              {(
                <>
                  <div className="overflow-x-auto w-full">
                    <table className="w-full text-sm text-left text-white  border-secondary capitalize">
                      <thead className="text-[12px] uppercase shadow-md  bg-[rgba(219,217,217)] border border-white">
                        <tr className="">
                          <th
                            scope="col "
                            className="px-2.5 w-[28%] py-1.5 text-[#243a48]  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center gap-2">
                              {" "}
                              Description {renderSortArrows("date")}
                            </div>
                          </th>
                          <th
                            scope="col "
                            className="px-2.5 w-[14%] py-1.5 text-[#243a48]  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center gap-2">
                              P&L{renderSortArrows("amount")}
                            </div>
                          </th>

                          <th
                            scope="col "
                            className="pl-2.5 w-[24%] py-1.5 text-[#243a48] whitespace-nowrap  border-[0.5px] border-white"
                          ><div className="flex justify-between items-center gap-2">
                              Commission{" "} {renderSortArrows("balance")}
                            </div>
                          </th>
                          <th
                            scope="col "
                            className="px-2.5 py-1.5 w-[13%] text-[#243a48]  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center gap-2">
                              Date{renderSortArrows("date")}
                            </div>
                          </th>
                          {/* <th scope="col " className="px-2.5 py-1.5 text-[#243a48]  border-[0.5px] border-white">Action </th> */}
                          {/* <th scope="col " className="px-2.5 py-1.5 text-[#243a48]  border-[0.5px] border-white"> Description</th> */}
                        </tr>
                      </thead>
                      {data?.length > 0 ? data?.map((item, index) => (
                        <tbody key={index}>
                          <tr className="bg-white border-b-[1px]  border-secondary uppercase">

                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd] whitespace-nowrap">{item.remark}</td>



                            <td
                              className={`px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd] whitespace-nowrap ${item.amount > 0
                                  ? "text-green-600"
                                  : item.amount < 0
                                    ? "text-red-600"
                                    : "text-white"
                                }`}
                            >
                              {parseFloat(item.amount)
                                .toFixed(2)
                                .replace(/\.?0+$/, "")}
                            </td>
                            <td
                              className={`px-2 text-[#333] py-2  text-[12px] font-medium whitespace-nowrap  border border-[#ddd] ${Number(item.balance) > 0
                                  ? "text-green-600"
                                  : Number(item.balance) < 0
                                    ? "text-red-600"
                                    : "text-white"
                                }`}
                            >
                              {parseFloat(item.balance)
                                .toFixed(2)
                                .replace(/\.?0+$/, "")}
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium whitespace-nowrap  border border-[#ddd] text-center space-x-2 ">
                              {moment(item.date).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )) : (
                        <tr >
                          <td colSpan={4}>
                            <div className="text-center border-2 bg-white text-black  px-2 py-3 text-[12px]  ">
                              {" "}
                              No data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                  {/* {data?.length > 0 && 
                  <div className="flex justify-start mx-2 my-2">
                    <CustomPagination
                    totalCount={userLedgerData?.totalCount}
                    pageSize={pageSize}
                    currentPage={pageNumber}
                    onChange={setPageNumber}
                  /> 

                  </div> } */}
                </>
              )
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfitLoss;

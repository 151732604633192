/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useRef } from "react";
import Login from "../login/Login";
import { FaHome, } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { FaMicrophone, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { domainName } from "../../middelware/global";
import SignIn from "../../pages/SignIn";
import { getMatchList, getUserBalance } from "../../redux/_reducers/_user_reducers";
import { login } from "../../redux/_reducers/_auth_reducers";
import { apiCall } from "../../middelware/Http";
import moment from "moment";
import { primaryColor } from "../../pages/Home/HomeJson";
import { IoMdArrowDropdown } from "react-icons/io";
import { RiBankLine } from "react-icons/ri";
function Header({ setSidebarOpen }) {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isExposerOpen, setIsExposerOpen] = useState(false);
  const [exposerData, setExposerData] = useState([])
  const activeTab = 1
  const setActiveTabCasino = 2
  const handleTabClick = (path) => {
    navigate(path)
  }
  const mobileActive = 1
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openModal = () => setIsLoginOpen(true);
  const closeModal = () => {
    setIsLoginOpen(false);
    localStorage.setItem("unauthorized", false);
  };
  const [balance, setBalance] = useState({
    coins: "",
    exposure: "",
    profitLoss: ""
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefbutton = useRef(null);
  const [localStoragelogin, setlocalStorage] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token, login_loading } = useSelector((state) => state.authentication);
  const localstorageuser = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem(`user_info_${domainName}`));
  const unauthorizedLoginModal = localStorage.getItem("unauthorized")
    ? JSON.parse(localStorage.getItem("unauthorized"))
    : false;

  useEffect(() => {
    if (token || localstorageuser) {
      setlocalStorage(true);
    }
  }, [token, localstorageuser]);
  useEffect(() => {
    if (unauthorizedLoginModal) {
      setIsLoginOpen(true);
    }
  }, [unauthorizedLoginModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !dropdownRefbutton.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {

    const initData = localStorage.getItem("matchList");
    const intervalId = setInterval(() => {
      localStorage.removeItem("matchList");
    }, 1000 * 60 * 5);

    const intervalBalId = setInterval(() => {
      dispatch(getUserBalance())
    }, 3000);

    
    if (!initData) {
      dispatch(getMatchList());
    }
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalBalId);
    };
  }, [dispatch]);


  useEffect(() => {
    setTimeout(() => {
      let Balance = JSON.parse(localStorage.getItem("clientBalance"));
      let clientExposure = JSON.parse(localStorage.getItem("clientExposure"));
      let clientProfitLoss = JSON.parse(localStorage.getItem("clientProfitLoss"));
      setBalance({
        coins: Balance,
        exposure: clientExposure,
        profitLoss: clientProfitLoss
      });
    }, 1000);
  }, [balance]);

  const handleDemoLogin = () => {
    const demoCredentials = {
      username: "C1000",
      password: "1122",
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(demoCredentials))
      .then((data) => {
        if (!data.error) {
          closeModal();
          window.location.href = "/dashboard"
          // navigate("/dashboard");
        } else {
          console.error("Demo Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Demo login request failed:", error);
      });
  };
  const handleExposerModal = async () => {

    let betReq = {
      "fancyBet": true,
      "oddsBet": true,
      // "casinoBet": true,
      "isDeclare": false,
      "diamondBet": true,
      "isClientExposure": true
    };
    const userBetHistory = await apiCall("POST", 'sports/betsList', betReq);
    if (userBetHistory && userBetHistory.data) {
      setExposerData(userBetHistory.data)

    }
    setIsExposerOpen(true)
  }

  let domainSetting = JSON.parse(localStorage.getItem("clientdomainSetting"));
  let socialMediaLink = domainSetting?.socialMedia

  const handleLogout = async () => {
    // localStorage.removeItem()
    localStorage.clear();
    setIsDropdownOpen(false);
    window.location.reload();
  }
  const [matchData, setMatchData] = useState([]);
  const matchlistLocal = localStorage.getItem("matchList")
    ? JSON.parse(localStorage.getItem("matchList"))
    : null;
  const { matchList } = useSelector((state) => state.user);
  useEffect(() => {
    let matchListData = matchlistLocal ? matchlistLocal : matchList;
    const sortedMatchList = matchListData
      ? matchListData
        .filter((match) => match.status === "INPLAY")
        .sort((a, b) =>
          moment(a.matchDate, "DD-MM-YYYY HH:mm:ss").isBefore(
            moment(b.matchDate, "DD-MM-YYYY HH:mm:ss")
          )
            ? -1
            : 1
        )
      : [];
    setMatchData(sortedMatchList);
  }, [matchList]);

  // useEffect(() => {
  //   dispatch(getUserBalance())
  // }, [])

  return (
    <>

      {/* md:static fixed top-0 left-0 */}
      <div className="w-full">
        <header className={`flex w-full top-0 left-0 md:bg-${primaryColor} bg-${primaryColor} items-center justify-between shadow md:px-1 px-1 md:p-[5px] `}>
          <div className="flex items-center justify-between gap-3  ">
            <button
              className="text-gray-200 lg:hidden m-1 "
              onClick={() => setSidebarOpen(true)}
            >
              <svg
                className=" w-[25px]"
                fill="#ffff"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
            <div className="flex items-center justify-center border-gray-700"

            >
              <img
                onClick={() => navigate("/")}
                src="/assets/logo.png"
                alt="Power Play"
                className="sm:h-[2rem] h-[1.5rem] "
              />
            </div>





          </div>

          <div className="relative flex flex-col items-center justify-center gap-0.5 rounded-md lg:mr-2 py-1">
            <>


              <div
                ref={dropdownRefbutton}
                onClick={toggleDropdown}
                className={`flex  items-center justify-between rounded-[5px] bg-${primaryColor}  px-[3px] w-full rounded-sm cursor-pointer`}
              >

                <FaUser className="text-white md:text-[12px] text-[12px] lg:text-[12px]" />
                <span className="text-white md:text-[12px] lg:text-[12px] px-[2px] sm:text-[10px] text-[9px] md:p-0 p-1">
                  {userData?.name ? userData?.name : "N/A"}
                </span>
                <IoMdArrowDropdown className="text-white" />

              </div>


              <div
                onClick={() => setIsModalOpen(!isModalOpen)}
                className={`flex flex-row items-center justify-between rounded-[5px] bg-${primaryColor} p-0.5 px-[2px] w-full rounded-sm cursor-pointer`}
              >
                <div className="flex justify-between items-center gap-2">
                  <img src="/winjaImages/DashboardIcon/coin.svg" className="h-3" alt="coin icon" />
                  <span className="text-white md:text-[12px] lg:text-[12px] px-[2px] sm:text-[10px] text-[9px] md:p-0 p-1">
                    {balance?.coins ? (Math.floor(Number(balance?.coins) * 100) / 100).toFixed(2) : 0}

                  </span>
                </div>
                <IoMdArrowDropdown className="text-white" />
              </div>

              {/* Modal */}
              {isModalOpen && (
                <div onClick={() => setIsModalOpen(false)} className="fixed inset-0  z-50 ">
                  <div onClick={(e) => e.stopPropagation()} className={`w-[120px] p-2 space-y-3 absolute right-0 top-[45px] text-[13px] rounded-md font-semibold bg-[#e0e6e6]  text-black`}>
                    <div className="p-1 bg-white  rounded-md">Credit Limit : <br /> 0</div>
                    <div className="p-1 bg-white  rounded-md">Main Balance : <br />{balance?.coins ? Number(balance?.coins).toFixed(2) : 0}</div>
                    <div className="p-1 bg-white  rounded-md"><a href="/unsettal-bet">Exposure :<br /> {balance?.exposure ? Number(balance?.exposure).toFixed(2) : 0}</a></div>
                    <div className="p-1 bg-white  rounded-md">P&L : <br /> {balance?.profitLoss ? Number(balance?.profitLoss).toFixed(2) : 0}</div>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className={`w-full bg-${primaryColor} text-white p-2 rounded-md my-2`}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}

              {/* Hidden grid section for larger screens */}
              <div className={`lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 items-center justify-center gap-1 bg-${primaryColor} p-0.5 rounded-[5px] hidden sm:hidden lg:visible md:visible`}>
                <div onClick={() => navigate('/deposit')} className="flex flex-row w-full justify-evenly animate-bgTransition items-center py-[3.5px] px-3.5 rounded-lg gap-2 mr-1">
                  <span className="text-white text-[12px]">D</span>
                  <RiBankLine className="text-white text-[9px]" />
                </div>

                <div onClick={() => navigate('/withdrawal')} className="flex flex-row w-full justify-evenly items-center animate-bgTransition px-3.5 py-[3.5px] rounded-lg gap-2">
                  <span className="text-white text-[12px]">W</span>
                  <RiBankLine className="text-white text-[9px]" />
                </div>
              </div>



              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className={`w-[220px] z-50 absolute right-0 top-[22px] rounded-md  bg-white  text-${primaryColor} `}
                >

                  {/* 
                  <div className="border-t border-b p-4 mb-6">
                    <div className="flex items-center justify-center gap-2 text-center font-semibold text-sm mb-2">
                      <BsBank /> Balance Information
                    </div>
                    <div className="text-sm flex justify-between">
                      <span>Balance:</span>
                      <span
                        className={`${balance &&
                          balance.coins &&
                          parseFloat(balance.coins) > 0
                          ? "text-green-500"
                          : "text-red-500"
                          }`}
                      >
                        {balance && balance.coins
                          ? (Math.floor(Number(balance.coins) * 100) / 100)
                          : 0}
                      </span>
                    </div>
                    <div className="text-sm flex justify-between mb-4" >
                      <span>Liability:</span>
                      <span
                        className={`${balance &&
                          balance.exposure &&
                          parseFloat(balance.exposure) > 0
                          ? "text-green-500"
                          : "text-red-500"
                          }`}
                        onClick={() => handleExposerModal()}
                      >
                        {balance && balance.exposure
                          ? (Math.floor(Number(balance.exposure) * 100) / 100)
                          : 0}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          localStorage.getItem("token")
                            ? navigate("/deposit")
                            : localStorage.setItem("unauthorized", true);

                          setIsDropdownOpen(false);
                        }}
                        className="flex-1 bg-[conic-gradient(from_0deg,#f15a24,#e4440a)] hover:bg-orange-600 text-white py-[6px] px-[12px] rounded"
                      >
                        Deposit
                      </button>
                      <button
                        onClick={() => {
                          localStorage.getItem("token")
                            ? navigate("/withdrawal")
                            : localStorage.setItem("unauthorized", true);

                          setIsDropdownOpen(false);
                        }}
                        className="flex-1 bg-[conic-gradient(from_0deg,#f15a24,#e4440a)] hover:bg-red-600 text-white py-1 px-3 rounded"
                      >
                        Withdraw
                      </button>
                    </div>

                  </div> */}

                  <div className="flex flex-row w-full justify-end p-1">
                    <div className="text-[12px] border-l-2 px-2 font-semibold">
                      GMT+5:30
                    </div>

                  </div>
                  <div className="w-full">
                    <div className="h-full  scrollbar-thin  scrollbar-thumb-white scrollbar-track-transparent">
                      <div className="w-full">
                        <div
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/profile")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >
                          <img src="/winjaImages/DashboardIcon/change-password.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">Change password</span>
                        </div>

                        <div
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/account-statement")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >
                          <img src="/winjaImages/DashboardIcon/download.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">Account Statement</span>
                        </div>
                        {/* <div className="flex items-center gap-1 text-sm px-[0.5px] py-0.5 border-b  rounded-lg cursor-pointer">
                      <FaCreditCard />
                      <span className="text-[12px]">Add Payment Methods</span>
                    </div> */}
                        <div
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/profit-loss")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >
                          <img src="/winjaImages/DashboardIcon/profit-loss-report.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">Profit & Loss</span>
                        </div>
                        <div
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/bet-history")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >
                          <img src="/winjaImages/DashboardIcon/bet-history.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">Bet History</span>
                        </div>
                        <div
                          onClick={() => {
                            // localStorage.getItem("token")
                            //   ? navigate("/profit-loss")
                            //   : localStorage.setItem("unauthorized", true);

                            // setIsDropdownOpen(false);
                            navigate("/setButton-value")
                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >
                          <img src="/winjaImages/DashboardIcon/set-button-value.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">set Button Value</span>
                        </div>
                        <div
                          onClick={() => {
                            // localStorage.getItem("token")
                            //   ? navigate("/profit-loss")
                            //   : localStorage.setItem("unauthorized", true);

                            // setIsDropdownOpen(false);
                            navigate("/Rules")
                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >

                          <img src="/winjaImages/DashboardIcon/rules.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">Rules</span>

                        </div>
                        <div
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/result")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >
                          <img src="/winjaImages/DashboardIcon/change-password.svg" className="h-[15px]"></img>
                          <span className="text-[12px]">Result History</span>
                        </div>
                        <div
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/deposit")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                        >

                          <img src="/winjaImages/DashboardIcon/deposit.png" className="h-[15px]"></img>
                          <span className="text-[12px]">Deposit</span>
                        </div>

                        {/* <div
                          className="flex items-center gap-1 text-sm px-[0.5px] py-1 border-b   cursor-pointer account-sec"
                          onClick={() => {
                            localStorage.getItem("token")
                              ? navigate("/contact")
                              : localStorage.setItem("unauthorized", true);

                            setIsDropdownOpen(false);
                          }}
                        >
                          <FaPhone size={18} />
                          <span className="text-[12px]">Contact Us</span>
                        </div> */}
                      </div>
                      <div
                        onClick={() => {
                          localStorage.getItem("token")
                            ? navigate("/withdrawal")
                            : localStorage.setItem("unauthorized", true);

                          setIsDropdownOpen(false);
                        }}
                        className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                      >
                        <img src="/winjaImages/DashboardIcon/withdrawal.png" className="h-[15px]"></img>
                        <span className="text-[12px]">withdrawal</span>
                      </div>
                      <a passHref={true} href={`https://wa.me/+${domainSetting?.whatsappNumber}`} title="Whatsapp" className="">
                      <div
                        onClick={() => {
                          // localStorage.getItem("token")
                          //   ? navigate("/profit-loss")
                          //   : localStorage.setItem("unauthorized", true);

                          // setIsDropdownOpen(false);
                        }}
                        className="flex items-center gap-1 text-sm px-2 py-1 border-b   cursor-pointer account-sec"
                      >
                          <img src="/winjaImages/DashboardIcon/contact1.png" className="h-[15px]"></img>
                          <span className="text-[12px]">Contact Us</span>
                      </div>
                        </a>
                    </div>
                    <div
                      onClick={() => handleLogout()}
                      className={`flex items-center justify-between rounded-b-md gap-2 py-1 pr-1.5  text-white cursor-pointer account-sec bg-${primaryColor} hover:bg-[#2d0256]`}
                    >

                      <span className="text-[12px] px-1.5 py-1 font-bold">LogOut</span>
                      <img src="/winjaImages/DashboardIcon/log-out.svg" className="h-[15px] bg-white"></img>
                    </div>

                  </div>
                </div>
              )}
            </>
          </div>

        </header>
        <div className={`grid grid-cols-2 w-full bg-${primaryColor} pb-1  gap-1  lg:hidden md:hidden border border-white`}>
          <div className={`bg-${primaryColor} text-center py-1 flex w-full justify-center  items-center text-white animate-bgTransition `}>
            <a href="/deposit" className="">
              <div className="flex  items-center justify-center gap-[6px]  row">
                <RiBankLine className="text-white text-[24px]" />
                <span className="text-[13px] font-bold">DEPOSIT</span>
              </div>
            </a>
          </div>
          <style jsx>{`
            @keyframes bgTransition {
              0% {
                background-color: ${primaryColor};
              }
              100% {
                background-color: #fdbd3f; 
              }
              
            }

            .animate-bgTransition {
              animation: bgTransition 1.2s infinite; 
            }
          `}</style>

          <div className={`bg-${primaryColor} text-center flex w-full justify-center  items-center text-white animate-bgTransition `}>
            <a href="/withdrawal" className="">
              <div className="flex  items-center justify-center gap-[6px] row">
                <RiBankLine className="text-white text-[24px]" />
                <span className="text-[13px] font-bold">WITHDRAW</span>
              </div>
            </a>
          </div>

        </div>
        {/* {domainSetting && domainSetting?.clientNotification && (
          <marquee className="bg-white px-2 text-xs py-0.5 font-semibold hidden md:block mb-1">
            {domainSetting?.clientNotification}
          </marquee>
        )} */}
        <hr className="w-full bg-white text-white md:hidden block" />
      </div>

      {/* {domainSetting && domainSetting?.clientNotification && (
        <marquee className="bg-white rounded-md text-xs py-0.5 mt-1 font-semibold visible md:hidden">
          {domainSetting?.clientNotification}
        </marquee>
      )} */}
      <Login isOpen={isLoginOpen} closeModal={closeModal} />
      {isSignUpOpen && (
        <SignIn isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen} />
      )}

      <div className="w-full bg-[rgba(229,231,252/0.9)] border-t   ">

        <div className={` bg-[rgba(229,231,252/0.9)] flex  flex-row justify-start items-center`}>

          <div className={`lg:w-[98px] lg:h-6 md:h-6 md:w-[95px] w-[95px] h-6 bg-[rgb(2,10,78)] relative gap-[0.2px]  flex flex-row lg:justify-start lg:pl-1 md:gap-[3px] md:justify-start md:pl-1.5 justify-center items-center`}>
            <FaMicrophone className="lg:text-[15px] md:text-[15px] text-[15px] text-white" />
            <span className="text-white  lg:text-[12px] md:text-[12px] text-[12px]"> News</span>
            <div className={`absolute lg:h-[19px] lg:w-[18px] md:h-4 h-4 w-4 bg-${primaryColor} !right-[-8px] rotate-45`}></div>

          </div>

          {domainSetting && domainSetting?.clientNotification && (
            <marquee>
              <p className={` text-${primaryColor} lg:text-[13px] md:text-[13px] text-[13px]`}>
                <a>{domainSetting?.clientNotification}</a>
              </p>

            </marquee>
          )}

          {/* <marquee>
            <p className={` text-${primaryColor} lg:text-[13px] md:text-[13px] text-[13px]`}>
              <a>Welcome to the PREMIUM WEBSITE WINJA.IN play and win unlimited with 24/7 service…thanks..🙏</a>
              <a>🚀 ENJOY AVIATOR AND NEW 300+ LIVE AND VIRTUAL CASINOS 🎰 </a>
            </p>
          </marquee> */}

        </div>
      </div>
      <div className="md:text-[15px] text-[12px] bg-[#F5F5F5] flex items-center w-full text-gray-900 py-0">
        {/* <div className="overflow-x-auto scroll-md lg:block hidden">
        <div className="font-medium text-sm lg:flex ">
          <div className=" flex items-center divide-x divide-#52796f">
            <span onClick={() => handleTabClick(1)}
              className={`px-2 py-1 capitalize  ${activeTab === 1 ? "text-white  bg-common_color-yellow" : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`}>
              Football
            </span>
            <span onClick={() => handleTabClick(2)}
              className={`px-2 py-1 capitalize  ${activeTab === 2 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Tennis
            </span>
            <span onClick={() => handleTabClick(4)}
              className={`px-2 py-1 capitalize  ${activeTab === 4 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              cricket
            </span>
            
            <span onClick={() => handleTabClick(203)}
              className={`px-2 py-1 capitalize  ${activeTab === 203 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Esoccer 
            </span>
            <span onClick={() => handleTabClick(52)}
              className={`px-2 py-1 capitalize  ${activeTab === 52 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Horse Racing
            </span>
            <span onClick={() => handleTabClick(5)}
              className={`px-2 py-1 capitalize  ${activeTab === 5 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
                Table Tennis
            </span>
            <span onClick={() => handleTabClick(6)}
              className={`px-2 py-1 capitalize  ${activeTab === 6 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Grayhound Racing
            </span>
            <span onClick={() => handleTabClick(7)}
              className={`px-2 py-1 capitalize  ${activeTab === 7 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Basketball
            </span>
            <span onClick={() => handleTabClick(8)}
              className={`px-2 py-1 capitalize  ${activeTab === 8 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Volleyball
            </span>
            <span onClick={() => handleTabClick(9)}
              className={`px-2 py-1 capitalize  ${activeTab === 9 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Kabaddi
            </span>
            <span onClick={() => handleTabClick(10)}
              className={`px-2 py-1 capitalize  ${activeTab === 10 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Badminton
            </span>
            <span onClick={() => handleTabClick(11)}
              className={`px-2 py-1 capitalize  ${activeTab === 11 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              American Football
            </span>
            <span onClick={() => handleTabClick(12)}
              className={`px-2 py-1 capitalize  ${activeTab === 12 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Futsal
            </span>
            <span onClick={() => handleTabClick(12)}
              className={`px-2 py-1 capitalize  ${activeTab === 12 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              E Games
            </span>
            <span onClick={() => handleTabClick(13)}
              className={`px-2 py-1 capitalize  ${activeTab === 13 ? "text-white bg-common_color-yellow " : "text-black bg-[#bbbbbb]"} cursor-pointer whitespace-nowrap`} >
              Snooker
            </span>
          </div>
        </div>
      </div> */}



        <div className="  block w-full bg- overflow-y-auto">
          <div className={` font-bold text-[16px] lg:flex border-b-[1.5px] border-white  overflow-x-auto scroll-md z-10  bg-${primaryColor}`}>
            <>

              <div className=" flex items-center">
                
                <div onClick={() => navigate("/dashboard")}
                  className={`lg:px-6 px-3 capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center h-full justify-evenly text-white  bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><FaHome size={18} /></span><span className="text-[13px] font-bold ">Home</span>
                </div>

                <style jsx>{`
                  @keyframes textColorBlink {
                    0% {
                      color: ${primaryColor};
                    }
                      25% {
                      color: green;
                    }
                      
                      50% {
                      color:purple;
                    }
                      75% {
                      color: pink;
                    }
                    100% {+

                    color: #fdbd3f; 
                    }
                    
                  }
                  .animate-textColorBlink {
                    animation: textColorBlink 0.5s infinite; 
                  }
                `}</style>

                <a href="/iframe-casino/600113">
                  <div
                    className={`lg:px-7 px-3 h-full  capitalize transition-all ease-in-out duration-300 hover:bg-[#4b9325] flex flex-col items-center py-1  white border-l animate-textColorBlink bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                    <span><img src='/winjaImages/DashboardIcon/LudoKing.png' className="h-[19px]" /></span>   <span className="text-[12px]  font-bold ">Ludo Game</span>
                  </div>
                </a>
                {/* <div onClick={() => handleTabClick(4)}
                  className={`lg:px-4 px-2  capitalize hover:bg-[#4b9325] flex flex-col items-center py-1  text-white border-l bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><img src='/winjaImages/DashboardIcon/cricket-ball.png' className="h-[18px]" /></span>   <span className="text-[12px]  font-bold ">Cricket</span>
                </div> */}

                <a href="/iframe-casino/500001">
                  <div
                    // onClick={() => handleTabClick(`/iframe-casino/500001`)}
                    className={`lg:px-7 px-3 h-full  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  white border-l animate-textColorBlink bg-header_bg-900 cursor-pointer whitespace-nowrap`} >
                    <span><img src='/winjaImages/DashboardIcon/spiral2.png' className="h-[19px]" /></span>   <span className="text-[12px]  font-bold ">Super Nowa</span>
                  </div>
                </a>

                <a href="/iframe-casino/201206">
                  <div
                    // onClick={() => handleTabClick(`/iframe-casino/201206`)}I
                    className={`lg:px-8 px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  text-white border-l bg-header_bg-900 cursor-pointer whitespace-nowrap`} >
                    <span><img src='/winjaImages/DashboardIcon/aviator-icon.svg' className="h-[19px]" /></span>   <span className="text-[12px]  font-bold ">Avaitor</span>
                  </div>
                </a>
                <div onClick={() => navigate("/inplay")}
                  className={`lg:px-4 px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  text-white border-l bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><img src='/winjaImages/DashboardIcon/in-play-icon.svg' className="h-[18px]" /></span>   <span className="text-[12px]  font-bold ">In-play</span>
                </div>
                <div onClick={() => navigate("/allcricket")}
                  className={`lg:px-7  py-1 gap-[5px] px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center justify-around lg:h-full h-full   text-white border-l  cursor-pointer whitespace-nowrap`}>
                  <div className="flex flex-row justify-between items-center !h-3  w-7 bg-white rounded-sm  ">
                    <div className="flex flex-row justify-center w-full"><img src='/winjaImages/DashboardIcon/ic_twinkling.svg' className="h-[7px] " /> </div>
                    <div className="bg-red-500  rounded-r-sm h-3 w-6  text-[11px] flex items-center justify-center ">
                      {matchData?.filter((match) => match.sportId == "4")?.length}
                    </div>
                  </div>   <span className="text-[12px]  font-bold ">Cricket</span>
                </div>
                <div onClick={() => navigate("/allsoccer")}
                  className={`lg:px-7 px-3  py-1 gap-[5px] capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center justify-around h-full   text-white border-l  cursor-pointer whitespace-nowrap`}>
                  <div className="flex flex-row justify-between items-center !h-3  w-7 bg-white rounded-sm  "><div className="flex flex-row justify-center w-full"><img src='/winjaImages/DashboardIcon/ic_twinkling.svg' className="h-[7px] " /> </div>
                    <div className="bg-red-500  rounded-r-sm h-3 w-6  text-[11px] flex items-center justify-center ">
                      {matchData?.filter((match) => match.sportId == "1")?.length}
                    </div>
                  </div>   <span className="text-[12px]  font-bold ">Soccer</span>
                </div>
                <div onClick={() => navigate("/alltennis")}
                  className={`lg:px-7 px-3  py-1 gap-[5px] capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center justify-around h-full   text-white border-l  cursor-pointer whitespace-nowrap`}>
                  <div className="flex flex-row justify-between items-center !h-3  w-7 bg-white rounded-sm  "><div className="flex flex-row justify-center w-full"><img src='/winjaImages/DashboardIcon/ic_twinkling.svg' className="h-[7px] " /> </div>
                    <div className="bg-red-500  rounded-r-sm h-3 w-6  text-[11px] flex items-center justify-center ">
                      {matchData?.filter((match) => match.sportId == "2")?.length}
                    </div>
                  </div>   <span className="text-[12px]  font-bold ">Tennis</span>
                </div>
                <div onClick={() => navigate("/casino")}
                  className={`lg:px-6 px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  text-white border-l bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><img src='/winjaImages/DashboardIcon/indian-poker-icon.svg' className="h-[19px]" /></span>   <span className="text-[12px]  font-bold ">casino</span>
                </div>
                <div onClick={() => handleTabClick(2)}
                  className={`lg:px-8 px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  text-white border-l bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><img src='/winjaImages/DashboardIcon/virtualgameicon.png' className="h-[18px]" /></span>   <span className="text-[12px]  font-bold ">Virtual Games</span>
                </div>
                {/* <div onClick={() => {
                  localStorage.getItem("token")
                    ? navigate("/horse-recing")
                    : localStorage.setItem("unauthorized", true);
                }}
                  className={`lg:px-8 px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  text-white border-l bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><img src='/winjaImages/DashboardIcon/greyhound1.png' className="h-[18px]" /></span>   <span className="text-[12px]  font-bold ">Horse</span>
                </div>
                <div onClick={() => {
                  localStorage.getItem("token")
                    ? navigate("/greyhound")
                    : localStorage.setItem("unauthorized", true);
                }}
                  className={`lg:px-6 px-3  capitalize hover:bg-[#4b9325] transition-all ease-in-out duration-300 flex flex-col items-center py-1  text-white border-x bg-header_bg-900 cursor-pointer whitespace-nowrap`}>
                  <span><img src='/winjaImages/DashboardIcon/horse.png' className="h-[18px]" /></span>   <span className="text-[12px]  font-bold ">GrayHound</span>
                </div> */}

              </div>

            </>
          </div>
        </div>
      </div>
      <div>

      </div>

      {isExposerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-1 rounded-lg shadow-lg md:w-1/2 w-[360px] max-h-[600px]">


            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Match
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Stake
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Rate
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Time
                    </th>
                    <th scope="col" class="px-6 py-3 whitespace-nowrap">
                      Market Type
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {exposerData && exposerData.length > 0 ? exposerData?.map((item, index) => (
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                        {item?.market}
                      </th>
                      <td class="px-6 py-4">
                        {item?.amount}
                      </td>


                      <td class="px-6 py-4">
                        {item?.rate}
                      </td>
                      <td class="px-6 py-4">
                        {item?.type}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        {moment(item?.time).format("YYYY-MM-DD hh:mm:ss a")}
                      </td>
                      <td class="px-6 py-4">
                        {item?.marketType}
                      </td>

                    </tr>

                  )) : null}

                </tbody>
              </table>
            </div>

            <div className="flex justify-end w-full">
              <button
                className="mt-4 px-4 py-2 bg-red-500 text-white rounded "
                onClick={() => setIsExposerOpen(false)}
              >

                Close
              </button>
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default Header;

function Loader() {
  return (
    <div className="flex w-full h-[75vh] bg-transparent justify-center items-center">
      {/* bg-[rgba(0,8,73,0.9)] .. */}

      <div className="animate-spin">
        <img
          className="w-auto h-auto max-w-[100px] max-h-[50px] md:max-w-[100px] md:max-h-[50px] sm:max-w-[50px] sm:max-h-[30px] p-4"
          src="/assets/logo.png"
          alt="Loading..."
        />
      </div>
    </div>
  );
}

export default Loader;

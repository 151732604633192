import React, { useEffect, useState } from 'react';
import { casinoListJson } from "./CasinoConstent";
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'antd';
import { primaryColor } from '../../Home/HomeJson';
// import { useHistory } from "react-router";

const Casino = () => {
    const [casinoList, setCasinoList] = useState([]);
    // const history = useHistory();
    const navigate = useNavigate()
    const crouselJson = [

        {
            section: "banner",
            name: "Banner 4",
            pathName: "slider-4.jpeg",
        },
        {
            section: "banner",
            name: "Banner 4",
            pathName: "slider-4.jpeg",
        },
        {
            section: "banner",
            name: "Banner 4",
            pathName: "slider-4.jpeg",
        },
        {
            section: "banner",
            name: "Banner 4",
            pathName: "slider-4.jpeg",
        },
        {
            section: "banner",
            name: "Banner 4",
            pathName: "slider-4.jpeg",
        },

    ]


    useEffect(() => {
        setCasinoList(casinoListJson);
    }, [casinoList]);

    const handleResponseCasino = (product) => {
        // history.push(`/app/${product.shortName}/${product.eventId}`)
        navigate(`/${product.shortName}/${product.eventId}`)
    }

    return (
        <section className='py-0 my-1' >
            {crouselJson && crouselJson.length > 0 ? (
                <Carousel
                    autoplay
                    dots={false} // You can switch to `false` if you prefer no dots
                    speed={500}
                    autoplaySpeed={1500}
                    pauseOnHover={false} // Pause carousel on hover
                    easing="ease-in-out" // Smooth easing for transitions
                    arrows={false} // Show arrows for navigation
                >
                    {crouselJson.filter((image) => image.section === "banner").map(
                        (game, index) => (
                            <div key={index} className="w-full">
                                <img
                                    src={`/winjaImages/banner/${game.pathName}`}
                                    alt={game.name || `Slide ${index + 1}`}
                                    className="w-full h-full object-cover  shadow-md"
                                    // className="w-full h-[140px] sm:h-[180px] md:h-[200px] lg:h-[300px] xl:h-[450px] object-cover  shadow-md"
                                    loading="lazy"
                                />
                            </div>
                        )
                    )}
                </Carousel>
            ) : (
                <div className="text-center text-white">No images available.</div>
            )}
             <div
                className={`text-sm p-1 w-[97%] mx-auto font-semibold text-white bg-${primaryColor}`}
            >
                Casino
            </div>
            <div className='flex justify-center'>
           
                <div className="w-[95%] mx-auto grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-2 py-1 text-white">

                    {casinoList.map((product, index) => (
                        <div key={index} onClick={() => handleResponseCasino(product)} className="shadow  relative overflow-hidden  rounded-sm  duration-500 sw-full w-full h-[200px] sm:h-[250px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px]" style={{ backgroundImage: `url(${product.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="block w-full h-full cursor-pointer">
                                <div className="px-2 py-1  absolute -bottom-[-0.05rem] border  w-full border-gray-700  text-green-950 bg-white hover:text-white hover:bg-yellow-500">
                                    <p className=" text-[12px]  md:text-[13px] lg:text-[14px] 2xl:text-[16px]  truncate flex items-center justify-center uppercase">{product.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Casino;

import moment from "moment";
import { useEffect, useState } from "react";
import { FaSortDown, FaSortUp, FaTv } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "./Home/HomeJson";
import InplaySlider from "./InplaySlider";

function AllCricketMatches() {
  const [cricketOpen, setCricketOpen] = useState(true);
  const [tennisOpen, setTennisOpen] = useState(true);
  const [footballOpen, setFootballOpen] = useState(true);
  const [matchData, setMatchData] = useState([]);
  const matchlistLocal = localStorage.getItem("matchList")
    ? JSON.parse(localStorage.getItem("matchList"))
    : null;

  const { matchList } = useSelector((state) => state.user);

  useEffect(() => {
    let matchListData = matchlistLocal ? matchlistLocal : matchList;
    setMatchData(matchListData);
  }, [matchList]);
  function getTeamsFromMatchName(matchName) {

    let team1 = "Team 1",
      team2 = "Team 2";

    if (typeof matchName !== 'string') {
      return { team1, team2 };
    }


    if (matchName.includes(" v ") || matchName.includes(" V ")) {

      [team1, team2] = matchName.split(/ v | V /);
    } else if (matchName.includes(" vs ") || matchName.includes(" VS ")) {

      [team1, team2] = matchName.split(/ vs | VS /);
    }


    return { team1, team2 };
  }

  const getSportName = (sportId) => {
    switch (sportId) {
      case 4:
        return "Cricket";
      case 2:
        return "Tennis";
      case 1:
        return "Soccer";
      default:
        return "Other";
    }
  };


  const navigate = useNavigate();



  return (
    <div className="pb-20">
      <InplaySlider />
      <div className="sm:hidden md:hidden lg:block  hidden p-2">
        {matchData && matchData.length > 0 ? (
          [4].map((sportId) => {
            const filteredMatches = matchData.filter(
              (match) => match.sportId === sportId
            );
            return (
              <div key={sportId}>
                <div
                  className={`text-sm p-1  font-bold text-white bg-${primaryColor}`}
                >
                  {getSportName(sportId)}
                </div>
                {filteredMatches?.length > 0 ?
                  filteredMatches.map((match, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        navigate(
                          `/match-details/${match.marketId}/${match.eventId}`
                        )
                      }
                      className="grid border-b border-x py-[3px] grid-cols-1 lg:grid-cols-2  justify-between items-center"
                    >
                      <div className="flex md:items-center   justify-between">
                        <div className="  ">
                          <div className="text-[11px]   text-gray-500 font-semibold  sm:order-1 order-2 ml-1">
                            <div className="flex flex-row gap-0.5 justify-start items-center">

                              <img className="h-[13px] sm:hidden visible " src="/winjaImages/DashboardIcon/clock.png"></img>
                              <div className="text-[12px]"> {match?.matchDate}
                                <span className="text-[12px]  text-gray-500 font-semibold pl-0.5 border-l-2 ml-1 border-l-gray-400  ">{match.seriesName}</span>

                                <span className=" sm:overflow-visible sm:whitespace-normal text-[12px]  pl-0.5 ml-1 border-l-gray-400  border-l-2 text-ellipsis overflow-hidden whitespace-nowrap text-black"> {(() => {

                                  const inputMoment = moment(
                                    match?.matchDate,
                                    "DD-MM-YYYY HH:mm:ss A"
                                  );
                                  const currentMoment = moment();
                                  return currentMoment.isSameOrAfter(

                                    inputMoment
                                  ) ? (
                                    <div className="md:w-[10px] h-[10px] w-[10px] md:h-[10px] lg:h-[10px] lg:w-[10px] inline-block  rounded-full ml-[6px]  bg-[#6BBD11]">
                                      {" "}
                                    </div>
                                  ) : ''
                                  // (P
                                  //   ""
                                  // );
                                })()} {match.matchName}</span> &nbsp;
                                <span className="" >
                                  {(() => {

                                    const inputMoment = moment(
                                      match?.matchDate,
                                      "DD-MM-YYYY HH:mm:ss A"
                                    );
                                    const currentMoment = moment();
                                    return currentMoment.isSameOrAfter(

                                      inputMoment
                                    ) ? (
                                      <span className='text-[#91023f] font-bold text-[12px]'>Live now</span>
                                    ) : ''
                                  })()}
                                </span>
                              </div>

                            </div>
                          </div>
                          <div className=" md:text-[14px]  lg:text-xs flex gap-0 pl-1 items-center text-[14px] sm:order-2 order-1 font-bold text-black  sm:border-l-2  sm:border-gray-400">

                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="lg:hidden flex md:items-center gap-1 px-1 ">
                            {match.sportId === 1 ?
                              <img className="h-[16px]" src="/winjaImages/DashboardIcon/tv.svg" />
                              : match.sportId === 2 ? <img className="h-[16px]" src="/winjaImages/DashboardIcon/tv.svg" />
                                : match.sportId === 4 ?
                                  <>
                                    <img className="h-[15px]" src="/winjaImages/DashboardIcon/tv.svg" />
                                    <img className="h-[15px]" src="/winjaImages/DashboardIcon/bm-icon1.svg" />
                                    <img className="h-[15px]" src="/winjaImages/DashboardIcon/fancy-icon1.svg" />
                                  </>
                                  : null}
                          </div>
                          <div className="sm:hidden lg:hidden md:hidden w-full flex items-center justify-end">
                            <div className="   border border-gray-400 ">
                              <img
                                className="h-4 w-4"
                                src="/winjaImages/DashboardIcon/a-pin-mobile.svg"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex h-full justify-end items-center md:gap-0 md:mt-0 mt-2">
                        <div className="lg:flex hidden items-center gap-1 px-1 mr-5 ">
                          {match.sportId === 1 ?
                            <img className="h-[16px]" src="/winjaImages/DashboardIcon/tv.svg" />
                            : match.sportId === 2 ? <img className="h-[16px]" src="/winjaImages/DashboardIcon/tv.svg" />
                              : match.sportId === 4 ?
                                <>
                                  <img className="h-[15px]" src="/winjaImages/DashboardIcon/tv.svg" />
                                  <img className="h-[15px]" src="/winjaImages/DashboardIcon/bm-icon1.svg" />
                                  <img className="h-[15px]" src="/winjaImages/DashboardIcon/fancy-icon1.svg" />
                                </>
                                : null}
                        </div>
                        <div className="w-full    flex flex-row !font-bold text-[11px]">

                          <button className="bg-[#7CC4F7] md:text-[12.9px] mr-1 w-full text-center py-0.5  rounded-full">
                            -
                          </button>
                          <button className="bg-[#FCA4B7]  md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            1.89
                          </button>
                          <button className="bg-[#7CC4F7] md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            -
                          </button>
                          <button className="bg-[#FCA4B7]  md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            1.89
                          </button>
                          <button className="bg-[#7CC4F7] md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            -
                          </button>
                          <button className="bg-[#FCA4B7]  md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            1.89
                          </button>
                        </div>
                        <div className="flex items-center justify-end">
                          <div className="border hidden sm:block border-gray-400">
                            <img
                              className="h-4 w-5"
                              src="/winjaImages/DashboardIcon/a-pin-mobile.svg"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  :
                  <p className=" border-b border-x text-[12px]">Has No Live Event</p>
                }
              </div>
            );
          })
        ) : (
          <div className="w-full text-center text-white font-semibold py-3 bg-primary rounded-lg">
            {" "}
            No data Found
          </div>
        )}
      </div>
      <div className="lg:hidden">
        {matchData && matchData.length > 0 ? (
          [4].map((sportId) => {
            const filteredMatches = matchData.filter(
              (match) => match.sportId === sportId
            );

            return (
              <div key={sportId}>
                <div
                  className={`text-sm p-1 font-bold text-white bg-${primaryColor}`}
                >
                  {getSportName(sportId)}
                </div>
                {filteredMatches?.length > 0 ?
                  filteredMatches.map((match, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        navigate(
                          `/match-details/${match.marketId}/${match.eventId}`
                        )
                      }
                      className="grid border-b border-x py-[3px] grid-cols-1 lg:grid-cols-2  justify-between items-center"
                    >
                      <div className="flex md:items-center   justify-between">
                        <div className="grid sm:grid-cols-2  flex-1 md:grid-cols-1 lg:grid-cols-2 grid-cols-1  ">
                          <div className="text-[11px]   text-gray-500 font-semibold  sm:order-1 order-2 ml-1">
                            <div className="flex flex-row gap-0.5 justify-start items-center">

                              <img className="h-[13px] sm:hidden visible " src="/winjaImages/DashboardIcon/clock.png"></img>
                              <div className="text-[12px]"> {match?.matchDate}
                                <span className="text-[12px]  text-gray-500 font-semibold  sm:order-1 order-2 ml-1 border-l-2 pl-2">{match.seriesName}</span></div>
                            </div>
                          </div>
                          <div className=" md:text-[14px]  lg:text-xs flex gap-1 pl-1 items-center text-[13px] sm:order-2 order-1 font-bold text-black  sm:border-l-2  sm:border-gray-400">


                            {(() => {

                              const inputMoment = moment(
                                match?.matchDate,
                                "DD-MM-YYYY HH:mm:ss A"
                              );
                              const currentMoment = moment();
                              return currentMoment.isSameOrAfter(

                                inputMoment
                              ) ? (
                                <div className="md:w-[10px] h-[10px] w-[10px] md:h-[10px] lg:h-[10px] lg:w-[10px]  rounded-full ml-[6px]  bg-[#6BBD11]">
                                  {" "}
                                </div>
                              ) : ''

                            })()}

                            <div className=" sm:overflow-visible sm:whitespace-normal text-[11px] text-ellipsis overflow-hidden whitespace-nowrap">
                              {match.matchName}</div>
                            {(() => {

                              const inputMoment = moment(
                                match?.matchDate,
                                "DD-MM-YYYY HH:mm:ss A"
                              );
                              const currentMoment = moment();
                              return currentMoment.isSameOrAfter(

                                inputMoment
                              ) ? (
                                <span className='text-[#91023f] font-bold text-[12px]'>Live now</span>
                              ) : ''
                            })()}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="lg:hidden flex md:items-center gap-1 px-1 ">
                            {match.sportId === 1 ?
                              <img className="h-[16px]" src="/winjaImages/DashboardIcon/tv.svg" />
                              : match.sportId === 2 ? <img className="h-[16px]" src="/winjaImages/DashboardIcon/tv.svg" />
                                : match.sportId === 4 ?
                                  <>
                                    <img className="h-[15px]" src="/winjaImages/DashboardIcon/tv.svg" />
                                    <img className="h-[15px]" src="/winjaImages/DashboardIcon/bm-icon1.svg" />
                                    <img className="h-[15px]" src="/winjaImages/DashboardIcon/fancy-icon1.svg" />
                                  </>
                                  : null}
                          </div>
                          <div className="sm:hidden lg:hidden md:hidden w-full flex items-center justify-end py-1">
                            <div className="   border border-gray-400 ">
                              <img
                                className="h-4 w-4"
                                src="/winjaImages/DashboardIcon/a-pin-mobile.svg"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex h-full justify-end items-center md:gap-3 md:mt-0 mt-2">
                        <div className="lg:flex hidden items-center gap-1 px-1 mr-5 ">
                          <img
                            className="h-[19px] w-auto"
                            src="/winjaImages/DashboardIcon/tv.svg"
                          />
                          <img
                            className="h-[17px] w-auto"
                            src="/winjaImages/DashboardIcon/bm-icon1.svg"
                          />
                          <img
                            className="h-[17px] w-auto"
                            src="/winjaImages/DashboardIcon/fancy-icon1.svg"
                          />
                        </div>
                        <div className="w-full flex flex-row font-bold text-[11px]">


                          <button className="bg-[#7CC4F7] md:text-[12.9px] mr-1 w-full text-center py-0.5  rounded-full">
                            -
                          </button>
                          <button className="bg-[#FCA4B7]  md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            1.89
                          </button>
                          <button className="bg-[#7CC4F7] md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            -
                          </button>
                          <button className="bg-[#FCA4B7]  md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            1.89
                          </button>
                          <button className="bg-[#7CC4F7] md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            -
                          </button>
                          <button className="bg-[#FCA4B7]  md:text-[12.9px] mr-1  w-full text-center py-0.5  rounded-full">
                            1.89
                          </button>
                        </div>
                        {/* <div className="h-full     justify-end items-end flex flex-col ">
                            <div className="hidden sm:block border border-gray-400">
                              <img
                                className="h-5 w-7"
                                src="/winjaImages/DashboardIcon/a-pin-mobile.svg"
                                alt="icon"
                              />
                            </div>
                          </div> */}
                      </div>
                    </div>
                  ))
                  :
                  <p className=" border-b border-x text-[12px]">Has No Live Event</p>



                }
              </div>
            );
          })
        ) : (
          <div className="w-full text-center text-white font-semibold py-3 bg-primary rounded-lg">
            {" "}
            No data Found
          </div>
        )}
      </div>
    </div>
  );
}

export default AllCricketMatches;

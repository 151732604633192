import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import cards32Arules from './images/cards32arule.jpg';
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
// import { httpPost, httpPostBet } from "../../../../middelware/Http";
// import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";
import { httpPost, httpPostBet } from "../../../middelware/CasinoMiddleware/Http";
import Loader from "../../../component/Loader/Loader";


class Cards32A extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "card32-a",
      betList: [],
      betresponse: [],
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      clicked: true,
      completeBetList: [],
      inCompleteBetList: [],
      activeTab: 1,
      LoadingBet: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  handleTvScreen = () => {
    this.setState({ isTvScreen: !this.state.isTvScreen });
    this.setState(prevState => ({ clicked: !prevState.clicked }));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };


  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);

  };

  componentDidMount() {

    let requestData = {
      eventId: 3055,
    }
    this.getDidMountData(requestData)
    // this.socketConnection()
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "card32-a",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }



  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }


  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });

  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "card32-a",
      "eventId": 3055,
      "betFor": this.state.betSlipData.data.nat + "",
      "isLay": this.state.betSlipData.type === "No" ? true : false,
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    console.log(result, "result")
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />);
        this.betList(3055)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />);
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, totalProfitLoss, clicked, minStake, maxStake, activeTab } = this.state;
    let { data, result } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let t2 = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let Player8 = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let Player9 = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let Player10 = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let Player11 = data && data.t2 && data.t2[3] ? data.t2[3] : {};

    const maxValue = Math.max(t1.C1, t1.C2, t1.C3, t1.C4);
    const getColorClass = (value) => value === maxValue ? 'text-green-500' : 'text-white';

    let resultCard;
    if (t1 && t1.desc && typeof t1.desc === 'string') {
      resultCard = t1.desc.split(',');
    } else {
      resultCard = [];
    }

    return (
      <>
        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }
        {showLoader ?
          <Loader />
         
          :
          <div className={`w-full relative h-screen  overflow-y-auto text-sm flex`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "32 Cards A"}
                shortName={shortName ? shortName : "card32-a"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full scroll-md lg:space-x-1.5">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={cards32Arules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">
                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "32 Cards A"}
                        ruleImage={cards32Arules}
                        t1={t1}
                      />

                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[480px] md:h-[300px] h-[200px]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full border border-[#24e6a4] ' />
                        <div className=" flex justify-between">
                          <div className="absolute top-0 left-0 w-full">
                            <div className="text-white font-semibold px-1 lg:py-[2px] py-0 tracking-wide uppercase text-[14px]">Card</div>
                            <div className="w-full px-1 lg:space-y-1 space-y-0">
                              <div>
                                <div className={`font-semibold p-[1px] tracking-tight text-[12px] ${t1.C1 > t1.C2 && t1.C1 > t1.C3 && t1.C1 > t1.C4 ? 'text-green-500' : 'text-white'}`}>
                                  Player 8 :
                                  <span className="text-[#ffc107]">{t1 && t1.C1 ? t1.C1 : null}</span>
                                </div>
                                <div className="flex space-x-2 justify-start">
                                  {resultCard && resultCard[0] && resultCard[0] !== '1' ? (<img src={`/cards/${resultCard[0]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[4] && resultCard[4] !== '1' ? (<img src={`/cards/${resultCard[4]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[8] && resultCard[8] !== '1' ? (<img src={`/cards/${resultCard[8]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[12] && resultCard[12] !== '1' ? (<img src={`/cards/${resultCard[12]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[16] && resultCard[16] !== '1' ? (<img src={`/cards/${resultCard[16]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[20] && resultCard[20] !== '1' ? (<img src={`/cards/${resultCard[20]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[24] && resultCard[24] !== '1' ? (<img src={`/cards/${resultCard[24]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[28] && resultCard[28] !== '1' ? (<img src={`/cards/${resultCard[28]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[32] && resultCard[32] !== '1' ? (<img src={`/cards/${resultCard[32]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                </div>
                              </div>

                              <div>
                                <div className={` ${getColorClass(t1.C2)} font-semibold p-[1px] tracking-tight text-[12px]`}>
                                  Player 9 :
                                  <span className="text-[#ffc107]">{t1 && t1.C2 ? t1.C2 : null}</span>
                                </div>
                                <div className="flex space-x-2 justify-start">
                                  {resultCard && resultCard[1] && resultCard[1] !== '1' ? (<img src={`/cards/${resultCard[1]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[5] && resultCard[5] !== '1' ? (<img src={`/cards/${resultCard[5]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[9] && resultCard[9] !== '1' ? (<img src={`/cards/${resultCard[9]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[13] && resultCard[13] !== '1' ? (<img src={`/cards/${resultCard[13]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[17] && resultCard[17] !== '1' ? (<img src={`/cards/${resultCard[17]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[21] && resultCard[21] !== '1' ? (<img src={`/cards/${resultCard[21]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[25] && resultCard[25] !== '1' ? (<img src={`/cards/${resultCard[25]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[29] && resultCard[29] !== '1' ? (<img src={`/cards/${resultCard[29]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[33] && resultCard[33] !== '1' ? (<img src={`/cards/${resultCard[33]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                </div>
                              </div>

                              <div>
                                <div className={` ${getColorClass(t1.C3)} font-semibold p-[1px] tracking-tight text-[12px]`}>
                                  Player 10 :
                                  <span className="text-[#ffc107]">{t1 && t1.C3 ? t1.C3 : null}</span>
                                </div>
                                <div className="flex space-x-2 justify-start">
                                  {resultCard && resultCard[2] && resultCard[2] !== '1' ? (<img src={`/cards/${resultCard[2]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[6] && resultCard[6] !== '1' ? (<img src={`/cards/${resultCard[6]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[10] && resultCard[10] !== '1' ? (<img src={`/cards/${resultCard[10]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[14] && resultCard[14] !== '1' ? (<img src={`/cards/${resultCard[14]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[18] && resultCard[18] !== '1' ? (<img src={`/cards/${resultCard[18]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[22] && resultCard[22] !== '1' ? (<img src={`/cards/${resultCard[22]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[26] && resultCard[26] !== '1' ? (<img src={`/cards/${resultCard[26]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[30] && resultCard[30] !== '1' ? (<img src={`/cards/${resultCard[30]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[34] && resultCard[34] !== '1' ? (<img src={`/cards/${resultCard[34]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                </div>
                              </div>

                              <div>
                                <div className={` ${getColorClass(t1.C4)} font-semibold p-[1px] tracking-tight text-[12px]`}>
                                  Player 11 :
                                  <span className="text-[#ffc107]">{t1.C4}</span>
                                </div>
                                <div className="flex space-x-2 justify-start">
                                  {resultCard && resultCard[3] && resultCard[3] !== '1' ? (<img src={`/cards/${resultCard[3]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[7] && resultCard[7] !== '1' ? (<img src={`/cards/${resultCard[7]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[11] && resultCard[11] !== '1' ? (<img src={`/cards/${resultCard[11]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[15] && resultCard[15] !== '1' ? (<img src={`/cards/${resultCard[15]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[19] && resultCard[19] !== '1' ? (<img src={`/cards/${resultCard[19]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[23] && resultCard[23] !== '1' ? (<img src={`/cards/${resultCard[23]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[27] && resultCard[27] !== '1' ? (<img src={`/cards/${resultCard[27]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[31] && resultCard[31] !== '1' ? (<img src={`/cards/${resultCard[31]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                  {resultCard && resultCard[35] && resultCard[35] !== '1' ? (<img src={`/cards/${resultCard[35]}.png`} alt="card" className="lg:h-10 h-6 lg:w-8 w-6 border-[1px] border-yellow-300" />) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end items-end absolute bottom-2 right-1">
                            <FlipCountdown
                              titlePosition='hidden'
                              hideYear
                              hideMonth
                              hideDay
                              hideHour
                              hideMinute
                              endAtZero
                              size='small'
                              endAt={new Date(Date.now() + 1000 * (t1 && t1.lasttime ? t1.lasttime : null)).toUTCString()}
                            />
                          </div>
                        </div>
                      </div>


                      <div className="w-full ">
                        <div className="grid lg:grid-cols-2 grid-cols-1 lg:space-x-3 space-x-0 lg:space-y-2 space-y-0">

                          <div className=" grey-color white-text mt-2 divide-y divide-[#c7c8ca] border border-[#24e6a4] ">

                            <div className="grid grid-cols-4 text-center darktext font-[600] text-[14px] uppercase divide-x divide-[#c7c8ca]">
                              <div className="col-span-2 white-bg w-full flex justify-start white-text items-center py-1">
                              </div>
                              <div class="w-full flex justify-center light-blue items-center py-1">Back</div>
                              <div class="w-full flex justify-center odds-khaii items-center py-1">Lay</div>
                            </div>


                            <div className="w-full text-center white-bg">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca] ">
                                <div className="h-full text-[14px] capitalize darktext font-[600] px-2 py-1 col-span-2 w-full lg:text-center text-left -space-y-1 flex justify-between items-center">
                                  <div className="">
                                    {Player8 && Player8.nat ? Player8.nat : Player8.nation ? Player8.nation : "Player 8"}
                                  </div>
                                  <div className={`${posArray[Player8.sid] < 0 ? "text-red-500" : "text-green-800"} text-[10px]`} > {posArray[Player8.sid]}</div>
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player8 && Player8.gstatus === 'ACTIVE' ?
                                    <div className="w-full light-blue cursor-pointer"
                                      onClick={() => {
                                        if (Player8 && Player8.b1 != 0) {
                                          this.handleBackOpen({
                                            data: Player8, type: "Yes", odds: Player8.b1, nat: Player8.nat ? Player8.nat : Player8.nation,
                                          }, this.section1Ref);
                                        }
                                      }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player8 && Player8.b1 ? (Player8.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player8 && Player8.bs1 ? (Player8.bs1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full light-blue" >
                                      <div className=" text-center relative darktext py-1">
                                        
                                        <div className="text-[14px] font-[600]">{Player8 && Player8.b1 ? (Player8.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player8 && Player8.bs1 ? (Player8.bs1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player8 && Player8.gstatus === 'ACTIVE' ?
                                    <div className="w-full odds-khaii cursor-pointer " onClick={() => {
                                      if (Player8 && Player8.l1 != 0) {
                                        this.handleBackOpen({ data: Player8, type: "No", odds: Player8.l1, nat: Player8.nat ? Player8.nat : Player8.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center dark-text py-1" >
                                      <div className="text-[14px] font-[600]">{Player8 && Player8.l1 ? (Math.floor(Number(Player8.l1) * 100) / 100).toFixed(2)  : "-"}</div>
                                      <div className="text-[10px] font-[500]">{Player8 && Player8.ls1 ?(Math.floor(Number(Player8.ls1) * 100) / 100).toFixed(2) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full odds-khaii" >
                                      <div className=" text-center relative dark-text py-1">
                                        <div className="text-[14px] font-[600]">{Player8 && Player8.l1 ? (Player8.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player8 && Player8.ls1 ? (Player8.ls1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                              </div>
                            </div>


                            <div className="w-full text-center white-bg">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca]">
                                <div className="h-full text-[14px] capitalize darktext font-[600] px-2 py-1 col-span-2 w-full lg:text-center text-left flex justify-between items-center ">
                                  <div>
                                    {Player9 && Player9.nat ? Player9.nat : Player9.nation ? Player9.nation : "Player 9"}
                                  </div>
                                  <div className={`${posArray[Player9.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px]`} > {posArray[Player9.sid]}</div>
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player9 && Player9.gstatus === 'ACTIVE' ?
                                    <div className="w-full light-blue cursor-pointer " onClick={() => {
                                      if (Player9 && Player9.b1 != 0) {
                                        this.handleBackOpen({ data: Player9, type: "Yes", odds: Player9.b1, nat: Player9.nat ? Player9.nat : Player9.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player9 && Player9.b1 ? (Player9.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player9 && Player9.bs1 ? (Player9.bs1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full light-blue" >
                                      <div className=" text-center relative darktext py-1">
                                        <div className="text-[14px] font-[600]">{Player9 && Player9.b1 ? (Player9.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player9 && Player9.bs1 ? (Player9.bs1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player9 && Player9.gstatus === 'ACTIVE' ?
                                    <div className="w-full odds-khaii cursor-pointer " onClick={() => {
                                      if (Player9 && Player9.l1 != 0) {
                                        this.handleBackOpen({ data: Player9, type: "No", odds: Player9.l1, nat: Player9.nat ? Player9.nat : Player9.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player9 && Player9.l1 ? (Player9.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player9 && Player9.ls1 ? (Player9.ls1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full odds-khaii" >
                                      <div className=" text-center relative darktext py-1">
                                        <div className="text-[14px] font-[600]">{Player9 && Player9.l1 ? (Player9.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player9 && Player9.ls1 ? (Player9.ls1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div className=" grey-color white-text divide-y divide-[#c7c8ca] border border-[#24e6a4]">

                            <div className="lg:grid grid-cols-4 hidden text-center darktext font-[600] text-[14px] uppercase divide-x divide-[#c7c8ca]">
                              <div className="col-span-2 white-bg w-full flex justify-start white-text items-center py-1">
                              </div>
                              <div class="w-full flex justify-center light-blue items-center py-1">Back</div>
                              <div class="w-full flex justify-center odds-khaii items-center py-1">Lay</div>
                            </div>


                            <div className="w-full text-center white-bg">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca]">
                                <div className="h-full text-[14px] capitalize darktext font-[600] px-2 py-1 col-span-2 w-full lg:text-center text-left flex justify-between items-center ">
                                  <div>
                                    {Player10 && Player10.nat ? Player10.nat : Player10.nation ? Player10.nation : "Player 10"}
                                  </div>
                                  <div className={`${posArray[Player10.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px]`} > {posArray[Player10.sid]}</div>
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player10 && Player10.gstatus === 'ACTIVE' ?
                                    <div className="w-full light-blue cursor-pointer " onClick={() => {
                                      if (Player10 && Player10.b1 != 0) {
                                        this.handleBackOpen({ data: Player10, type: "Yes", odds: Player10.b1, nat: Player10.nat ? Player10.nat : Player10.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player10 && Player10.b1 ? (Player10.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player10 && Player10.bs1 ? (Player10.bs1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full light-blue" >
                                      <div className=" text-center relative darktext py-1">
                                        <div className="text-[14px] font-[600]">{Player10 && Player10.b1 ? (Player10.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player10 && Player10.bs1 ? (Player10.bs1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player10 && Player10.gstatus === 'ACTIVE' ?
                                    <div className="w-full odds-khaii cursor-pointer " onClick={() => {
                                      if (Player10 && Player10.l1 != 0) {
                                        this.handleBackOpen({ data: Player10, type: "No", odds: Player10.l1, nat: Player10.nat ? Player10.nat : Player10.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player10 && Player10.l1 ? (Player10.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player10 && Player10.ls1 ? (Player10.ls1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full odds-khaii" >
                                      <div className=" text-center relative darktext py-1">
                                        <div className="text-[14px] font-[600]">{Player10 && Player10.l1 ? (Player10.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player10 && Player10.ls1 ? (Player10.ls1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                              </div>
                            </div>


                            <div className="w-full text-center white-bg">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca]">
                                <div className="h-full text-[14px] capitalize darktext font-[600] px-2 py-1 col-span-2 w-full lg:text-center text-left flex justify-between items-center ">
                                  <div>
                                    {Player11 && Player11.nat ? Player11.nat : Player11.nation ? Player11.nation : "Player 11"}
                                  </div>
                                  <div className={`${posArray[Player11.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px]`} > {posArray[Player11.sid]}</div>
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player11 && Player11.gstatus === 'ACTIVE' ?
                                    <div className="w-full light-blue cursor-pointer " onClick={() => {
                                      if (Player11 && Player11.b1 != 0) {
                                        this.handleBackOpen({ data: Player11, type: "Yes", odds: Player11.b1, nat: Player11.nat ? Player11.nat : Player11.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player11 && Player11.b1 ? (Player11.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player11 && Player11.bs1 ? (Player11.bs1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full light-blue" >
                                      <div className=" text-center relative darktext py-1">
                                        <div className="text-[14px] font-[600]">{Player11 && Player11.b1 ? (Player11.b1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player11 && Player11.bs1 ? (Player11.bs1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                                <div class="flex justify-end items-center divide-x w-full">
                                  {Player11 && Player11.gstatus === 'ACTIVE' ?
                                    <div className="w-full odds-khaii cursor-pointer " onClick={() => {
                                      if (Player11 && Player11.b1 != 0) {
                                        this.handleBackOpen({ data: Player11, type: "No", odds: Player11.l1, nat: Player11.nat ? Player11.nat : Player11.nation },
                                          this.section1Ref);
                                      }
                                    }}>
                                      <div className=" text-center darktext py-1" >
                                        <div className="text-[14px] font-[600]">{Player11 && Player11.l1 ? (Player11.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player11 && Player11.ls1 ? (Player11.ls1) : "-"}</div>
                                      </div>
                                    </div> :
                                    <div className="w-full odds-khaii" >
                                      <div className=" text-center relative darktext py-1">
                                        <div className="text-[14px] font-[600]">{Player11 && Player11.l1 ? (Player11.l1) : "-"}</div>
                                        <div className="text-[10px] font-[500]">{Player11 && Player11.ls1 ? (Player11.ls1) : "-"}</div>
                                        <BetLocked />
                                      </div>
                                    </div>}
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                      <div className=" pb-2 bg-black/5 mt-2 border border-[#24e6a4]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() => this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              <p className={`font-[600] text-[13px] text-[#FFFF33]`}>
                                {element && element.result === '1' ? "8" : element && element.result === '2' ? "9" : element && element.result === '3' ? "10" : "11"}
                              </p>

                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>


                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />


                  </div>
                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}


              </div>
            </div>
          </div >}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Cards32A);
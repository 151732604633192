import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCasinoListByCateogeory } from "../redux/_reducers/_user_reducers";
import { useDispatch, useSelector } from "react-redux";

function SlotGames() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [categoryWiseCasinoList, setCategoryWiseCasinoList] = useState([]);
    const { getCasinoListByCateogeoryData, loading } = useSelector((state) => state.user);

    useEffect(() => {
        const reqData = { category: "Slot games" };
        dispatch(getCasinoListByCateogeory(reqData));
    }, [dispatch]);

    // Use the correct dependency to update state when data changes
    useEffect(() => {
        if (getCasinoListByCateogeoryData) {
            setCategoryWiseCasinoList(getCasinoListByCateogeoryData);
        }
    }, [getCasinoListByCateogeoryData]);

    const handleResponseCasino = (product) => {
        if (product?.gameId) {
            localStorage.getItem("token")
                ? navigate(`/iframe-casino-new/${product?.providerName}/${product?.gameId}`)
                : localStorage.setItem("unauthorized", true);
        } else {
            if (!product.shortName || !product.eventId) return;
            localStorage.getItem("token")
                ? navigate(`/${product.shortName}/${product.eventId}`)
                : localStorage.setItem("unauthorized", true);
        }
    };

    const sortedList = categoryWiseCasinoList?.filter(item => item.priority !== 0)
        .sort((a, b) => b.priority - a.priority);

    const finalList = [
        ...categoryWiseCasinoList?.filter(item => item.priority === 0),
        ...sortedList
    ];

    return (
        <>
            <div className="w-full rounded bg-conic">
                <div className="px-3 py-2 text-lg text-white font-bold uppercase">Provider:- Slot Games</div>
            </div>

            {/* Loading spinner while waiting for data */}
            {loading ? (
                <div className="text-center py-5">
                    <p>Loading...</p>
                </div>
            ) : (
                <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 gap-6 mb-20 py-3">
                    {finalList?.map((item, idx) => (
                        <div key={idx} className="flex flex-col items-center gap-3 border-2 border-orange-600 rounded-lg">
                            <img
                                onClick={() => handleResponseCasino(item)}
                                src={item?.urlThumb}
                                alt={item?.gameName}
                                className="w-32 h-32 sm:w-32 sm:h-32 md:w-44 md:h-40 object-cover rounded-lg cursor-pointer hover:scale-105 transition-transform duration-300"
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default SlotGames;

import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/_reducers/_auth_reducers";
import { message } from 'antd'; // Ant Design's message component
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // React Icons for eye toggle
import SignIn from "../../pages/SignIn";
import { getUserBalance } from "../../redux/_reducers/_user_reducers";

function Login({ isOpen, closeModal }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isterm, setIsterm] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useRef(null); // Create a reference for the modal container

  
  useEffect(() => {
    // Close modal if clicked outside of the modal container
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      return message.error("Please enter username and password");
    }

    const reqData = {
      username: username,
      password: password,
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(reqData))
      .then((data) => {
        if (!data.error) {
          dispatch(getUserBalance())
          closeModal();
          window.location.href = "/dashboard";
        } else {
          console.error("Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Login request failed:", error);
      });
  };

  // Handle login with demo credentials
  const handleDemoLogin = () => {
    const demoCredentials = {
      username: "brclient",
      password: "1122",
      isClient: true,
      host: window.location.host,
    };

    dispatch(login(demoCredentials))
      .then((data) => {
        if (!data.error) {
          closeModal();
          window.location.href = "/dashboard";
        } else {
          console.error("Demo Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Demo login request failed:", error);
      });
  };

  const termANdConsition = () => {
    setIsterm(true);
  };

  return (
    <>
      {isterm ? (
        <div
          className="fixed z-30 inset-0 flex items-center justify-center bg-[rgba(0,0,0,0.9)] px-3"
          onClick={closeModal}
        >
          <div className="bg-primary text-white max-w-[550px] md:min-w-[800px] min-w-[100%] rounded-2xl shadow-lg relative">
            {/* Header */}
            <div className="py-3 px-3">
              <div className="border-b border-secondary py-3 text-gray-500 font-bold">
                Terms & Conditions Agreement
              </div>
              <ul
                style={{ listStyleType: "disc" }}
                className="px-6 text-sm space-y-3 mt-3 text-gray-500"
              >
                {/* Terms content */}
                <li className="text-xs mt-4">
                  Any sort of <b>heating</b> bet, any sort of <b>Matching(Passing of
                  funds)</b>, Court Siding <b>(Ghaobaazi on commentary), Sharpening,
                  Commission making </b> is not allowed in Exchange, etc.
                </li>
                <li className="text-xs">
                  <b>Fluke hunting/Seeking</b> is prohibited in Exchange, etc.
                </li>
                <li className="text-xs">
                  In case of any technical issue or disruption of services, Exchange is
                  neither liable nor responsible to pay any losses.
                </li>
                <li className="text-xs">
                  Betfair.com reserves the right to reverse, resettle or void any Market or
                  bet, etc.
                </li>
              </ul>
            </div>
            <div className="flex items-center justify-between mb-4 border-t border-secondary px-6 py-3">
              <div></div>
              <button
                onClick={() => setIsterm(false)}
                className="text-white bg-orange-700 px-2 py-2 rounded flex justify-center items-center hover:text-white text-sm"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="fixed z-30 inset-0 flex items-center justify-center bg-[rgba(0,0,0,0.9)] px-3"
        >
          <div
            ref={modalRef} // Reference to the modal container
            className="bg-white text-black max-w-[550px] md:min-w-[500px] rounded-lg p-4 shadow-lg relative"
          >
            {/* Header */}
            <div className="flex items-center justify-center mb-4 px-6 py-4">
              <img src="/assets/logo.png" alt="Power play247" className="h-10" />
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="px-3 pb-3">
              {/* Input Fields */}
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Username"
                  className="border border-blue-950 w-full text-black flex-grow p-2 rounded-md my-2 placeholder-gray-400 outline-none"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="mb-4 relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="w-full border flex-grow p-2 mb-2 rounded-md text-black placeholder-gray-400 border-blue-950 focus:border outline-none"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-3 top-2 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
                </button>
              </div>

              {/* Buttons */}
              <button type="submit" className="bg-blue-950 text-white w-full py-2 rounded-md mb-4">
                SignIn
              </button>
              <button type="button" className="bg-blue-950 text-white w-full py-2 rounded-md mb-4">
              <div class="flex items-center justify-center">
                                                    <img src="images/betfair.svg" class="img-responsive" alt="" />
                                                </div>
              </button>
              <div className="flex items-center w-full justify-center">
                <span className="text-sm text-center">
                  Don't have an Account ?{" "}
                  <span
                    className="text-blue-950 underline"
                    // onClick={() => {
                    //   setIsSignUpOpen(true);
                    // }}
                  >
                    Register
                  </span>{" "}
                </span>
              </div>
            </form>
          </div>

          {/* Signup Modal */}
          {isSignUpOpen && (
            <SignIn isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen} />
          )}
        </div>
      )}
    </>
  );
}

export default Login;

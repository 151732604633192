import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/SignIn";
import Layout from "./layout/Layout";
import PrivateRoute from "./routes/PrivateRoute";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDomainSettingData, getInternationalGroupCasinoList, getMatchList } from "./redux/_reducers/_user_reducers";
import { BalanceProvider } from "./component/ContextApi";
import IframeCasino from "./pages/DiamondCasino/Casino/IframeCasino/IframeCasino";
import IframeCasinonew from "./pages/DiamondCasino/Casino/IframeCasino/IframeCasinonew";
import IframeQtech from "./pages/DiamondCasino/Casino/IframeCasino/IframeQtech";
import { IoChatbox } from "react-icons/io5";
import Login from "./component/login/Login";
import Home from "./pages/Home/Home";



function App() {
  const dispatch = useDispatch();
  const cosinoGroupList = JSON.parse(localStorage.getItem('cosinoGroupList'))

  useEffect(() => {
    dispatch(getMatchList());
    let domainSetting = {
      domainUrl: window.location.origin,
      // domainUrl: 'https://sikander777.com',
    };
    dispatch(getDomainSettingData(domainSetting));
    const interval = setInterval(() => {
      dispatch(getMatchList());
    }, 180000);

    casinoGroupWise()
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);


  const casinoGroupWise = () => {
    const ReqData = {
      "categoriesList": true,
      "providerList": true,
      "lobbygames": true,
      "trendingGames": true,
      "popularGames": true,
      "liveGames": true
    };
    {
      !cosinoGroupList && (
        dispatch(getInternationalGroupCasinoList(ReqData))
      )
    }

  }

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://widget.cxgenie.ai/widget.js";
  //   script.async = true;
  //   script.setAttribute("data-aid", "d443e72a-22b5-4780-a409-8238f135780e");
  //   script.setAttribute("data-lang", "en");

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);


  //..
  const domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));


  return (
    <BalanceProvider>
      <div className="">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Layout />} />
          </Routes>
        </BrowserRouter>
      </div>
    </BalanceProvider>
  );
}

export default App;

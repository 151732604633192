export const casinoListJson = [
  {
    title: '20 20 teenpatti',
    eventId: '3030',
    shortName: 'teen20',
    img: "/winjaImages/casinoimages/20-20-TEENPATTI.jpg",
    casinoSort: "marketWhite"
  },

// \
//   C:\Users\computer\Desktop\Projects\winja-cilent\public\winjaImages\casinoimages\BOLLYWOD-CASINO.jpg
//   C:\Users\computer\Desktop\Projects\winja-cilent\public\winjaImages\casinoimages\LUCKY-7-A.jpg
//   C:\Users\computer\Desktop\Projects\winja-cilent\public\winjaImages\casinoimages\LUCKY-7-B.jpg
//   C:\Users\computer\Desktop\Projects\winja-cilent\public\winjaImages\casinoimages\Test-Teenpatti.jpg

  {
    title: 'lucky 7 - b',
    eventId: '3032',
    shortName: 'lucky7eu',
    img: "/winjaImages/casinoimages/LUCKY-7-B.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: '20 20 dragon tiger',
    eventId: '3035',
    shortName: 'dt20',
    img: "/winjaImages/casinoimages/20-20-dragon-tiger.png",
    casinoSort: "marketWhite"
  },

  {
    title: 'amar akbar anthony',
    eventId: '3056',
    shortName: 'aaa',
    img: "/winjaImages/casinoimages/AMAR-AKBAR-ANTHONY.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'worli matka',
    eventId: '3054',
    shortName: 'worli',
    img: "/winjaImages/casinoimages/worli-matka.png",
    casinoSort: "marketWhite"
  },

  {
    title: '20-20 Dargon tiger 2',
    eventId: '3059',
    shortName: 'dt202',
    img: "/winjaImages/casinoimages/20-20-DRAGON-TIGER.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'teenpatti 1-day',
    eventId: '3031',
    shortName: 'teen',
    img: "/winjaImages/casinoimages/1-day-teen.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: '32 cards b',
    eventId: '3034',
    shortName: 'card32eu',
    img: "/winjaImages/casinoimages/32-card-b.png",
    casinoSort: "marketWhite"
  },

  {
    title: 'teenpatti test',
    eventId: '3048',
    shortName: 'teen9',
    img: "/winjaImages/casinoimages/Test-Teenpatti.jpg",
    casinoSort: "marketWhite"
  },
  {
    title: '32 cards a',
    eventId: '3055',
    shortName: 'card32-a',
    img: "/winjaImages/casinoimages/32-CARD-A.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'Ander Bahar',
    eventId: '3043',
    shortName: 'abj',
    img: "/winjaImages/casinoimages/ANDAR-BAHAR-2.jpg",
    casinoSort: "marketWhite"
  },

  
];
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userUpdate } from '../redux/_reducers/_user_reducers';
import { primaryColor } from './Home/HomeJson';


const SetButtonValue = () => {
  const [formattedButtonValues, setFormattedButtonValues] = useState([]);
  const dispatch = useDispatch();
  const Id = JSON.parse(localStorage.getItem('user_id_tvs99'))?.data?.userId;


  useEffect(() => {
    let betChipsDataItems = JSON.parse(localStorage.getItem('clientbetChipsData'));
    let betChips = betChipsDataItems || {};

    const keyValues1 = Object.entries(betChips).map(([key, value]) => ({
      key,
      value: parseInt(value),
    }));
    setFormattedButtonValues(keyValues1);
  }, []);

  const handleKeyChange = (index, newKey) => {
    const updatedValues = [...formattedButtonValues];
    updatedValues[index].key = newKey;
    setFormattedButtonValues(updatedValues);
  };

  const handleValueChange = (index, newValue) => {
    if (/^\d*$/.test(newValue) && (newValue === '' || newValue.length <= 10)) {
      const updatedValues = [...formattedButtonValues];
      updatedValues[index].value = newValue === '' ? '' : Number(newValue);
      setFormattedButtonValues(updatedValues);
    } else {
      console.log('Please enter a valid integer value with up to 10 digits.');
    }
  };

  const handleSubmit = () => {
    const data = {};
    formattedButtonValues.forEach((item) => {
      data[item.key] = item.value;
    });

    let reqData = {
      userId: Id,
      betChipsData: data,
    };

    dispatch(userUpdate(reqData)).then((response)=>{
      console.log(response, "responseresponseresponse");
      
    });
    localStorage.setItem('clientbetChipsData', JSON.stringify(data));
  };

  return (
    <div className="">
      <div className={`w-full text-[12px] text-white p-1.5 font-bold bg-${primaryColor}`}>
        Set Button Value
      </div>
      <div className='px-3'>
        <table className="w-full text-sm text-left border-2 border-[#ddd] mt-2 px-4">
          <thead className="text-[12px] border-2 text-[#243a48] bg-[#E4E4E4]  border-white">
            <tr className="border-2 border-[#ddd]">
              <th scope="col" className="px-2.5 w-[3%] py-1.5 text-[#243a48] border-2 border-[#ddd]">
                ID
              </th>
              <th scope="col" className="px-2.5 w-[16.5%] py-1 text-[#243a48] border-2 border-[#ddd]">
                Button Name
              </th>
              <th scope="col" className="px-2.5 w-[16.5%] py-1 text-[#243a48] border-2 border-[#ddd]">
                Button Value
              </th>
            </tr>
          </thead>
          <tbody>
            {formattedButtonValues.map((item, index) => (
              <tr key={index}>
                <td className="py-0.5 px-2 border-2 border-[#ddd]">{index + 1}</td>
                <td>
                  <input
                    type="text"
                    value={item.key}
                    className="w-full border-2 p-1 rounded-[3px]"
                    onChange={(e) => handleKeyChange(index, e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.value}
                    className="w-full border-2 p-1 rounded-[3px]"
                    onChange={(e) => handleValueChange(index, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
        className="bg-gradient-to-b from-[#333] to-black rounded-[4px] mt-6 min-h-[30px]"
        onClick={handleSubmit}
      >
        <span className="text-white text-[13px] px-[19px] py-[20px]">Submit</span>
      </button>
      </div>
     
    </div>
  );
};

export default SetButtonValue;



import React, { useEffect, useState, useRef } from "react";
import { FaChevronDown, FaChevronRight, FaHome, FaPlay, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SPORTSCONSTANT } from "../../middelware/global";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { casinoImages } from "../../middelware/casinoConstants";
import { primaryColor } from "../../pages/Home/HomeJson";
import { BiDownArrow } from "react-icons/bi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";


const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [clickedOutside, setClickedOutside] = useState(true);
  const [clickedOutside1, setClickedOutside1] = useState(true);
  const [clickedSports, setClickedSports] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [sidebar, sidebartoggle] = useState(false);
  const [openKeys2, setOpenKeys2] = useState({});
  const navigate = useNavigate();
  const [matchData, setMatchData] = useState([]);
  const [matchDataLocal, setMatchDataLocal] = useState([]);

  const matchlistLocal = localStorage.getItem("matchList")
    ? JSON.parse(localStorage.getItem("matchList"))
    : null;
  const { matchList } = useSelector((state) => state.user);
  const sidebarRef = useRef(null); // Ref to the sidebar element
  const sidebarRef2 = useRef(null); // Ref to the sidebar element

  useEffect(() => {
    let matchListData = matchlistLocal ? matchlistLocal : matchList;
    setMatchDataLocal(matchListData);
    const printingData = organizeData(matchListData);
    setMatchData(printingData);
  }, [matchList]);

  const organizeData = (data) => {
    if (!data) return [];
    const organizedData = [];
    data?.forEach((item) => {
      const { sportId, seriesId, seriesName, createdAt } = item;
      let sportIndex = organizedData?.findIndex(
        (sport) => sport.sportId === sportId
      );
      if (sportIndex === -1) {
        sportIndex = organizedData.length;
        organizedData.push({ sportId, series: [] });
      }
      let seriesIndex = organizedData[sportIndex].series.findIndex(
        (series) => series.seriesId === seriesId
      );
      if (seriesIndex === -1) {
        seriesIndex = organizedData[sportIndex].series.length;
        organizedData[sportIndex].series.push({
          seriesId,
          seriesName,
          matches: [],
        });
      }
      organizedData[sportIndex].series[seriesIndex].matches.push(item);
    });
    return organizedData;
  };

  const [openKeys, setOpenKeys] = useState([]);
  const [openKeys1, setOpenKeys1] = useState({});

  const handleClick = (index, e) => {
    e.stopPropagation();
    if (openKeys.includes(index)) {
      setOpenKeys(openKeys.filter((key) => key !== index));
    } else {
      setOpenKeys([...openKeys, index]);
    }
  };

  const handleClick1 = (sportIndex, seriesIndex, e) => {
    e.stopPropagation();
    const key = `${sportIndex}-${seriesIndex}`;
    setOpenKeys1((prevOpenKeys1) => ({
      ...prevOpenKeys1,
      [key]: !prevOpenKeys1[key],
    }));
  };

  const handleNavigate = (item) => {
    localStorage.getItem("token")
      ? (window.location.href = item)
      : localStorage.setItem("unauthorized", true);
  };

  // Close sidebar when clicked outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen, setIsSidebarOpen]);

  const [menuState, setMenuState] = useState({
    isInPlayOpen: false,
    isInPlayOpen2: false,
    isInPlayOpen3: false,
    isInPlayOpen4: false,
    isInPlayOpen5: false,
    isInPlayComing: false,
    openChild: null,
    openChild2: null,
    openChild3: null,
    openChild4: null,
    openChild5: null,
  });

  const handleResponseCasinoApi = (product) => {
    console.log(product, "productproductproduct");

    if (product?.gameId) {
      localStorage.getItem("token")
        ? navigate(`/iframe-casino-new/${product?.providerName}/${product?.gameId}`)
        : localStorage.setItem("unauthorized", true);

    } else {
      if (!product.shortName || !product.eventId) return;
      localStorage.getItem("token")
        ? navigate(`/${product.shortName}/${product.eventId}`)
        : localStorage.setItem("unauthorized", true);
    }
  };




  const toggleMenu = (menuKey) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuKey]: !prevState[menuKey],
    }));
  };
  const toggleChild = (menuKey, section) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuKey]: prevState[menuKey] === section ? null : section,
    }));
  };
  const handleResponseCasino = (product) => {
    if (product?.iframeID) {
      localStorage.getItem("token")
        ? navigate(`/iframe-casino-new/${product?.provider}/${product?.iframeID}`)
        : localStorage.setItem("unauthorized", true);
    } else {
      if (!product.shortName || !product.eventId) return;
      localStorage.getItem("token")
        ? navigate(`/${product.shortName}/${product.eventId}`)
        : localStorage.setItem("unauthorized", true);
    }
    setIsSidebarOpen(false)
  };
  const handleClickProvider = (path) => {
    localStorage.getItem("token")
      ? navigate(`/iframe-casino/${path}`)
      : localStorage.setItem("unauthorized", true);
  };

  const providers = [
    { id: "100001", name: "Ezugi", img: "/assets/appImages/navigation/57.webp" },
    { id: "600000", name: "Jilli", img: "/assets/appImages/magic-win-biz/cms-content/assets/images/icons/86.png" },
    { id: "SPB-aviator", name: "Qtech", img: "/assets/appImages/categorydetail/46.webp" },
    { id: "100001", name: "Wheel", img: "/assets/appImages/categorydetail/1629.webp" },
    { id: "200215", name: "Evolution", img: "/assets/appImages/navigation/56.webp" },
    { id: "201206", name: "AVIATOR", img: "/assets/appImages/magic-win-biz/cms-content/assets/images/categorydetail/49.webp" },
    { id: "900000", name: "Royal Gaming", img: "/assets/rgCasino.png" },
    { id: "400000", name: "AE SEXY", img: "/assets/HotnSexy.jpg" },
    { id: "500001", name: "Supernova", img: "/assets/supernova.png" },
  ];
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef2.current && !sidebarRef2.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsSidebarOpen]);



  const handleClick2 = (sportIndex, seriesIndex, dateIndex, e) => {
    e.stopPropagation();
    const key = `${sportIndex}-${seriesIndex}-${dateIndex}`;
    // setOpenKeys2((prevOpenKeys2) => ({
    //   ...prevOpenKeys2,
    //   [key]: !prevOpenKeys2[key],
    // }));
  };


  return (
    <div
      ref={sidebarRef}
      className={`${isSidebarOpen
        ? "translate-x-0 opacity-100"
        : "-translate-x-full opacity-0"
        } lg:translate-x-0 lg:opacity-100    lg:block   w-full h-screen sidebarbg md:bg-white  bg-[#000000]/60 text-white fixed lg:relative top-0 left-0 bottom-0 transition-all duration-300 border-l border-white`}
    >
      <div className="p-[5px] border-t  invisible lg:hidden mx-4 flex items-center justify-between ">
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="text-gray-400 hover:text-white text-3xl block lg:hidden"
        >
          &times;
        </button>
        <img
          onClick={() => {
            navigate("/");
          }}
          src="/assets/logo.png"
          alt="Reddy Plus"
          className="h-12 w-28"
        />
      </div>

      {/* Desktaop View  */}
      <div className="hidden lg:block bg-white text-black ">
        <div className={`bg-${primaryColor} flex px-1.5 py-1 items-center text-[13px] font-[700] text-white`}>
          <span>Popular</span>
        </div>
        <div
          onClick={() => navigate("/inplay")}
          className={`text-${primaryColor}  flex items-center border-b py-1.5 px-2.5 gap-1 text-sm mr-0 sidebarbx cursor-pointer`}
        >
          <img src="/winjaImages/DashboardIcon/in-play-icon.svg" alt="" className="h-4" />
          <span className="text-[12px] font-[700]">In-Play</span>
        </div>
        {SPORTSCONSTANT?.map((menuItem, index) => {
          const sport = matchData?.find(
            (sport) => sport.sportId.toString() === menuItem.count
          );
          return (
            <div
              key={index}
              className={`text-[#272d31] overflow-hidden py-0 my-0 transition-[max-height] duration-300 ease-in ${clickedOutside1 === true
                ? "max-h-96 bg-white"
                : "max-h-0 bg-white"
                }`}
            >
              <div className="cursor-pointer">
                <div
                  className="text-md  py-0 my-0 ml-0 w-full flex justify-between  sidebarbx items-center"
                  onClick={(e) => handleClick(index, e)}
                >
                  <div className="flex gap-1">
                    <img src={menuItem.iconImg} alt="" className="h-4" />
                    <span className="text-[12px] font-[700]">{menuItem.text}</span>
                  </div>
                  <span>

                    <FaChevronDown size={12} />
                  </span>
                </div>
                {sport && openKeys.includes(index) && (
                  <div className=" py-0 my-0  !bg-[rgba(229,231,252,0.9)] border-b">
                    {sport?.series.map((series, seriesIndex) => (
                      <div key={seriesIndex} className="">
                        <div
                          className="text-md relative px-0 text-black w-full submenu bg-[rgb(rgb(210,227,255))] py-0 my-0 space-x-2 font-[700] flex  items-center cursor-pointer"
                          onClick={(e) => handleClick1(index, seriesIndex, e)}
                        >
                          <div className="px-5  py-3 border-b-[0.3px] border-[#ccccccb0] w-full bg-[rgb(rgb(210,227,255))] my-0  flex justify-start items-center gap-4 " sty>
                            {openKeys1[`${index}-${seriesIndex}`] ? (
                              <AiOutlineMinusSquare size={15} />
                            ) : (
                              <AiOutlinePlusSquare size={15} />
                            )}
                            {series.seriesName}
                          </div>
                        </div>
                        {openKeys1[`${index}-${seriesIndex}`] && (
                          <div className="">
                            {series.matches.map((match, matchIndex) => (
                              <div key={matchIndex} className="">

                                <div
                                  className="text-md relative  border-b-[0.3px] border-[#ccccccb0] px-6 text-black  w-full py-3 justify-start my-0  font-[400] flex items-center cursor-pointer"
                                  onClick={() => window.location.href = `/match-details/${match.marketId}/${match?.eventId}`}
                                >
                                  <span>

                                  </span>
                                  <span className="text-[10.5px] pl-4  w-full">{match.matchName}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

            </div>
          );
        })}
        <div
          className={`text-${primaryColor} flex items-center border-b p-1 px-2.5 gap-1  text-md capitalize   mr-0 sidebarbx`}
        >
          <a href="/casino" className="flex items-center p-0.5 gap-1">
            <img src="/winjaImages/DashboardIcon/indian-poker-icon.svg" alt="" className="h-4" />
            <span className="font-[700]">Casino</span>
          </a>
        </div>
        <div
          className={`text-${primaryColor} flex items-center border-b p-1 px-2.5 gap-1  text-md capitalize   mr-0 sidebarbx `}
        >
          <a href="/iframe-casino/500001" className="flex items-center p-0.5 gap-1">
            <img src="/winjaImages/DashboardIcon/spiral2.png" alt="" className="h-4" />
            <span className="font-[700]">Super nowa</span>
          </a>
        </div>
        <div
          className={`text-${primaryColor} flex items-center border-b p-1 px-2.5 gap-1  text-md capitalize   mr-0 sidebarbx `}
        >
          <img src="/winjaImages/DashboardIcon/virtualgameicon.png" alt="" className="h-3" />
          <span className="font-[700]">Virtual Games</span>
        </div>
        {/* <div
          onClick={() => {
            localStorage.getItem("token")
              ? navigate("/horse-recing")
              : localStorage.setItem("unauthorized", true);
          }}
          className={`text-${primaryColor} flex items-center border-b p-1 px-2.5 gap-1  text-md capitalize mr-0 sidebarbx `}
        >
          <img src="/winjaImages/DashboardIcon/horse.png" alt="" className="h-4" />
          <span className="font-[700]">Horse</span>
        </div>
        <div
          onClick={() => {
            localStorage.getItem("token")
              ? navigate("/greyhound")
              : localStorage.setItem("unauthorized", true);
          }}
          className={`text-${primaryColor} flex items-center border-b p-1 px-2.5 gap-1  text-md capitalize   mr-0 sidebarbx`}
        >
          <img alt="" width={25} height={25} src="/winjaImages/DashboardIcon/greyhound1.png" />
          <span className="font-[700]">Greyhound</span>
        </div> */}
      </div>

      {/* mobile view  */}

      <div ref={sidebarRef2} className={`block lg:hidden w-52 h-full bg-white ${isSidebarOpen ? 'block' : 'hidden'}`}>
        <div className={`bg-${primaryColor}  flex py-1 px-1 items-center text-[13px] font-[700] text-white`}>
          <div className="flex items-center justify-between w-full px-2"><span>Popular</span>
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="text-white hover:text-white text-lg block lg:hidden"
            ><FaTimes size={15} /></button>
          </div>
        </div>
        <div
          onClick={() => navigate("/dashboard")}
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5  text-[15px]   mr-0 `}
        >
          <span><FaHome size={18} /></span>
          <span className="text-[12px]">Home</span>
        </div>
        <div
          onClick={() => navigate("/inplay")}
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5  text-[15px]   mr-0 `}
        >
          <img src="/winjaImages/DashboardIcon/in-play-icon.svg" alt="" className="h-4" />
          <span className="text-[12px]">In-Play</span>
        </div>
        {SPORTSCONSTANT?.map((menuItem, index) => {
          const sport = matchData?.find(
            (sport) => sport.sportId.toString() === menuItem.count
          );
          return (
            <div
              key={index}
              className={`text-[#272d31] overflow-hidden py-0 my-0 transition-[max-height] duration-300 ease-in ${clickedOutside1 === true
                ? "max-h-96 bg-white"
                : "max-h-0 bg-white"
                }`}
            >
              <div className="cursor-pointer">
                <div
                  className="text-md  py-0 my-0 ml-0 w-full flex justify-between  font-[700] sidebarbx items-center"
                  onClick={(e) => handleClick(index, e)}
                >
                  <div className="flex gap-1 text-[#045662]">
                    <img src={menuItem.iconImg} alt="" className="h-4" />
                    <span className="text-[12px]">{menuItem.text}</span>
                  </div>
                  <span>

                    <MdOutlineKeyboardArrowDown />
                  </span>
                </div>
                {sport && openKeys.includes(index) && (
                  <div className=" py-0 my-0  !bg-[rgba(229,231,252,0.9)] border-b">
                    {sport?.series.map((series, seriesIndex) => (
                      <div key={seriesIndex} className="">
                        <div
                          className="text-md relative px-0 text-black w-full submenu bg-[rgb(rgb(210,227,255))] py-0 my-0 space-x-2 font-[600] flex  items-center cursor-pointer"
                          onClick={(e) => handleClick1(index, seriesIndex, e)}
                        >
                          <div className="px-5  py-3 border-b-[0.3px] border-[#ccccccb0] w-full bg-[rgb(rgb(210,227,255))] my-0  flex justify-start items-center gap-4 " sty>
                            {openKeys1[`${index}-${seriesIndex}`] ? (
                              <AiOutlineMinusSquare size={15} />
                            ) : (
                              <AiOutlinePlusSquare size={15} />
                            )}
                            {series.seriesName}
                          </div>
                        </div>
                        {openKeys1[`${index}-${seriesIndex}`] && (
                          <div className="">
                            {series.matches.map((match, matchIndex) => (
                              <div key={matchIndex} className="">
                                <div

                                  className="text-md relative  border-b-[0.3px] border-[#ccccccb0] px-6 text-black  w-full py-3 justify-start my-0  font-[600] flex items-center cursor-pointer"
                                  onClick={(e) => {
                                    window.location.href = `/match-details/${match.marketId}/${match?.eventId}`
                                    sidebartoggle();
                                  }}
                                >
                                  <span>

                                  </span>
                                  <span className="text-[10px]   w-full">{match.matchName}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

            </div>
          );
        })}
        <div
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5   mr-0 `}
        >
          <a href="/casino" className="flex items-center p-0.5 gap-1">
            <img src="/winjaImages/DashboardIcon/indian-poker-icon.svg" alt="" className="h-4" />
            <span className="text-[12px] capitalize">Casino</span>
          </a>
        </div>
        <div
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5   mr-0 `}
        >
          <a href="/iframe-casino/500001" className="flex items-center p-0.5 gap-1">
            <img src="/winjaImages/DashboardIcon/spiral2.png" alt="" className="h-4" />
            <span className="text-[12px] capitalize">Super nowa</span>
          </a>
        </div>
        <div
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5   mr-0 `}
        >
          <img src="/winjaImages/DashboardIcon/virtualgameicon.png" alt="" className="h-3" />
          <span className="text-[12px] capitalize">Virtual Games</span>
        </div>
        {/* <div
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5   mr-0 `}
        >
          <a href="/horse-recing" className="flex items-center p-0.5 gap-1">
            <img src="/winjaImages/DashboardIcon/horse.png" alt="" className="" width={25} height={25} />
            <span className="text-[12px] capitalize ">Horse</span>
          </a>
        </div>
        <div
          className={`text-[#045662] font-[700] flex items-center border-b py-1.5 px-1.5 gap-1.5   mr-0 `}
        >
          <a href="/greyhound" className="flex items-center p-0.5 gap-1">
            <img alt="" width={25} height={25} src="/winjaImages/DashboardIcon/greyhound1.png" />
            <span className="text-[12px] capitalize ">Greyhound</span>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
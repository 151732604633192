import React from 'react'
import { AiOutlineInstagram, AiOutlineTwitter, AiOutlineWhatsApp } from 'react-icons/ai'
import { BsTelegram } from 'react-icons/bs'

const Social = () => {
  return (
    <div>
        <div className="w-full m-1">
        <div className="w-full">
              <div className="flex flex-row h-10 justify-evenly items-center w-full border-2 border-dashed border-[rgba(0,8,73,0.9)] rounded-full">
                <button>
                  <img
                    className=""
                    src="/winjaImages/images/google-pay.png"
                  />
                </button>
                <button className="text-blue-800">
                  <img className="" src="/winjaImages/images/paytm.png" />
                </button>
                <button>
                  <img className="" src="/winjaImages/images/visa.png" />
                </button>
                <button>
                  <img className="" src="/winjaImages/images/upi.png" />
                </button>
              </div>
            </div>
            </div>
      
    </div>
  )
}

export default Social


import React, { createContext, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { getSocket, initSocket } from '../SocketConnection/SocketConnection';
import { domainName } from '../../middelware/global';
import { getDomainSettingData, getMatchList, getUserBalance } from '../../redux/_reducers/_user_reducers';


const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
  let fullDomain = window.location.hostname;
  let domain = fullDomain.replace(/^admin\./, '');
  const [balance, setBalance] = useState({
    coins: JSON.parse(localStorage.getItem("clientBalance")) || "",
    exposure: JSON.parse(localStorage.getItem("clientExposure")) || ""
  });
  const dispatch = useDispatch()
  useEffect(() => {
    updateSocket();

    function updateSocket() {
      let userID = JSON.parse(localStorage.getItem(`user_info_${domainName}`));
      // let userID = JSON.parse(localStorage.getItem("clientUser"));
      let token_Id = userID?.token;
      let socket = getSocket();

      if (!socket || socket == null) {
        socket = initSocket(token_Id);
      }

      const loginData = {
        userId: userID?.data?.userId,
        token: token_Id,
        domain: domain
      };

      socket.emit(`login`, loginData);

      socket.on("userLoggedOut", (data) => {
        // console.warn('userLoggedOut socket');
        // if (data == userID?.data?.userId) {
        //   // return dispatch(userSignOut()) ff
        //   localStorage.clear()
        //   window.location.href = '/login'
        // }
      });

      socket.on("userLoggedIn", (data) => {
        console.warn('userLoggedIn socket');
      });

      socket.on("sportsListUpdate", (data) => {
        if (data?.isUpdate) {
          dispatch(getMatchList())
        }
      });

      socket.on("domainUpdate", (data) => {
        if (data?.sportListUpdate) {
          dispatch(getMatchList())
        } if (data?.isDomainSettingUpdate) {
          let domainSetting = {
            domainUrl: window.location.origin,
          };
          dispatch(getDomainSettingData(domainSetting));
        } if (data?.isLogout) {
          localStorage.clear()
          window.location.href = '/dashboard'
        }

        if (data?.isRefresh) {
          dispatch(getUserBalance());
          window.location.href = '/dashboard'
        }

      });
      socket.on("coinUpdate", (data) => {
      
        localStorage.setItem("clientBalance", JSON.stringify(data?.coins));
        localStorage.setItem("clientExposure", JSON.stringify(data?.exposure));
        setBalance({
          coins: data.coins,
          exposure: data.exposure,
        });
      });

      socket.on("fetchCasinoBetList", (data) => {
        console.log("fetchCasinoBetList scoket");
      });

    }
  }, []);

  return (
    <BalanceContext.Provider value={{ balance, setBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  return useContext(BalanceContext);
};

import React from "react";
import { Carousel } from 'antd';
import { dashboardImages } from "../../pages/Home/HomeJson";

const Banner = () => {
    let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
    return (
        <section>
            <div className=" w-full  py-2">
                {domainSetting?.banner?.length > 0 ? (
                    <Carousel
                        autoplay
                        dots={true} // You can switch to `false` if you prefer no dots
                        speed={500}
                        autoplaySpeed={3000}
                        pauseOnHover={true} // Pause carousel on hover
                        easing="ease-in-out" // Smooth easing for transitions
                        arrows={true} // Show arrows for navigation
                    >
                        {domainSetting.banner?.sort((a, b) => a.priority - b.priority)?.map((req, index) => (
                            <div key={index} className="border-0 rounded-lg">

                                {req?.url ? <a href={req.url} >
                                    <img
                                        src={req.image}
                                        alt={req.name}
                                        className="border-0 rounded-lg w-[97%] 2xl:h-64 lg:h-52 sm:h-48 h-32"

                                    /> </a> : <img
                                    src={req.image}
                                    alt={req.name}
                                    className="border-0 rounded-lg w-[97%] 2xl:h-64 lg:h-52 sm:h-48 h-32"

                                />}
                            </div>
                        ))}
                    </Carousel>
                ) :
                 dashboardImages && dashboardImages.length > 0 && (
                    <Carousel
                      autoplay
                      dots={true}
                      speed={500}
                      autoplaySpeed={3000}
                      pauseOnHover={true}
                      easing="ease-in-out"
                      arrows={true}
                    >
                      {dashboardImages.map((game, index) => (
                        <div key={index} className="w-full">
                          <img
                            src={`/winjaImages/banner/DashBoardImages/${game.pathName}`}
                            alt={game.name || `Slide ${index + 1}`}
                            className="w-full h-[140px] sm:h-[180px] md:h-[200px] lg:h-[300px] xl:h-[450px] object-cover  shadow-md"
                            loading="lazy"
                          />
                        </div>
                      ))}
                    </Carousel>
                  )}

            </div>
        </section>
    )
}

export default Banner



import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function PrivateRoute({ element }) {
    const navigate = useNavigate();
    const location = useLocation(); // Get current location (path)
    const auth = JSON.parse(localStorage.getItem("token"));

    useEffect(() => {
        if (!auth) {
            if (location.pathname !== '/dashboard') { // If current path is not 
                navigate('/'); // Redirect to home if not on /dashboard
                localStorage.setItem("unauthorized", true);
            } else {
                localStorage.setItem("unauthorized", true); // Just set the item if on /dashboard
            }
        }
    }, [auth, location.pathname, navigate]);

    return <>{element}</>;
}

export default PrivateRoute;

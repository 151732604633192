import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userServices } from "../_services/_user_services";




const initialState = {

};

export const getMatchList = createAsyncThunk(
  "user/getMatchList",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.getMatchList(payload);


      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);

export const getAccountStatement = createAsyncThunk(
  "user/getAccountStatement",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.getAccountStatement(payload);

      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);
export const getDepositWithdrawList = createAsyncThunk(
  "user/getDepositWithdrawList",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.getDepositWithdrawList(payload);

      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);

export const getuserLedger = createAsyncThunk(
  "user/getuserLedger",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.getuserLedger(payload);

      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);
export const getDomainSettingData = createAsyncThunk(
  "user/getDomainSettingData",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.getDomainSettingData(payload);

      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);
export const getUserBalance = createAsyncThunk(
  "user/getUserBalance",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.getUserBalance(payload);

      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);
export const userUpdate = createAsyncThunk(
  "user/userUpdate",
  async (payload, { rejectWithValue }) => {
    try {
      const user = await userServices.userUpdate(payload);

      return user;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);


export const getInternationalGroupCasinoList = createAsyncThunk(
  "websit/getInternationalGroupCasinoList",
  async (payload, { rejectWithValue }) => {
    try {
      const cosinoGroupList = await userServices.getInternationalGroupCasinoList(payload);

      return cosinoGroupList;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);

export const getCasinoListByProviderName = createAsyncThunk(
  "websit/getCasinoListByProviderName",
  async (payload, { rejectWithValue }) => {
    try {
      const cosinoGroupList = await userServices.getCasinoListByProviderName(payload);

      return cosinoGroupList;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);


export const getCasinoListByCateogeory = createAsyncThunk(
  "websit/getCasinoListByCateogeory",
  async (payload, { rejectWithValue }) => {
    try {
      const casinoListByCateogeory = await userServices.getCasinoListByCateogeory(payload);

      return casinoListByCateogeory;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);


export const getResult = createAsyncThunk(
  "websit/getResult",
  async (payload, { rejectWithValue }) => {
    try {
      const casinoListByCateogeory = await userServices.getResult(payload);

      return casinoListByCateogeory;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);

export const getBetList = createAsyncThunk(
  "sports/betsList",
  async (payload, { rejectWithValue }) => {
    try {
      const casinoListByCateogeory = await userServices.getBetList(payload);

      return casinoListByCateogeory;
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);







const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMatchList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMatchList.fulfilled, (state, action) => {
        state.loading = false;
        state.matchList = action.payload?.data ? action.payload?.data : [];
      })
      .addCase(getMatchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAccountStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccountStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.accountStatement = action.payload?.data;
      })
      .addCase(getAccountStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDepositWithdrawList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepositWithdrawList.fulfilled, (state, action) => {
        state.loading = false;
        state.depositWithdrawal = action.payload?.data;
      })
      .addCase(getDepositWithdrawList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getuserLedger.pending, (state) => {
        state.loading = true;
      })
      .addCase(getuserLedger.fulfilled, (state, action) => {
        state.loading = false;
        state.userLedgerData = action.payload?.data;
      })
      .addCase(getuserLedger.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDomainSettingData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDomainSettingData.fulfilled, (state, action) => {
        state.loading = false;
        state.userDomainData = action.payload?.data;
      })
      .addCase(getDomainSettingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserBalance.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        // state.loading = false;
        state.userBalance = action.payload?.data;
      })
      .addCase(getUserBalance.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })
      .addCase(userUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.userUpdateData = action.payload?.data;
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // casino international List

      .addCase(getInternationalGroupCasinoList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInternationalGroupCasinoList.fulfilled, (state, action) => {
        state.loading = false;
        state.getInternationalGroupCasinoListData = action.payload?.data;
      })
      .addCase(getInternationalGroupCasinoList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // provierd list according to provider

      .addCase(getCasinoListByProviderName.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCasinoListByProviderName.fulfilled, (state, action) => {
        state.loading = false;
        state.getCasinoListByProviderNameData = action.payload?.data;
      })
      .addCase(getCasinoListByProviderName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // getCasinoListByCateogeory
      .addCase(getCasinoListByCateogeory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCasinoListByCateogeory.fulfilled, (state, action) => {
        state.loading = false;
        state.getCasinoListByCateogeoryData = action.payload?.data;
      })
      .addCase(getCasinoListByCateogeory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResult.fulfilled, (state, action) => {
        state.loading = false;
        state.resultData = action.payload?.data;
      })
      .addCase(getResult.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Bet List
      .addCase(getBetList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBetList.fulfilled, (state, action) => {
        state.loading = false;
        state.sportBetList = action.payload?.data;
      })
      .addCase(getBetList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

  },
});



export default userSlice.reducer;



import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAccountStatement } from "../redux/_reducers/_user_reducers";
import CustomPagination from "../component/CustomPagination/CustomPagination";
import { FaCircleInfo } from "react-icons/fa6";
import { primaryColor } from "./Home/HomeJson";

function AccountStatement() {
  const [payloadData, setPayloadData] = useState({
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    statementFor: "",
  });
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const dispatch = useDispatch();
  const { accountStatement } = useSelector((state) => state.user);
  const statementData = accountStatement?.statementData;
  const [pageNumber, setPageNumber] = useState(1);
  const [sportType, setSportType] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [modalData, setModalData] = useState({
    status: false,
    id: "",
  });
  const pageSize = 20;

  useEffect(() => {
    handleSubmit();
  }, [dispatch]);

  useEffect(() => {
    let dataFilter;

    if (sportType === "") {
      dataFilter = statementData;
    } else if (sportType === "cricket") {
      dataFilter = statementData?.filter(
        (ele) =>
          ele.statementFor !== "internationalCasino" &&
          ele.statementFor !== "diamondCasino"
      );
    } else {
      dataFilter = statementData?.filter(
        (ele) => ele.statementFor === sportType
      );
    }

    setFilteredData(dataFilter);
  }, [sportType, statementData]);

  const groupedData = {};
  filteredData?.forEach((item) => {
    const dateKey = moment(item.date).format("YYYY-MM-DD");
    groupedData[dateKey] = groupedData[dateKey] || [];
    groupedData[dateKey].push(item);
  });

  let totalAmount = 0;
  filteredData?.map((data) => {
    totalAmount += data.amount;
  });
  let balance = 0;
  let showAmount = 0;
  let finalData = filteredData?.map((data) => {
    balance = totalAmount - showAmount;
    showAmount += data.amount;
    return {
      amount: data.amount,
      date: data.date,
      balance: + balance,
      gameType: data.gameType,
      remark: data.remark,
      userRemark: data.userRemark,
      statementFor: data.statementFor,
      isComm: data.isComm,
      marketId: data.marketId,
      createdAt: data.createdAt,
      selectionId: data.selectionId || "0",
      _id: data?._id,
    };
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayloadData({
      ...payloadData,
      [name]: value,
    });
  };

  // Handle change for the select dropdown
  const handleSelectChange = (e) => {
    const newValue = e.target.value;

    setPayloadData((prevData) => ({
      ...prevData,
      statementFor: newValue,
    }));

    // const reqData = {
    //   fromDate: payloadData.fromDate,
    //   toDate: payloadData.toDate,
    //   pageNo: pageNumber,
    //   size: pageSize,
    //   statementFor: e.target.value,
    // };
    // dispatch(getAccountStatement(reqData));
  };

  const handleReset = () => {
    setPayloadData({
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      statementFor: payloadData?.statementFor,

    });
    handleSubmit()
  };

  const handleSubmit = () => {
    const reqData = {
      fromDate: payloadData.fromDate,
      toDate: payloadData.toDate,
      statementFor: payloadData?.statementFor,
      pageNo: pageNumber,
      size: pageSize,
    };
    dispatch(getAccountStatement(reqData));
  };

  const handleSelectChange2 = (e) => {
    setSportType(e.target.value);
  };
  const openModal = (id) => {
    setModalData({ status: true, id });
  };

  const closeModal = () => {
    setModalData({ status: false, id: "" });
  };

  useEffect(() => {
    setData(finalData)
  }, [statementData])


  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData || []].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };



  const renderSortArrows = (key) => (
    <span className="flex flex-col  justify-center  gap-0  items-center">
      <button
        onClick={() => handleSort(key)}
        className={`text-[9px] h-1 ${sortConfig.key === key && sortConfig.direction === "ascending"
          ? "opacity-100 text-gray-800"
          : "opacity-50 text-gray-400"
          }`}
      >
        ▲
      </button>
      <button
        onClick={() => handleSort(key)}
        className={`text-[9px] ${sortConfig.key === key && sortConfig.direction === "descending"
          ? "opacity-0 text-gray-800"
          : "opacity-0 text-gray-400"
          }`}
      >
        ▼
      </button>
    </span>
  );

  return (
    <div className="shadow-sm" >
      {modalData.status && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-primary  max-w-[550px] md:min-w-[500px] min-w-[100%] rounded-2xl shadow-lg">
            <div className="flex items-center justify-between mb-4 border-b border-secondary px-6 py-4">
              <img
                src="/assets/logo.png"
                alt="power play247"
                className="h-10"
              />
              <button
                onClick={closeModal}
                className="text-white bg-gray-700/70 w-9 h-9 rounded-full flex justify-center items-center  hover:text-white text-4xl"
              >
                &times;
              </button>
            </div>
            <div className="m-2 p-2 bg-gradient-to-b from-[#84c2f1] space-y-2 font-semibold capitalize to-blue-50 rounded-lg">
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>remark</span> <span>{modalData?.id?.remark}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>date</span>{" "}
                <span>
                  {moment(modalData?.id?.date).format("DD-MM-YYYY HH:MM")}
                </span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>gameType</span> <span>{modalData?.id?.gameType}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>userRemark</span> <span>{modalData?.id?.userRemark}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>statementFor</span>{" "}
                <span>{modalData?.id?.statementFor}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>marketId</span> <span>{modalData?.id?.marketId}</span>
              </div>
              <div className="flex text-xs text-black justify-between items-center py-1 border-b border-black">
                <span>selectionId</span>{" "}
                <span>{modalData?.id?.selectionId}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="w-full ">
        <div className="">
          <div className="">
            <div className="">
              <div className={`w-full text-[12px] text-white p-1.5 font-bold bg-${primaryColor}`}>
                Account Statement

              </div>
              <div className="w-full flex justify-center items-center">
                <div className="lg:mx-[55px]  w-full lg:w-[60%] md:mx-28 mx-7 ">
                  <div className=" lg:grid lg:grid-cols-5 grid grid-cols-1  items-center  w-full
                     justify-center">
                    <div className="flex flex-col  justify-start items-start w-full  px-5 ">
                      <label className="text-[12px] py-1 whitespace-nowrap">
                        Choose from Date
                      </label>

                      <input
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        value={payloadData.fromDate}
                        onChange={handleChange}
                        className="text-[12px] lg:!max-w-80  px-[11px] py-[15px] bg-white h-[30px] w-full border shadow-inner  rounded-[4px]"
                      />
                    </div>
                    <div className="flex flex-col  justify-start items-start px-5">
                      <label className="text-[12px] py-1 whitespace-nowrap">
                        {" "}
                        Choose to Date
                      </label>

                      <input
                        type="date"
                        name="toDate"
                        id="toDate"
                        value={payloadData.toDate}
                        onChange={handleChange}
                        className="text-[12px] lg:max-w-80  px-[11px] py-[15px] bg-white h-[30px] w-full border shadow-inner  rounded-[4px]"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start px-5 ">
                      <label className="text-[12px] py-1">Type</label>

                      <select
                        name="statementFor"
                        className="lg:max-w-80 w-full border"
                        value={payloadData?.statementFor}
                        onChange={handleSelectChange}
                      >
                        <option value="">ALL</option>
                        <option value="profitLoss">Profit/Loss</option>
                        <option value="ACCOUNT_STATEMENT">Account</option>
                      </select>
                    </div>

                    <div className="sm:m-0 mt-2 lg:ml-12 flex flex-row items-start justify-start h-full ">
                      <button className="bg-gradient-to-b from-[#333] to-black rounded-[4px] min-h-[30px] mr-2 sm:ml-2" onClick={handleSubmit}>
                        <span className="text-white text-[13px] px-[19px]   py-[20px]">
                          Go
                        </span>
                      </button>
                      <button className="bg-gradient-to-b from-[#333] to-black rounded-[4px] min-h-[30px]" onClick={handleReset} >
                        <span className="text-white text-[13px] px-[19px]   py-[20px]">
                          Reset
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">

              </div>
            </div>
            <div className="px-[18px] space-y-1 ">
              {(
                <div className="border shadow-sm">
                  <div className="overflow-x-auto w-full shadow-1xl " style={{ boxShadow: '0 1px 6px rgb(0,0,0/0.9)' }}>
                    <table className="w-full text-sm  text-left   border-b-[2px] border-[#ddd] border  " >
                      <thead className="text-[12px]  text-[#243a48]   bg-[#E4E4E4] border border-white">
                        <tr className="border border-white">
                          <th
                            scope="col "
                            className="px-2.5 w-[16%] whitespace-nowrap py-2 text-[#243a48]    border-[0.5px] border-white"
                          >
                            {" "}
                            <div className="flex justify-between items-center gap-2">
                              Date {renderSortArrows("date")}
                            </div>
                          </th>
                          <th
                            scope="col "
                            className="px-2.5 w-[16%]  py-2 text-[#243a48]  whitespace-nowrap  border-[0.5px] border-white"
                          >
                            {" "}
                            <div className="flex justify-between items-center gap-2">
                              Amount {renderSortArrows("amount")}
                            </div>
                          </th>

                          <th
                            scope="col "
                            className="px-2.5 w-[16%]  py-2 text-[#243a48]  whitespace-nowrap  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center gap-2">
                              Available Balance{" "}  {renderSortArrows("balance")}
                            </div>
                          </th>
                          <th
                            scope="col "
                            className="px-2.5 w-[16%]  py-2 text-[#243a48]  whitespace-nowrap  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center gap-2">
                              Remark{" "} {renderSortArrows("statementFor")}
                            </div>
                          </th>
                          {/* <th scope="col " className="px-2.5 py-1.5 text-[#243a48]  whitespace-nowrap  border-[0.5px] border-white">Action </th> */}
                          <th
                            scope="col "
                            className="px-2.5 w-[16%]  py-2 text-[#243a48]  whitespace-nowrap  border-[0.5px] border-white"
                          >
                            <div className="flex justify-between items-center gap-2">
                              {" "}
                              Description {renderSortArrows("remark")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {data?.length > 0 ? data?.map((item, index) => (
                        <tbody key={index}>
                          <tr className="bg-white border-b-[1px]  border-secondary uppercase">
                            <th scope="row" className=" py-2 text-[#333] text-[12px] font-medium  border border-[#ddd] whitespace-nowrap" >
                              {moment(item.date).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )}
                            </th>

                            <td
                              className={`px-2 text-[#333] py-2  text-[12px] font-medium  border border-[#ddd] whitespace-nowrap ${item.amount > 0
                                  ? "text-green-600"
                                  : item.amount < 0
                                    ? "text-red-600"
                                    : "text-[#333]"
                                }`}
                            >
                              {parseFloat(item.amount)
                                .toFixed(2)
                                .replace(/\.?0+$/, "")}
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border whitespace-nowrap border-[#ddd]">
                              {parseFloat(item.balance)
                                .toFixed(2)
                                .replace(/\.?0+$/, "")}
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border whitespace-nowrap border-[#ddd] space-x-2 ">
                              {item.statementFor}
                            </td>
                            <td className="px-2 text-[#333] py-2  text-[12px] font-medium  border whitespace-nowrap border-[#ddd]">{item.remark}</td>

                          </tr>


                        </tbody>
                      )) :
                        (
                          <tr >
                            <td colSpan={5}>
                              <div className="text-center border-2   px-2 py-3 text-[12px]  ">
                                {" "}
                                No data found
                              </div>
                            </td>
                          </tr>
                        )}
                    </table>
                  </div>
                  {data?.length > 0 &&
                    <div className="flex justify-start mx-2 my-2">
                      <CustomPagination
                        totalCount={accountStatement?.totalCount}
                        pageSize={pageSize}
                        currentPage={pageNumber}
                        onChange={setPageNumber}
                      />
                    </div>}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountStatement;
